import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Layout } from "./components/Layout";
import { CustomContentModal as Modal } from "../../../components/CustomContentModal";
import { RegistrationList } from "./components/RegistrationList";
import OnboardingMiniTimeline from "./components/OnboardingMiniSteps";
import Panel from "../../../components/Panel";
import Button from "../../../components/Form/Button";
import QuickSearch from "../../../components/QuickSearch/QuickSearch";
import { OnboardingRecordEdit } from "./components/OnboardingRecordEdit";
import ComboboxAutocomplete from "../../../components/Form/ComboboxAutocomplete";
import Table from "../../../components/Table/Table";
import qs from "qs";
import { Dialog } from "@headlessui/react";
import {
  fetchAllTenantRegistrationRecords,
  previewTenantRegistrationRecords,
  importTenantRegistrationRecords,
  updateTenantRegistrationRecord,
  exportDataApi,
  exportZipDataApi,
  getPropertyListApi,
} from "../../../apis/registration";
import { generateAccountInvitationApi } from "../../../apis/apis";
import { pushToast } from "../../../components/Toaster/Toaster.slice";
import type { TenantRegistration } from "../../../types/registration";
import { Paginated } from "../../../apis/types";
import { useAppDispatch } from "../../../shared/redux/hooks";

export const Onboarding: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const propertyName = searchParams.get("propertyName") ?? undefined;
  const registrationID = searchParams.get("registrationID") ?? undefined;

  const [registrations, setRegistrations] =
    useState<Paginated<TenantRegistration>>();
  const [properties, setProperties] = useState<string[]>();
  // const [selectedPropertyName, setSelectedPropertyName] = useState<any>(propertyName)
  const [quickSearchTerm, setQuickSearchTerm] = useState<string>();
  const [selectedRecords, setSelectedRecords] = useState<string[]>([]);
  const [isPromotingRecords, setIsPromotingRecords] = useState(false);
  const [importRegistrations, setImportRegistrations] = useState<
    TenantRegistration[]
  >([]);
  const [openImportRegistrationIDs, setOpenImportRegistrationIDs] = useState<
    Set<string>
  >(() => new Set());
  // const [isEditRegistrationModalOpen, setIsEditRegistrationModalOpen] = useState<boolean>(false)
  // const [editRegistrationID, setEditRegistrationID] = useState<string | null>(null)
  const [isImportRegistrationModalOpen, setIsImportRegistrationModalOpen] =
    useState<boolean>(false);
  const [importRegistrationModalStep, setImportRegistrationModalStep] =
    useState<number>(1);
  const [
    isImportRegistrationFileUploading,
    setIsImportRegistrationFileUploading,
  ] = useState<boolean>(false);
  const [isImportRegistrationFileSaving, setIsImportRegistrationFileSaving] =
    useState<boolean>(false);

  const hiddenFileInputRef = useRef<HTMLInputElement>(null);
  const fileRef = useRef<File>();
  const onboardingRegistrationExportLinkRef = useRef<HTMLAnchorElement | null>(
    null
  );
  const accountSignupExportLinkRef = useRef<HTMLAnchorElement | null>(null);
  const tesaExportLinkRef = useRef<HTMLAnchorElement | null>(null);

  useEffect(() => {
    const init = async (): Promise<void> => {
      const propertyList = await getPropertyListApi();
      setProperties(propertyList);
    };

    void init();
  }, []);

  useEffect(() => {
    const init = async (): Promise<void> => {
      setSelectedRecords([]);
      const registrations = await fetchAllTenantRegistrationRecords({
        page: 0,
        propertyName: propertyName?.toString(),
        businessName: quickSearchTerm,
      });
      setRegistrations(registrations);
    };

    void init();
  }, [propertyName, quickSearchTerm]);

  const refreshList = async (): Promise<void> => {
    const response = await fetchAllTenantRegistrationRecords({
      page: registrations?.page ?? 0,
      propertyName: propertyName?.toString(),
      businessName: quickSearchTerm,
    });
    setRegistrations(response);
  };

  const handlePaginatorClick = async (page: number): Promise<void> => {
    setSelectedRecords([]);
    const registrations = await fetchAllTenantRegistrationRecords({
      page,
      propertyName: propertyName?.toString(),
    });
    setRegistrations(registrations);
  };

  const handleQuickSearchChange = React.useCallback((value: string): void => {
    if (value === undefined) {
      return;
    }
    setQuickSearchTerm(value);
    setSelectedRecords([]);
  }, []);

  const setSelectedPropertyName = (propertyName: string | undefined): void => {
    const queryString = qs.stringify(
      { propertyName, registrationID },
      { addQueryPrefix: true, skipNulls: true }
    );
    navigate({ search: queryString }, { replace: true });
  };

  const setEditRegistrationID = (registrationID: string | null): void => {
    const queryString = qs.stringify(
      { propertyName, registrationID },
      { addQueryPrefix: true, skipNulls: true }
    );
    navigate({ search: queryString }, { replace: true });
  };

  const getRegistrationByID = React.useCallback(
    (registrationId: string): TenantRegistration | undefined =>
      registrations?.results?.find(
        (registration) => registration.id === registrationId
      ),
    [registrations?.results]
  );

  const handleExport = async (
    selectedPropertyName: string,
    type: string
  ): Promise<void> => {
    try {
      const objectUrl = await exportDataApi(selectedPropertyName, type);

      const downloadLink = document.createElement("a");
      downloadLink.href = objectUrl;
      downloadLink.download = `${selectedPropertyName} - ${
        type === "ONBOARDING_REGISTRATION" ? "Registration" : "Account"
      } Invitations.csv`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error: any) {
      if (error.response?.status === 404) {
        dispatch(
          pushToast({
            type: "error",
            message: "Invitation export is empty",
            description: "Please adjust your query and try again",
          })
        );
      } else {
        dispatch(
          pushToast({
            type: "error",
            message: "Error exporting invitations",
            description:
              error.response?.data?.message ??
              error.response?.message ??
              error.message,
          })
        );
      }
    }
  };

  const handleZipExport = async (
    selectedPropertyName: string,
    type: string
  ): Promise<void> => {
    try {
      dispatch(
        pushToast({
          message: "File(s) generating. Please wait 30 seconds...",
        })
      );
      const objectUrl = await exportZipDataApi(selectedPropertyName, type);
      const downloadLink = document.createElement("a");
      downloadLink.href = objectUrl;
      downloadLink.download = `${selectedPropertyName} - ${
        type === "TESA_EXPORT" ? "TESA" : "<?>"
      } .zip`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      dispatch(
        pushToast({
          type: "success",
          message: "File(s) downloaded",
        })
      );
    } catch (error: any) {
      if (error.response?.status === 404) {
        dispatch(
          pushToast({
            type: "error",
            message: "TESA export is empty",
            description: "Please adjust your query and try again",
          })
        );
      } else {
        dispatch(
          pushToast({
            type: "error",
            message: "Error exporting TESAs",
            description:
              error.response?.data?.message ??
              error.response?.message ??
              error.message,
          })
        );
      }
    }
  };

  const selectedRegistration = React.useMemo(
    () =>
      registrationID !== undefined
        ? getRegistrationByID(registrationID)
        : undefined,
    [registrationID, getRegistrationByID]
  );

  return (
    <>
      <Layout title="Tenant Onboarding">
        <div className="mb-5 flex justify-end">
          <button
            onClick={() => {
              const fileInput = hiddenFileInputRef.current;

              if (fileInput != null) {
                fileInput.value = "";
              }

              fileRef.current = undefined;

              setImportRegistrationModalStep(1);
              setIsImportRegistrationModalOpen(true);
            }}
            className="px-4 py-2 text-sm font-medium text-orange-900 bg-orange-100 border border-transparent rounded-md hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-orange-500"
          >
            Import
          </button>
          {selectedRecords.length > 0 && (
            <Button
              type="button"
              disabled={isPromotingRecords}
              onClick={async () => {
                try {
                  setIsPromotingRecords(true);
                  await generateAccountInvitationApi(selectedRecords);
                  const registrations = await fetchAllTenantRegistrationRecords(
                    {
                      page: 0,
                      propertyName: propertyName?.toString() ?? undefined,
                      businessName: quickSearchTerm ?? undefined,
                    }
                  );
                  setRegistrations(registrations);
                  setSelectedRecords([]);
                  dispatch(
                    pushToast({
                      type: "success",
                      message: `${selectedRecords.length} account invitation${
                        selectedRecords.length === 1 ? "" : "s"
                      } generated`,
                    })
                  );
                } catch (error: any) {
                  dispatch(
                    pushToast({
                      type: "error",
                      message: `Failed to generate ${
                        selectedRecords.length
                      } account invitation${
                        selectedRecords.length === 1 ? "" : "s"
                      }`,
                      description:
                        error.response?.data?.message ??
                        error.response?.message ??
                        error.message,
                    })
                  );
                }
                setIsPromotingRecords(false);
              }}
              className="ml-4 px-4 py-2 text-sm font-medium text-indigo-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
            >
              Generate {selectedRecords.length} account invitation
              {selectedRecords.length === 1 ? "" : "s"}
            </Button>
          )}
        </div>
        <Panel header="Filters" className="mb-5">
          <div className="grid grid-auto-flow grid-cols-3 gap-3">
            <QuickSearch
              title="Tenant name (case sensitive)"
              initialValue={quickSearchTerm}
              handleChange={handleQuickSearchChange}
            />
            <div className="flex">
              <div className="flex flex-col grow">
                <label
                  htmlFor="propertyNames"
                  className="block text-sm font-medium text-zinc-700"
                >
                  Property
                </label>
                {properties !== undefined && properties.length > 0 && (
                  <ComboboxAutocomplete
                    labelPlural="Properties"
                    value={propertyName != null ? propertyName : "null"}
                    handleChange={setSelectedPropertyName}
                    data={properties?.map((property: string) => ({
                      id: property,
                      name: property,
                    }))}
                  />
                )}
              </div>
            </div>
            {propertyName != null &&
              propertyName !== "" &&
              propertyName !== "null" && (
                <div className="flex">
                  <a
                    ref={onboardingRegistrationExportLinkRef}
                    onClick={() => {
                      void handleExport(
                        propertyName,
                        "ONBOARDING_REGISTRATION"
                      );
                    }}
                    className="self-end px-4 py-2 text-sm font-medium text-orange-900 bg-orange-100 border border-transparent rounded-md hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-orange-500"
                  >
                    Export Registration Invitations
                  </a>
                  <a
                    ref={accountSignupExportLinkRef}
                    onClick={() => {
                      void handleExport(propertyName, "ACCOUNT_SIGNUP");
                    }}
                    className="ml-3 self-end px-4 py-2 text-sm font-medium text-orange-900 bg-orange-100 border border-transparent rounded-md hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-orange-500"
                  >
                    Export Account Invitations
                  </a>
                  <a
                    ref={tesaExportLinkRef}
                    onClick={() => {
                      void handleZipExport(propertyName, "TESA_EXPORT");
                    }}
                    className="ml-3 self-end px-4 py-2 text-sm font-medium text-orange-900 bg-orange-100 border border-transparent rounded-md hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-orange-500"
                  >
                    Export TESA Docs
                  </a>
                </div>
              )}
          </div>
        </Panel>
        <div className="mt-4">
          {registrations !== undefined && (
            <>
              <Table<TenantRegistration>
                data={registrations}
                handleClick={(record) => {
                  setEditRegistrationID(record.id);
                }}
                isSelected={(record) =>
                  selectedRecords.includes(
                    record.invites.ONBOARDING_REGISTRATION.id
                  )
                }
                colConfig={[
                  {
                    label:
                      registrations?.results.filter(
                        (record: any) =>
                          record.invites.isAccountInviteCreated === false &&
                          record.invites.isOnregInviteConsumed &&
                          record.isComplete !== false &&
                          record.invites.ONBOARDING_REGISTRATION
                            .agreementSubmissions?.[0]?.status !== "REJECTED"
                      ).length > 0 ? (
                        <div className="text-center">
                          <input
                            name="records-all"
                            type="checkbox"
                            checked={
                              selectedRecords.length ===
                              registrations?.results.filter(
                                (record) =>
                                  !record.invites.isAccountInviteCreated &&
                                  record.invites.isOnregInviteConsumed &&
                                  record.isComplete !== false &&
                                  record.invites.ONBOARDING_REGISTRATION
                                    .agreementSubmissions?.[0]?.status !==
                                    "REJECTED"
                              ).length
                            }
                            className="w-4 h-4 focus:ring-indigo-500 text-indigo-600 border-zinc-500 rounded"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onChange={(e) => {
                              e.stopPropagation();
                              if ((e.target as HTMLInputElement).checked) {
                                setSelectedRecords(
                                  registrations?.results
                                    .filter(
                                      (record) =>
                                        !record.invites
                                          .isAccountInviteCreated &&
                                        record.invites.isOnregInviteConsumed &&
                                        record.isComplete !== false &&
                                        record.invites.ONBOARDING_REGISTRATION
                                          .agreementSubmissions?.[0]?.status !==
                                          "REJECTED"
                                    )
                                    .map(
                                      (record) =>
                                        record.invites.ONBOARDING_REGISTRATION
                                          .id
                                    ) ?? []
                                );
                              } else {
                                setSelectedRecords([]);
                              }
                            }}
                          />
                        </div>
                      ) : null,
                    render: (record: TenantRegistration) => {
                      if (record.isComplete === false) {
                        return (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="mx-auto h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                            />
                          </svg>
                        );
                      } else if (
                        !record.invites.isAccountInviteCreated &&
                        record.invites.isOnregInviteConsumed &&
                        record.invites.ONBOARDING_REGISTRATION
                          .agreementSubmissions?.[0]?.status !== "REJECTED"
                      ) {
                        return (
                          <div className="text-center">
                            <input
                              name={`record-${record.id}`}
                              type="checkbox"
                              checked={selectedRecords.includes(
                                record.invites.ONBOARDING_REGISTRATION.id
                              )}
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-zinc-500 rounded"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              onChange={(e) => {
                                if ((e.target as HTMLInputElement).checked) {
                                  setSelectedRecords([
                                    ...selectedRecords,
                                    record.invites.ONBOARDING_REGISTRATION.id,
                                  ]);
                                } else {
                                  const index = selectedRecords.findIndex(
                                    (id: string) =>
                                      id ===
                                      record.invites.ONBOARDING_REGISTRATION.id
                                  );
                                  const splicedArr = [...selectedRecords];
                                  splicedArr.splice(index, 1);
                                  setSelectedRecords(splicedArr);
                                }
                              }}
                            />
                          </div>
                        );
                      }
                      return null;
                    },
                  },
                  {
                    label: 'Business',
                    render: (record) => record.tenant?.name.split('-')?.[0].trim(),
                  },
                  {
                    label: 'Property',
                    render: (record) => (
                        record.property?.split(' - ')[0]
                    ),
                  },
                  {
                    label: 'Location',
                    render: (record) => (
                        record.property?.split(' - ')[1]
                    ),
                  },
                  {
                    label: "Utility",
                    render: (record) => record.utility,
                  },
                  {
                    label: "Progress",
                    render: (record) => (
                      <OnboardingMiniTimeline invites={record.invites} />
                    ),
                  },
                ]}
                handlePaginatorClick={(page) => {
                  void handlePaginatorClick(page);
                }}
              />
            </>
          )}
        </div>
      </Layout>
      <Modal
        isOpen={isImportRegistrationModalOpen}
        onClose={() => {
          if (
            isImportRegistrationFileUploading ||
            isImportRegistrationFileSaving
          ) {
            return;
          }

          setIsImportRegistrationModalOpen(false);
        }}
      >
        <div
          className={`${
            importRegistrationModalStep === 1 ? "max-w-md" : "max-w-3xl"
          } p-6 text-left bg-white`}
        >
          {importRegistrationModalStep === 1 && (
            <>
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-zinc-900"
              >
                Import Tenant Feed Data
              </Dialog.Title>
              <div className="mt-4 text-sm">
                <p className="text-zinc-500">
                  Select a CSV file containing the tenant feed data meant for
                  preview and storage.
                </p>
                <p className="my-4 text-zinc-500">Supported columns:</p>
                <ul className="list-disc columns-2 text-xs whitespace-nowrap text-zinc-500">
                  <li>Contact ID</li>
                  <li>Program: Program Name</li>
                  <li>First Name</li>
                  <li>Last Name</li>
                  <li>Account Name</li>
                  <li>Utility Account Name</li>
                  <li>Utility Account ID</li>
                  <li>Email</li>
                  <li>Phone</li>
                  <li>Service Address Street</li>
                  <li>Service Address City</li>
                  <li>Service Address State</li>
                  <li>Service Address Zip</li>
                  <li>Billing Address Street</li>
                  <li>Billing Address City</li>
                  <li>Billing Address State</li>
                  <li>Billing Address Zip</li>
                  <li>Meter Number</li>
                  <li>kWh/yr</li>
                  <li>Tariff Object: Tariff</li>
                  <li>Service ID</li>
                  <li>Utility: Utility</li>
                  <li>Utility Record ID</li>
                  <li>Energy Bill Discount %</li>
                  <li>ProjectCo of Program</li>
                  <li>Program Nickname</li>
                  <li>Account ID</li>
                  <li>Tenant Type</li>
                  <li>CCA</li>
                  <li>Program ID</li>
                  <li>Landlord Entity Name</li>
                  <li>Meter Data ID</li>
                  <li>Property Name</li>
                </ul>
              </div>
              <div className="mt-4 flex justify-end">
                <button
                  type="button"
                  disabled={isImportRegistrationFileUploading}
                  onClick={() => setIsImportRegistrationModalOpen(false)}
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-zinc-900 bg-zinc-100 disabled:text-zinc-500 disabled:bg-zinc-50 disabled:cursor-not-allowed border border-transparent rounded-md hover:bg-zinc-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-zinc-500"
                >
                  Cancel
                </button>
                <div className="ml-2">
                  <button
                    type="button"
                    disabled={isImportRegistrationFileUploading}
                    onClick={() => hiddenFileInputRef.current?.click()}
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-orange-900 bg-orange-100 disabled:text-orange-500 disabled:bg-orange-50 disabled:cursor-not-allowed border border-transparent rounded-md hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-orange-500"
                  >
                    {isImportRegistrationFileUploading && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-orange-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    Choose File...
                  </button>
                  <input
                    ref={hiddenFileInputRef}
                    type="file"
                    accept=".csv"
                    onChange={(event) => {
                      async function init(): Promise<void> {
                        const file = event.target.files?.[0];
                        if (file === undefined) {
                          return;
                        }

                        fileRef.current = file;

                        setIsImportRegistrationFileUploading(true);

                        let importRegistrations: TenantRegistration[];

                        try {
                          importRegistrations =
                            await previewTenantRegistrationRecords(file);

                          setImportRegistrations(importRegistrations);

                          const ids = importRegistrations.map(({ id }) => id);
                          setOpenImportRegistrationIDs(new Set(ids));
                          setImportRegistrationModalStep(2);
                        } catch (error: any) {
                          dispatch(
                            pushToast({
                              type: "error",
                              message: `Failed to import feed`,
                              description:
                                error.response?.data?.message ??
                                error.response?.message ??
                                error.message,
                            })
                          );
                        } finally {
                          setIsImportRegistrationFileUploading(false);
                        }
                      }

                      void init();
                    }}
                    className="hidden"
                  />
                </div>
              </div>
            </>
          )}
          {importRegistrationModalStep === 2 && (
            <>
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-zinc-900"
              >
                Import Tenant Feed Data
              </Dialog.Title>
              <div className="mt-4">
                <RegistrationList
                  registrations={importRegistrations}
                  openRegistrationIDs={openImportRegistrationIDs}
                  areActionsHidden={true}
                  onItemClick={(idx) => {
                    if (openImportRegistrationIDs.has(idx)) {
                      openImportRegistrationIDs.delete(idx);

                      setOpenImportRegistrationIDs(
                        new Set(openImportRegistrationIDs)
                      );
                    } else {
                      openImportRegistrationIDs.add(idx);

                      setOpenImportRegistrationIDs(
                        new Set(openImportRegistrationIDs)
                      );
                    }
                  }}
                  onItemActionsClick={(index, action) =>
                    console.log(index, action)
                  }
                />
              </div>
              <div className="mt-4 flex justify-end">
                <button
                  type="button"
                  disabled={isImportRegistrationFileUploading}
                  onClick={() => setIsImportRegistrationModalOpen(false)}
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-zinc-900 bg-zinc-100 disabled:text-zinc-500 disabled:bg-zinc-50 disabled:cursor-not-allowed border border-transparent rounded-md hover:bg-zinc-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-zinc-500"
                >
                  Cancel
                </button>
                <div className="ml-2">
                  <button
                    type="button"
                    disabled={isImportRegistrationFileSaving}
                    onClick={() => {
                      async function init(): Promise<void> {
                        const file = fileRef.current;
                        if (file === undefined) {
                          return;
                        }

                        setIsImportRegistrationFileSaving(true);

                        try {
                          const registrationRecords =
                            await importTenantRegistrationRecords(file);
                          setIsImportRegistrationModalOpen(false);

                          const registrations =
                            await fetchAllTenantRegistrationRecords();
                          setRegistrations(registrations);

                          const propertyList = await getPropertyListApi();
                          setProperties(propertyList);

                          dispatch(
                            pushToast({
                              type: "success",
                              message: `Feed imported`,
                            })
                          );

                          setSelectedPropertyName(
                            registrationRecords[0].property
                          );
                        } catch (error: any) {
                          dispatch(
                            pushToast({
                              type: "error",
                              message: `Failed to import feed`,
                              description:
                                error.response?.data?.message ??
                                error.response?.message ??
                                error.message,
                            })
                          );
                        } finally {
                          setIsImportRegistrationFileSaving(false);
                        }
                      }

                      void init();
                    }}
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-orange-900 bg-orange-100 disabled:text-orange-500 disabled:bg-orange-50 disabled:cursor-not-allowed border border-transparent rounded-md hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-orange-500"
                  >
                    {isImportRegistrationFileSaving && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-orange-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    Save
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
      <Modal
        className="max-w-screen-lg min-w-screen-sm"
        isOpen={registrationID != null}
        onClose={() => setEditRegistrationID(null)}
      >
        {selectedRegistration !== undefined && (
          <OnboardingRecordEdit
            onboarding={selectedRegistration}
            refreshList={refreshList}
            updateOnboardingFn={async (registration) => {
              try {
                await updateTenantRegistrationRecord(
                  registration.id,
                  registration
                );
                await refreshList();
                setEditRegistrationID(null);
                dispatch(
                  pushToast({
                    type: "success",
                    message: `Changes saved`,
                  })
                );
              } catch (error: any) {
                dispatch(
                  pushToast({
                    type: "error",
                    message: `Failed to save changes`,
                    description:
                      error.response?.data?.message ??
                      error.response?.message ??
                      error.message,
                  })
                );
              }
            }}
            closeFn={() => {
              setEditRegistrationID(null);
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default Onboarding;
