import React from 'react'

import { RegistrationListHeader } from './RegistrationListHeader'
import { RegistrationListItem } from './RegistrationListItem'

import type { TenantRegistration } from '../../../../types/registration'
import type { TenantRegistrationActions } from '../../../../types/registration/actions'

export interface RegistrationListProps {
  registrations: TenantRegistration[]
  openRegistrationIDs: Set<string>
  areActionsHidden: boolean
  onItemClick: (id: string) => void
  onItemActionsClick: (id: string, action: TenantRegistrationActions) => void
}

export const RegistrationList: React.FC<RegistrationListProps> = (props) => {
  return (
    <>
      <div className="shadow bg-zinc-50 border-zinc-200 border-b rounded-lg overflow-x-auto">
        <div className="align-middle inline-block min-w-full">
          <table className="min-w-full divide-y divide-zinc-200 table-fixed">
            <thead className="bg-zinc-50">
              <RegistrationListHeader areActionsHidden={props.areActionsHidden} />
            </thead>
            <tbody className="bg-zinc-50 divide-y divide-zinc-200">
              {props.registrations?.map((registration: any) => (
                <RegistrationListItem
                  key={registration.id}
                  registration={registration}
                  isOpen={props.openRegistrationIDs.has(registration.id)}
                  areActionsHidden={props.areActionsHidden}
                  onClick={() => props.onItemClick(registration.id)}
                  onActionClick={(action) => props.onItemActionsClick(registration.id, action)}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default RegistrationList
