import React from 'react'

interface TableCheckMarkProps {
  onClick: () => void
  checked?: boolean
  id: string
}

const TableCheckMark: React.FC<TableCheckMarkProps> = ({ checked, onClick, id }) => (
  <input
    type="checkbox"
    id={id}
    checked={checked}
    onChange={onClick}
    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 z-50 cursor-pointer"
  />
)

export default TableCheckMark
