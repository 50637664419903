import React from "react";

export interface DisplayFieldProps {
  label: string;
  children?: React.ReactNode;
}

export const DisplayField: React.FC<DisplayFieldProps> = (props) => {
  // In first div, set the height to the sum of title and content heights
  return (
    <div className="flex flex-col flex-start h-[calc(5rem + 1.25rem)]">
      <div className="font-medium color-base text-zinc-700 mb-1">
        {props.label}
      </div>
      <div>
        <span>{props.children}</span>
      </div>
    </div>
  );
};

export default DisplayField;
