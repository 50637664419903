import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getTenant } from "../Tenants.slice";
import { pushToast } from "../../../../components/Toaster/Toaster.slice";
import { updatePaymentApi } from "../../../../apis/apis";
import Table from "../../../../components/Table/Table";
import Badge from "../../../../components/Badge";
import Button from "../../../../components/Form/Button";
import Modal from "../../../../components/Modal";
import ActionMenu from "../../../../components/ActionMenu";
import Panel from "../../../../components/Panel";
import InlineEditInput from "../../../../components/Form/InlineEditInput";
import AddPaymentForm from "./PostPaymentForm";
import CurrentInvoiceSummary from "./CurrentInvoiceSummary";
import { formatUTCDate, formatCurrency } from "../../../../util/string";
import { Payment, ServiceAccount } from "../../../../apis/types";
import { useAppDispatch, useAppSelector } from "../../../../shared/redux/hooks";

const getPaymentTypeMap = (key: string) => {
  type paymentType = "ONE_TIME_PAYMENT" | "AUTOPAY";
  const paymentTypeMap = {
    ONE_TIME_PAYMENT: "One-time payment",
    AUTOPAY: "AutoPay",
  };
  return paymentTypeMap[(key as unknown as paymentType) || "Unknown"];
};

export interface TabPaymentsProps {
  title: string;
  serviceAccount: ServiceAccount;
}

const TabPayments: React.FC<TabPaymentsProps> = (props) => {
  const [confirmModal, setConfirmModal] = useState<React.ReactNode>(false);
  const { serviceAccount, ...rest } = props;
  const dispatch = useAppDispatch();
  const { permissions } = useAppSelector((state) => state.app);

  const { tenantId } = useParams();
  const [currentTenantId, setCurrentTenantId] = useState(tenantId);

  useEffect(() => {
    setCurrentTenantId(tenantId);
  }, [tenantId]);

  const transformResponse = (
    response: Payment[]
  ): Array<Payment & { display: string; paymentTypeDisplay: string }> =>
    response.map((res) => {
      const { type } = res;
      const [paymentType] = type?.split(" - ") ?? [];
      return {
        ...res,
        display:
          res.providerName === "CHECK"
            ? "Check"
            : `${res.providerName} (••••${res.last4})`,
        paymentTypeDisplay: getPaymentTypeMap(paymentType),
      };
    });

  const handlePaymentAction = async (
    payment: Payment,
    newStatus: string
  ): Promise<void> => {
    try {
      await new Promise((resolve, reject) => {
        setConfirmModal(<Modal resolve={resolve} reject={reject} />);
      });
      setConfirmModal(false);
      try {
        await updatePaymentApi({
          paymentId: payment.id,
          newStatus,
        });
        dispatch(
          pushToast({
            type: "success",
            message: "Payment status updated",
          })
        );

        if (currentTenantId !== null) {
          dispatch(getTenant(tenantId ?? ""));
        }
      } catch (e: any) {
        dispatch(
          pushToast({
            type: "error",
            message: "Error updating payment status",
            description: `${
              e.response?.data?.message !== undefined
                ? `${e.response?.data?.message as string}:`
                : "Error:"
            } ${e.message as string}`,
          })
        );
      }
    } catch (e) {
      setConfirmModal(false);
    }
  };

  const hasPaymentOperationsPermissions = (): boolean => {
    if (permissions == null) return false;
    return (
      permissions.includes("payops:cancel:payment") &&
      permissions.includes("payops:update:payment")
    );
  };

  if (serviceAccount.currentInvoice === undefined) {
    return (
      <div>An invoice has not yet been posted for this service account</div>
    );
  }

  return (
    <>
      {serviceAccount.payments?.length > 0 ? (
        <>
          <Table<Payment>
            colConfig={[
              {
                label: "Payment Number",
                render: (record) => record.paymentNumber,
              },
              {
                label: "Amount",
                render: (record) => formatCurrency(record.amount),
              },
              {
                label: "Frequency",
                render: (record) => record.paymentTypeDisplay,
              },
              {
                label: "Type",
                render: (record) => record.display,
              },
              {
                label: "Payment Date",
                render: (record) => formatUTCDate(new Date(record.paymentDate)),
              },
              {
                label: "Posting Date",
                render: (record) => formatUTCDate(new Date(record.postingDate)),
              },
              {
                label: "Memo",
                render: (record) => (
                  <InlineEditInput
                    name="memo"
                    label="Payment memo"
                    hideLabel
                    initialValue={record.memo}
                    handleSubmit={async (editedValue) => {
                      await updatePaymentApi({
                        paymentId: record.id,
                        memo: editedValue,
                      });
                      dispatch(getTenant(currentTenantId ?? ""));
                    }}
                  />
                ),
              },
              {
                label: "Status",
                render: (record) => <Badge status={record.status} />,
              },
              ...(hasPaymentOperationsPermissions()
                ? [
                    {
                      label: "Change status",
                      isHidden:
                        serviceAccount.payments.filter(
                          (payment) => payment.nextStatus?.length ?? 0 > 0
                        ).length === 0,
                      render: (record: Payment) => (
                        <div>
                          <ActionMenu
                            handleChange={async (status) =>
                              await handlePaymentAction(record, status ?? "")
                            }
                            options={record.nextStatus?.map((nextStatus) => ({
                              value: nextStatus,
                            }))}
                          />
                        </div>
                      ),
                    },
                  ]
                : []),
            ]}
            hidePaginator
            data={{
              results: transformResponse(serviceAccount.payments),
            }}
            {...rest}
          />
          <Link
            to={`/collections/payments?tenantId=${currentTenantId ?? ""}`}
            className="inline-block mt-6 mr-4"
          >
            <Button>View all tenant payments</Button>
          </Link>
        </>
      ) : (
        <span>No payments exist yet for this service account</span>
      )}

      {serviceAccount.currentInvoice != null && (
        <div className="mt-6 grid grid-cols-2 gap-8">
          <CurrentInvoiceSummary serviceAccount={serviceAccount} />
          <Panel header="Post payment" className="min-w-max">
            <AddPaymentForm serviceAccount={serviceAccount} />
          </Panel>
        </div>
      )}
      {confirmModal}
    </>
  );
};

export default TabPayments;
