import { useRouteError } from 'react-router-dom'
import * as Sentry from '@sentry/react'

function ErrorBoundary() {
  const error = useRouteError()
  console.error(error)
  Sentry.captureException(error, {
    tags: {
      errorMessageDisplayed: true,
    },
  })
  return (
    <div style={{ padding: 20 }}>
      <div className="text-md font-semibold">An error occurred</div>
      <div className="text-sm">{(error as { message: string })?.message}</div>
    </div>
  )
}

export default ErrorBoundary
