import React from "react";
import { useTable } from "react-table";
import { format } from "date-fns";
import { UserStatusBadge } from "../../shared/components/UserStatusBadge";
import type { User } from "../../shared/types/models";
import type { Column, CellProps } from "react-table";

export interface UsersListProps {
  users: User[];
  handleRowClick?: (user: User) => void;
  handleRowMouseOver?: (user: User) => void;
}

export const UsersList: React.FC<UsersListProps> = (props) => {
  const columns = React.useMemo<ReadonlyArray<Column<User>>>(
    () => [
      {
        Header: "Contact",
        id: "contact",
        accessor: (user) => `${user.firstName} ${user.lastName}`,
        Cell: ({ row: { original: user } }: CellProps<User, string>) => (
          <>
            <div className="flex items-baseline">
              <span className="mr-2 text-sm font-medium text-gray-700">
                {user.firstName + " " + user.lastName}
              </span>
              <UserStatusBadge status={user.status} />
            </div>
            <span className="text-sm font-medium text-gray-500">
              {user.emailAddress}
            </span>
          </>
        ),
      },
      {
        Header: "Roles",
        accessor: "roles",
        Cell: ({ row: { original: user } }: { row: { original: User } }) =>
          user.roles.length > 0 ? (
            <span className="text-sm text-gray-700 whitespace-normal">
              {user.roles.map((role) => role.name).join(", ")}
            </span>
          ) : (
            <span className="text-sm text-gray-700">N/A</span>
          ),
      },
      {
        Header: "Last login",
        accessor: "lastLogin",
        Cell: ({ row: { original: user } }) =>
          user.lastLogin !== null ? (
            <div className="flex flex-col">
              <span className="text-sm text-gray-700">
                {format(new Date(user.lastLogin), "MMM d, yyyy")}
              </span>
              <span className="text-sm text-gray-500">
                {format(new Date(user.lastLogin), "h:mm aa")}
              </span>
            </div>
          ) : (
            <span className="text-sm text-gray-700">N/A</span>
          ),
      },
      {
        Header: "Created at",
        accessor: "createdAt",
        Cell: ({ row: { original: user } }) => (
          <div className="flex flex-col">
            <span className="text-sm text-gray-700">
              {format(new Date(user.createdAt), "MMM d, yyyy")}
            </span>
            <span className="text-sm text-gray-500">
              {format(new Date(user.createdAt), "h:mm aa")}
            </span>
          </div>
        ),
      },
    ],
    []
  );

  const table = useTable({ data: props.users, columns });

  return (
    <div className="flex flex-col">
      <div className="rounded-lg shadow overflow-x-auto">
        <div className="align-middle inline-block min-w-full">
          <div className="overflow-hidden border-b border-gray-200">
            <table
              {...table.getTableProps()}
              className="min-w-full divide-y divide-gray-200"
            >
              <thead className="bg-gray-50">
                {table.headerGroups.map((headerGroup) => {
                  const { key, ...headerGroupProps } =
                    headerGroup.getHeaderGroupProps();

                  return (
                    <tr key={key} {...headerGroupProps}>
                      {headerGroup.headers.map((column) => {
                        const { key, ...headerProps } = column.getHeaderProps();

                        return (
                          <th
                            key={key}
                            {...headerProps}
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {column.render("Header")}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
              <tbody
                {...table.getTableBodyProps()}
                className="bg-white divide-y divide-gray-200"
              >
                {table.rows.map((row, rowIdx) => {
                  table.prepareRow(row);

                  const { key, ...rowProps } = row.getRowProps();

                  return (
                    <tr
                      key={key}
                      {...rowProps}
                      onClick={() => props.handleRowClick?.(row.original)}
                      onMouseOver={() =>
                        props.handleRowMouseOver?.(row.original)
                      }
                      className={`cursor-pointer ${
                        rowIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                      }`}
                    >
                      {row.cells.map((cell) => {
                        const { key, ...cellProps } = cell.getCellProps();

                        return (
                          <td
                            key={key}
                            {...cellProps}
                            className="px-6 py-4 whitespace-nowrap"
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
