import React, { useState } from 'react'
import Table from '../../../../components/Table/Table'
import Panel from '../../../../components/Panel'
import AddAccountManagerForm from './AddAccountManagerForm'
import Modal from '../../../../components/Modal'
import { impersonateApi } from './../../../../apis/apis'
import { formatPhoneNumber } from '../../../../util/string'
import { AccountManager } from '../../../../apis/types'
import { pushToast } from '../../../../components/Toaster/Toaster.slice'
import { format } from 'date-fns'
import { useAppDispatch, useAppSelector } from '../../../../shared/redux/hooks'
import { disableAccountManagerApi } from './../../../../apis/apis'
import classnames from 'classnames'

interface TabAccountManagersProps {
  title: string
  data: AccountManager[]
  tenantAccountCuid: string
}

const TabAccountManagers: React.FC<TabAccountManagersProps> = (props) => {
  const { tenantAccountCuid, data, ...rest } = props
  const { permissions } = useAppSelector((state) => state.app)
  const dispatch = useAppDispatch()

  const [confirmAccountManagerDisableModal, setConfirmAccountManagerDisableModal] = useState<React.ReactElement | boolean>(false)

  const hasImpersonatePermission = (): boolean => {
    if (permissions == null) return false
    return permissions.includes('impersonate:tenant')
  }

  const hasCopyAccountInvitationPermission = (): boolean => {
    if (permissions == null) return false
    return permissions.includes('account:manager:copyinvite')
  }

  const hasDisableAccountManagerPermission = (): boolean => {
    if (permissions == null) return false

    return permissions.includes('account:manager:disable')
  }

  const handleImpersonate = async (accountManager: AccountManager): Promise<void> => {
    const { impersonationToken, tokenExpirationSeconds } = await impersonateApi(accountManager.id)

    const impersonationWindow = window.open('/impersonate')
    impersonationWindow != null && (impersonationWindow.window.impersonation_ephemeralToken = impersonationToken)
    impersonationWindow != null && (impersonationWindow.window.impersonation_tokenExpirationSeconds = tokenExpirationSeconds)
    impersonationWindow != null && (impersonationWindow.window.impersonation_email = accountManager.email)
    impersonationWindow != null && (impersonationWindow.window.impersonation_tenantAccountCuid = tenantAccountCuid)
  }

  const handleDisableAccountManager = async (accountManager: AccountManager): Promise<void> => {
    try {
      await new Promise((resolve, reject) => {
        setConfirmAccountManagerDisableModal(<Modal resolve={resolve} reject={reject} />)
      })
      setConfirmAccountManagerDisableModal(false)
      try {
        const payload = {
          tenantAccountId: tenantAccountCuid,
          emailAddress: accountManager.email,
        }

        await disableAccountManagerApi(payload)

        await dispatch(
          pushToast({
            message: 'Account manager disabled',
            type: 'success',
          })
        )

        window.location.reload()
      } catch (e: any) {
        await dispatch(
          pushToast({
            message: 'Failed to disable account manager',
            type: 'error',
            description: `${e.response?.data?.message !== undefined ? `${e.response?.data?.message as string}:` : 'Error:'} ${e.message as string}`,
          })
        )
      }
    } catch (e) {
      setConfirmAccountManagerDisableModal(false)
    }
  }

  const handleCopyInvitationToClipboard = async (accountManager: AccountManager): Promise<void> => {
    const { accountSignupLink } = accountManager
    if (accountSignupLink == null) return
    try {
      await navigator.clipboard.writeText(accountSignupLink)
      await dispatch(
        pushToast({
          message: 'Account invitation link copied to clipboard',
          type: 'success',
        })
      )
    } catch (e: any) {
      await dispatch(
        pushToast({
          message: 'Failed to copy account invitation link to clipboard',
          type: 'error',
          description: `${e.response?.data?.message !== undefined ? `${e.response?.data?.message as string}:` : 'Error:'} ${e.message as string}`,
        })
      )
    }
  }

  return (
    <>
      {data.length > 0 ? (
        <Table<AccountManager>
          colConfig={[
            {
              label: 'Name',
              render: (record) => (
                <div className={classnames(record.isActive === false ? 'text-slate-500' : '')}>{`${record.profile?.firstName ?? ''} ${
                  record.profile?.lastName ?? ''
                }`}</div>
              ),
            },
            {
              label: 'Email Address',
              render: (record) => <div className={classnames(record.isActive === false ? 'text-slate-500' : '')}>{record.email}</div>,
            },
            {
              label: 'Phone number',
              render: (record) => (
                <div className={classnames(record.isActive === false ? 'text-slate-500' : '')}>
                  {formatPhoneNumber(record.profile?.phoneNumber ?? '') ?? ''}
                </div>
              ),
            },
            {
              label: 'Last Login',
              render: (record) => (
                <div className={classnames(record.isActive === false ? 'text-slate-500' : '')}>
                  {record.lastLoginDate != null ? format(new Date(record.lastLoginDate), 'MM/dd/yyyy, h:mm a ') : 'N/A'}
                </div>
              ),
            },
            ...(hasCopyAccountInvitationPermission() || hasImpersonatePermission() || hasDisableAccountManagerPermission()
              ? [
                  {
                    label: 'Actions',
                    render: (record: AccountManager) => (
                      <div className="flex flex-row justify-between">
                        {record.isActive && hasImpersonatePermission() && (
                          <button
                            type="button"
                            onClick={() => {
                              void handleImpersonate(record)
                            }}
                            className="inline-flex records-center mr-2 px-2.5 py-1.5 border border-transparent text-sm font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Impersonate
                          </button>
                        )}
                        {record.isActive && record.accountSignupLink != null && record.accountSignupLink && hasCopyAccountInvitationPermission() && (
                          <button
                            type="button"
                            onClick={() => {
                              void handleCopyInvitationToClipboard(record)
                            }}
                            className="inline-flex records-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Copy Account Invitation
                          </button>
                        )}
                        {record.isActive && hasDisableAccountManagerPermission() && (
                          <button
                            type="button"
                            onClick={() => {
                              void handleDisableAccountManager(record)
                            }}
                            className="inline-flex records-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                          >
                            Disable
                          </button>
                        )}
                      </div>
                    ),
                  },
                ]
              : []),
          ]}
          hidePaginator
          data={{
            results: data,
          }}
          {...rest}
        />
      ) : (
        <span>No account managers found</span>
      )}
      <div className="mt-6">
        <Panel header="Add a new account manager" className="min-w-max">
          <AddAccountManagerForm tenantAccountCuid={tenantAccountCuid} />
        </Panel>
      </div>
      {confirmAccountManagerDisableModal}
    </>
  )
}

export default TabAccountManagers
