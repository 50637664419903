import React from 'react'
import { Disclosure } from '@headlessui/react'
import classnames from 'classnames'

interface IncentivePlanDetailsDisclosureProps {
  label: string
  contents: JSX.Element
  defaultOpen?: boolean
}
const IncentivePlanDetailsDisclosure: React.FC<IncentivePlanDetailsDisclosureProps> = ({ label, contents, defaultOpen }) => {
  return (
    <div className="mt-6 w-full rounded-2xl bg-white p-2">
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={classnames(
                'flex w-full justify-between bg-indigo-100 px-4 py-2 text-left text-sm font-medium text-indigo-900 hover:bg-indigo-200 focus:outline-none focus-visible:ring focus-visible:ring-indigo-500/75',
                open ? 'rounded-t-lg' : 'rounded-lg'
              )}
            >
              <span>{label}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-indigo-500`}
              >
                <path
                  fillRule="evenodd"
                  d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                  clipRule="evenodd"
                />
              </svg>
            </Disclosure.Button>
            <Disclosure.Panel className="p-4 text-sm bg-slate-100 rounded-b-2xl">
              <div className="w-full h-full">{contents}</div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  )
}

export default IncentivePlanDetailsDisclosure
