import React, { useState, useEffect } from 'react'
import classnames from 'classnames'

export interface TabPanelProps {
  selectedKey?: number
  children: React.ReactElement[]
}

export const TabPanel: React.FC<TabPanelProps> = (props) => {
  const [selectedKey, setSelectedKey] = useState(props.selectedKey ?? 0)

  useEffect(() => {
    setSelectedKey(props.selectedKey ?? 0)
  }, [props.selectedKey])

  const tabs = props.children?.map((child) => ({
    name: child.props.title,
    key: child.key,
  }))

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-base border-zinc-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          defaultValue={tabs.find((tab: any) => tab.current)?.name}
        >
          {tabs.map((tab: any) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="sm:block">
        <div>
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab: any, index: any) => (
              <a
                key={tab.name}
                onClick={() => setSelectedKey(index)}
                className={classnames(
                  selectedKey === index ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-zinc-500 hover:text-zinc-700 hover:border-zinc-300',
                  'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                )}
                aria-current={selectedKey === index ? 'page' : undefined}
              >
                {tab.name}
              </a>
            ))}
          </nav>
          <div className="py-6">{props.children[selectedKey]}</div>
        </div>
      </div>
    </div>
  )
}

export default TabPanel
