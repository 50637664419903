import React from "react";
import Toast from "./Toast";
import { useAppSelector } from "../../shared/redux/hooks";
// import { XIcon } from '@heroicons/react/24/solid'

export const Toaster: React.FC = () => {
  const toasts = useAppSelector((state) => state.toaster.data);

  return (
    <div
      aria-live="assertive"
      className="fixed z-30 inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
    >
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        {toasts.map((toast, index) => (
          <Toast data={toast} key={index} />
        ))}
      </div>
    </div>
  );
};

export default Toaster;
