import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getTenants, getPropertyList, getUtilityList } from "./Tenants.slice";
import Layout from "./Layout";
import Table from "../../../components/Table/Table";
import QuickSearch from "../../../components/QuickSearch/QuickSearch";
import ComboboxAutocomplete from "../../../components/Form/ComboboxAutocomplete";
import Panel from "../../../components/Panel";
import { TenantItem } from "../../../apis/types";
import qs from "qs";
import { useAppDispatch, useAppSelector } from "../../../shared/redux/hooks";

const Tenants: React.FC = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const propertyId = searchParams.get("propertyId");
  const utilityId = searchParams.get("utilityId");
  const [quickSearchTerm, setQuickSearchTerm] = useState<string | undefined>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { tenants, properties, utilities } = useAppSelector(
    (state) => state.tenants
  );

  useEffect(() => {
    properties?.length === 0 && dispatch(getPropertyList());
    utilities?.length === 0 && dispatch(getUtilityList());
    void dispatch(
      getTenants({
        page: 0,
        propertyId: propertyId?.toString(),
        utilityId: utilityId?.toString(),
        tenantName: quickSearchTerm,
      })
    );
  }, [propertyId, utilityId, quickSearchTerm, dispatch]);

  const handleClick = (record: TenantItem): void => {
    navigate(`/tenant/tenants/${record.id}`);
  };

  const handlePaginatorClick = (page: number): void => {
    void dispatch(
      getTenants({
        page,
        propertyId: propertyId?.toString(),
        utilityId: utilityId?.toString(),
        tenantName: quickSearchTerm,
      })
    );
  };

  const handleQuickSearchChange = (value: string): void => {
    if (value === "") {
      setQuickSearchTerm(undefined);
      return;
    }
    setQuickSearchTerm(value);
  };

  const handleSelectProperty = (propertyId: string | undefined): void => {
    navigate(
      {
        search: qs.stringify(
          { propertyId },
          { addQueryPrefix: true, skipNulls: true }
        ),
      },
      { replace: true }
    );
  };

  const handleSelectUtility = (utilityId: string | undefined): void => {
    navigate(
      {
        search: qs.stringify(
          { utilityId },
          { addQueryPrefix: true, skipNulls: true }
        ),
      },
      { replace: true }
    );
  };

  return (
    <>
      <Layout title="Tenants">
        <Panel header="Filters" className="mb-5">
          <div className="grid grid-auto-flow grid-cols-3 gap-3">
            <QuickSearch
              title="Tenant name"
              initialValue={quickSearchTerm}
              handleChange={handleQuickSearchChange}
            />
            <div>
              <label
                htmlFor="propertyNames"
                className="block text-sm font-medium text-zinc-700"
              >
                Property
              </label>
              {properties !== undefined && (
                <ComboboxAutocomplete
                  labelPlural="Properties"
                  value={propertyId ?? ""}
                  handleChange={handleSelectProperty}
                  data={properties?.map(
                    (property: { id: string; nameLocation: string }) => ({
                      id: property.id,
                      name: property.nameLocation,
                    })
                  )}
                />
              )}
            </div>
            <div>
              <label
                htmlFor="utilityNames"
                className="block text-sm font-medium text-zinc-700"
              >
                Utility
              </label>
              {utilities !== undefined && (
                <ComboboxAutocomplete
                  labelPlural="Utilities"
                  value={utilityId ?? ""}
                  handleChange={handleSelectUtility}
                  data={utilities?.map(
                    (utility: { id: string; name: string }) => ({
                      id: utility.id,
                      name: utility.name,
                    })
                  )}
                />
              )}
            </div>
          </div>
        </Panel>
        {!(tenants == null) && (
          <Table<TenantItem>
            colConfig={[
              {
                label: 'Tenant',
                render: (rec) => rec.name.split('-')?.[0],
              },
              {
                label: 'Property',
                render: (record) => record.property?.name,
              },
              {
                label: 'Location',
                render: (record) => record.property?.location,
              },
              {
                label: 'Utility',
                render: (rec) => rec.property?.utilityShortName,
              },
            ]}
            data={tenants}
            handleClick={handleClick}
            handlePaginatorClick={handlePaginatorClick}
          />
        )}
      </Layout>
    </>
  );
};

export default Tenants;
