import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getProgramsApi,
  getProgramApi,
  getPropertyListApi,
} from "../../apis/apis";

import type {
  Paginated,
  GetProgramsArgs,
  GetProgramsListData,
  ProgramItem,
  VnemAllocationItem,
  DetailedProgramItem,
} from "../../apis/types";
import type { RootState, AppDispatch } from "../../shared/redux/store";

const SLICE_NAME = "PROGRAMS_SLICE";

export const getPrograms = createAsyncThunk<
  GetProgramsListData,
  GetProgramsArgs,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: { err: boolean };
  }
>(`${SLICE_NAME}/getPrograms`, async (query, { rejectWithValue }) => {
  try {
    const response = await getProgramsApi(query);
    return response;
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    });
  }
});

export const getPropertyList = createAsyncThunk<
  Array<{
    id: string;
    nameLocation: string;
    name: string;
    location: string;
  }>,
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: { err: boolean };
  }
>(`${SLICE_NAME}/getPropertyList`, async (_, { rejectWithValue }) => {
  try {
    const properties = await getPropertyListApi();
    return properties.map((p) => {
      const [name, location] = p.name.split(" - ");
      return {
        id: p.id,
        nameLocation: p.name,
        name,
        location,
      };
    });
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    });
  }
});

export const getProgram = createAsyncThunk<
  DetailedProgramItem,
  string,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: { err: boolean };
  }
>(`${SLICE_NAME}/getProgram`, async (programId, { rejectWithValue }) => {
  try {
    const response = await getProgramApi(programId);
    return response;
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    });
  }
});

interface state {
  loading: boolean;
  error: boolean;
  programs: Paginated<ProgramItem> | undefined;
  properties: Array<{
    id: string;
    nameLocation: string;
    name: string;
    location: string;
  }>;
  program: DetailedProgramItem | undefined;
  vnemAllocations: Paginated<VnemAllocationItem> | undefined;
}

const initialState: state = {
  loading: false,
  error: false,
  programs: undefined,
  properties: [],
  program: undefined,
  vnemAllocations: undefined,
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPrograms.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getPrograms.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.programs = payload;
    });
    builder.addCase(getPrograms.rejected, (state, { payload, error }) => {
      state.loading = false;
      if (payload != null) {
        state.error = payload.err;
      } else {
        state.error = error !== undefined;
      }
    });
    builder.addCase(getPropertyList.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getPropertyList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.properties = payload;
    });
    builder.addCase(
      getPropertyList.rejected,
      (state: state, { payload, error }) => {
        state.loading = false;

        if (payload != null) {
          state.error = payload.err;
        } else {
          state.error = error !== undefined;
        }
      }
    );
    builder.addCase(getProgram.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getProgram.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.program = payload;
    });
    builder.addCase(getProgram.rejected, (state, { payload, error }) => {
      state.loading = false;
      if (payload != null) {
        state.error = payload.err;
      } else {
        state.error = error !== undefined;
      }
    });
  },
});

export const programsReducer = slice.reducer;
