import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../api";

import type { User, Role } from "./types/models";
import type {
  CreateUserParams,
  FindUserByIDParams as FindOneUserByIDParams,
  UpdateUserParams,
  AssignRolesToUserParams,
  UnassignRolesFromUserParams,
} from "./types/api";

export const usersAPI = createApi({
  reducerPath: "users",
  baseQuery: axiosBaseQuery({ baseUrl: "/api/v1/admin" }),
  tagTypes: ["User", "Role"],
  endpoints: (builder) => ({
    createUser: builder.mutation<User, CreateUserParams>({
      query: (data) => ({
        method: "POST",
        url: "/users",
        data,
      }),
      invalidatesTags: (result) =>
        result !== undefined ? [{ type: "User", id: "LIST" }] : [],
    }),
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    findUsers: builder.query<User[], void>({
      query: () => ({
        method: "GET",
        url: "/users",
      }),
      providesTags: (result) =>
        result !== undefined
          ? [
              ...result.map(({ id }) => ({ type: "User" as const, id })),
              { type: "User", id: "LIST" },
            ]
          : [{ type: "User", id: "LIST" }],
    }),
    findUserByID: builder.query<User, FindOneUserByIDParams>({
      query: (data) => ({
        method: "GET",
        url: `/users/${data.userID}`,
      }),
      providesTags: (result) =>
        result !== undefined ? [{ type: "User", id: result.id }] : [],
    }),
    updateUser: builder.mutation<User, UpdateUserParams>({
      query: (data) => ({
        method: "PATCH",
        url: `/users/${data.userID}`,
        data,
      }),
      onQueryStarted: async ({ userID }, { dispatch, queryFulfilled }) => {
        const { data: updated } = await queryFulfilled;

        dispatch(
          usersAPI.util.updateQueryData("findUsers", undefined, (users) => {
            for (const user of users) {
              if (user.id === userID) {
                Object.assign(user, updated);

                break;
              }
            }
          })
        );
        dispatch(
          usersAPI.util.updateQueryData("findUserByID", { userID }, (user) => {
            Object.assign(user, updated);
          })
        );
      },
    }),
    assignRolesToUser: builder.mutation<User, AssignRolesToUserParams>({
      query: (data) => ({
        method: "POST",
        url: `/users/${data.userID}/roles`,
        data,
      }),
      onQueryStarted: async ({ userID }, { dispatch, queryFulfilled }) => {
        const { data: updated } = await queryFulfilled;

        dispatch(
          usersAPI.util.updateQueryData("findUsers", undefined, (users) => {
            for (const user of users) {
              if (user.id === userID) {
                Object.assign(user, updated);

                break;
              }
            }
          })
        );
        dispatch(
          usersAPI.util.updateQueryData("findUserByID", { userID }, (user) => {
            Object.assign(user, updated);
          })
        );
      },
    }),
    unassignRolesFromUser: builder.mutation<User, UnassignRolesFromUserParams>({
      query: (data) => ({
        method: "DELETE",
        url: `/users/${data.userID}/roles`,
        data,
      }),
      onQueryStarted: async ({ userID }, { dispatch, queryFulfilled }) => {
        const { data: updated } = await queryFulfilled;

        dispatch(
          usersAPI.util.updateQueryData("findUsers", undefined, (users) => {
            for (const user of users) {
              if (user.id === userID) {
                Object.assign(user, updated);

                break;
              }
            }
          })
        );
        dispatch(
          usersAPI.util.updateQueryData("findUserByID", { userID }, (user) => {
            Object.assign(user, updated);
          })
        );
      },
    }),
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    findRoles: builder.query<Role[], void>({
      query: () => ({
        method: "GET",
        url: "/roles",
      }),
      providesTags: (result) =>
        result !== undefined
          ? [
              ...result.map(({ id }) => ({ type: "Role" as const, id })),
              { type: "Role", id: "LIST" },
            ]
          : [{ type: "Role", id: "LIST" }],
    }),
  }),
});

export const usersReducer = usersAPI.reducer;
