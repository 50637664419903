import React from 'react'

export const LogoutIcon: React.FC = () => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Nav" transform="translate(-33.000000, -721.000000)" fill="currentColor" fillRule="nonzero">
          <g id="Group" transform="translate(33.000000, 721.000000)">
            <path
              d="M24,0 L24,24 L7.14893617,24 L7.14893617,22.4 L22.3951368,22.4 L22.3951368,1.6 L7.14893617,1.6 L7.14893617,0 L24,0 Z M5.70385614,6.63829787 L6.83919851,7.7562571 L3.07347236,11.4647037 L16.8510638,11.4647037 L16.8510638,13.0458358 L3.07347236,13.0458852 L6.83919851,16.7542824 L5.70385614,17.8723404 L0,12.2553191 L5.70385614,6.63829787 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LogoutIcon
