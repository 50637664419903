import React from 'react'

export interface RegistrationListHeaderProps {
  areActionsHidden: boolean
}

export const RegistrationListHeader: React.FC<RegistrationListHeaderProps> = (props) => {
  return (
    <>
      <tr>
        <th scope="col" className="px-6 py-3 w-1/3 text-left text-xs font-medium text-zinc-500 uppercase tracking-wider">
          Tenant name
        </th>
        <th scope="col" className="px-6 py-3 w-1/3 text-left text-xs font-medium text-zinc-500 uppercase tracking-wider">
          Contact
        </th>
        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-zinc-500 uppercase tracking-wider">
          Status
        </th>
        {!props.areActionsHidden && (
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-zinc-500 uppercase tracking-wider">
            <span className="sr-only">Actions</span>
          </th>
        )}
      </tr>
    </>
  )
}

export default RegistrationListHeader
