import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Input from "../../../../components/Form/Input";
import Button from "../../../../components/Form/Button";
import Select from "../../../../components/Form/Select";
import Modal from "../../../../components/Modal";
import { getTenant } from "../Tenants.slice";
import { pushToast } from "../../../../components/Toaster/Toaster.slice";
import { addPaymentApi } from "../../../../apis/apis";
import { formatCurrency } from "../../../../util/string";
import { ServiceAccount } from "../../../../apis/types";
import { useAppDispatch, useAppSelector } from "../../../../shared/redux/hooks";

export interface PostPaymentFormProps {
  serviceAccount: ServiceAccount;
}

export const PostPaymentForm: React.FC<PostPaymentFormProps> = (props) => {
  const tenantID = useAppSelector((state) => state.tenants.tenant?.id);
  const dispatch = useAppDispatch();
  const { serviceAccount } = props;
  const { permissions } = useAppSelector((state) => state.app);
  const [confirmModal, setConfirmModal] = useState<
    React.ReactElement | boolean
  >(false);

  const hasTakePaymentPermission = (): boolean =>
    permissions != null ? permissions.includes("payops:take:payment") : false;

  const d = new Date();
  const defaultDate = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;

  const paymentMethodOptionsForUser = (userCuid: string): any =>
    [
      ...serviceAccount.accountManagers.filter(
        (am: any) => am.id === userCuid
      )[0].paymentMethods,
    ]
      .sort((a, b) => {
        if ((a.default ?? false) && !(b.default ?? false)) {
          return -1;
        }
        if ((b.default ?? false) && !(a.default ?? false)) {
          return 1;
        }
        return 0;
      })
      .sort((a, b) => {
        if (
          a.paymentMethodType === "CHECK" ||
          b.paymentMethodType !== "CHECK"
        ) {
          return -1;
        }
        if (
          b.paymentMethodType === "CHECK" ||
          a.paymentMethodType !== "CHECK"
        ) {
          return 1;
        }
        return 0;
      })
      .map((v) => ({
        id: v.id,
        label:
          v.paymentMethodType === "CHECK"
            ? "Check"
            : `${v.providerName} (••••${v.last4}) ${
                v.default === true ? " — Primary method" : ""
              }`,
        paymentMethodType: v.paymentMethodType,
      }));

  return (
    <Formik
      initialValues={{
        user: serviceAccount.accountManagers[0].id,
        paymentMethodId:
          paymentMethodOptionsForUser(serviceAccount.accountManagers[0].id)?.[0]
            ?.id ?? serviceAccount.accountManagers[0].paymentMethods[0].id,
        amount: "",
        paymentDate: defaultDate,
        memo: "",
      }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        amount: Yup.string()
          .required("Required field")
          .test("num", "Must be a number", function (value: any) {
            return !isNaN(Number(value));
          })
          .test("min", "Must be greater than zero", function (value: any) {
            return Number(value) > 0;
          })
          .matches(
            /^[0-9]+(.[0-9]{2})?$/,
            "Please enter whole dollars and cents (e.g. 100.00)"
          )
          .test(
            "max",
            `Non-check payments cannot exceed remaining balance of ${formatCurrency(
              serviceAccount.currentInvoice.remainingBalance
            )}`,
            function (value: any) {
              return (
                Number(value) <=
                  Number(serviceAccount.currentInvoice.remainingBalance) ||
                paymentMethodOptionsForUser(this.parent.user).find(
                  (pm: any) => pm.paymentMethodType === "CHECK"
                )?.id === this.parent.paymentMethodId
              );
            }
          ),
        paymentDate: Yup.string()
          .required("Required field")
          .matches(
            /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/,
            "M/D/YYYY format required"
          ),
        user: Yup.string().required("Required field"),
        paymentMethodId: Yup.string().required("Required field"),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const [month, day, year] = values.paymentDate.split("/");
        const paymentDate = new Date(
          Number(year),
          Number(month) - 1,
          Number(day)
        ).getTime();

        try {
          await new Promise((resolve, reject) => {
            setConfirmModal(<Modal resolve={resolve} reject={reject} />);
          });
          setConfirmModal(false);
          try {
            await addPaymentApi({
              amount: Number(values.amount),
              paymentDate,
              paymentMethodId: values.paymentMethodId,
              memo: values.memo,
              userId: values.user,
              invoiceId: serviceAccount.currentInvoice.id,
            });
            resetForm({});
            dispatch(pushToast({ message: "Payment posted" }));

            if (tenantID !== undefined) {
              dispatch(getTenant(tenantID));
            }
          } catch (e: any) {
            dispatch(
              pushToast({
                type: "error",
                message: "Error accepting payment",
                description: `${
                  e.response?.data?.message !== undefined
                    ? `${e.response?.data?.message as string}:`
                    : "Error:"
                } ${e.message as string}`,
              })
            );
          }
          setSubmitting(false);
        } catch (e) {
          setConfirmModal(false);
        }
      }}
    >
      {({ values, errors, touched, isValid, setValues }) => (
        <>
          <Form>
            <div className="grid grid-cols-1 gap-3">
              <Field
                component={Select}
                name="user"
                label="On behalf of"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setValues({
                    ...values,
                    user: e.target.value,
                    paymentMethodId: paymentMethodOptionsForUser(
                      e.target.value
                    )[0].id,
                  });
                }}
                options={serviceAccount.accountManagers.reduce(
                  (a, v) => ({
                    ...a,
                    [v.id]: `${
                      v.profile?.firstName !== undefined
                        ? `${v.profile?.firstName} `
                        : ""
                    } ${
                      v.profile?.lastName !== undefined
                        ? `${v.profile?.lastName} — `
                        : ""
                    } ${v.email}`,
                  }),
                  {}
                )}
                error={
                  errors.user !== undefined && (touched.user ?? false)
                    ? errors.user
                    : undefined
                }
              />
              <Field
                component={Select}
                name="paymentMethodId"
                label="Payment method / source"
                options={paymentMethodOptionsForUser(values.user).reduce(
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                  (a: any, c: any): any => ({
                    ...a,
                    [c.id]: c.label,
                  }),
                  {}
                )}
                error={
                  errors.paymentMethodId !== undefined &&
                  touched.paymentMethodId === true
                    ? errors.paymentMethodId
                    : undefined
                }
              />
              <Field
                as={Input}
                name="amount"
                label="Amount"
                error={
                  errors.amount !== undefined && (touched.amount ?? false)
                    ? errors.amount
                    : undefined
                }
              />
              {paymentMethodOptionsForUser(values.user).find(
                (pm: any) => pm.paymentMethodType === "CHECK"
              )?.id === values.paymentMethodId &&
                Number(values.amount) >
                  Number(serviceAccount.currentInvoice.remainingBalance) && (
                  <span className="relative mb-2">
                    <span className="mt-1 text-xs text-yellow-500 absolute -top-3">
                      Payment exceeds remaining balance of{" "}
                      {formatCurrency(
                        serviceAccount.currentInvoice.remainingBalance
                      )}
                    </span>
                  </span>
                )}
              <Field
                as={Input}
                name="paymentDate"
                label="Payment date"
                error={
                  errors.paymentDate !== undefined &&
                  (touched.paymentDate ?? false)
                    ? errors.paymentDate
                    : undefined
                }
              />
              <Field
                as={Input}
                name="memo"
                label="Memo (optional)"
                error={
                  errors.memo !== undefined && (touched.memo ?? false)
                    ? errors.memo
                    : undefined
                }
              />
              <div className="mt-6">
                <Button
                  type="submit"
                  className="w-full"
                  disabled={!isValid || !hasTakePaymentPermission()}
                >
                  Post payment
                </Button>
              </div>
            </div>
          </Form>
          {confirmModal}
        </>
      )}
    </Formik>
  );
};

export default PostPaymentForm;
