import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "./shared/redux/store";
const SLICE_NAME = "APP_SLICE";

export const getUserPermissions = createAsyncThunk<
  string[] | undefined,
  { accessToken: string; isAuthenticated: boolean },
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: { err: boolean };
  }
>(`${SLICE_NAME}/getUserPermissions`, (args, { rejectWithValue }) => {
  try {
    if (args.isAuthenticated) {
      // Parse token to get permissions
      const userPermissions: string[] =
        JSON.parse(atob(args.accessToken.split(".")[1])).permissions ?? [];
      return userPermissions;
    }
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    });
  }
});

interface state {
  loading: boolean;
  error: boolean;
  permissions: string[] | undefined;
}

const initialState: state = {
  loading: false,
  error: false,
  permissions: [],
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserPermissions.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getUserPermissions.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.permissions = payload;
    });
    builder.addCase(getUserPermissions.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error !== undefined;
    });
  },
});

export const appReducer = slice.reducer;
