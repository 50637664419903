import React from "react";
import { usersAPI } from "../shared/api";
import { useParams } from "react-router-dom";
import { UserView } from "./components/UserView";

export const UserPage: React.FC = () => {
  const { userID } = useParams();

  const { data: user } = usersAPI.endpoints.findUserByID.useQuery({
    userID: userID as string,
  });
  const { data: roles } = usersAPI.endpoints.findRoles.useQuery();

  return <UserView user={user} roles={roles} />;
};

export default UserPage;
