import React from 'react'

import classnames from 'classnames'

interface VNEMAllocationWarningProps {
  type: 'warning' | 'error'
  text: string
}

const VNEMWarning: React.FC<VNEMAllocationWarningProps> = ({ type, text }) => {
  return (
    <div
      className={classnames(
        `mb-3 p-2 text-sm font-semibold text-left inline-block rounded-lg `,
        type === 'warning' ? 'bg-orange-100 text-orange-700' : 'bg-red-100 text-red-700'
      )}
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="inline mr-1 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
        />
      </svg>
      <span>{text}</span>
    </div>
  )
}

export default VNEMWarning
