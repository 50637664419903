import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getInvoiceListApi,
  getTenantListApi,
  getPropertyListApi,
} from "../../../apis/apis";
import {
  CollectionsInvoice,
  GetInvoicesListArgs,
  GetInvoicesListData,
  InvoiceSummaries,
  Paginated,
} from "../../../apis/types";
import { AppDispatch, RootState } from "../../../shared/redux/store";

const SLICE_NAME = "INVOICES_SLICE";

export const getInvoiceSummary = createAsyncThunk<
  InvoiceSummaries,
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: { err: boolean };
  }
  // eslint-disable-next-line @typescript-eslint/require-await
>(`${SLICE_NAME}/getInvoiceSummary`, async (_, { rejectWithValue }) => {
  try {
    // TODO to reenable "Trailing 30-day totals" feature remove this hardcoded
    // api response, add back in the commented out code, and import getInvoiceSummaryApi

    // const response = await getInvoiceSummaryApi()
    // return response
    const summary: InvoiceSummaries | undefined = {
      PAID: { number: 0, amount: 0 },
      PAST_DUE: { number: 0, amount: 0 },
      PENDING: { number: 0, amount: 0 },
      PROCESSING: { number: 0, amount: 0 },
      VOID: { number: 0, amount: 0 },
    };
    return summary;
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    });
  }
});

export const getInvoiceList = createAsyncThunk<
  GetInvoicesListData,
  GetInvoicesListArgs,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: { err: boolean };
  }
>(`${SLICE_NAME}/getInvoiceList`, async (query, { rejectWithValue }) => {
  try {
    const response = await getInvoiceListApi(query);
    return response;
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    });
  }
});

export const getTenantList = createAsyncThunk<
  Array<{
    id: string;
    name: string;
  }>,
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: { err: boolean };
  }
>(`${SLICE_NAME}/getTenantList`, async (_, { rejectWithValue }) => {
  try {
    const response = await getTenantListApi();
    return response;
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    });
  }
});

export const getPropertyList = createAsyncThunk<
  Array<{
    id: string;
    name: string;
  }>,
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: { err: boolean };
  }
>(`${SLICE_NAME}/getPropertyList`, async (_, { rejectWithValue }) => {
  try {
    const response = await getPropertyListApi();
    return response;
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    });
  }
});

interface state {
  loading: boolean;
  error: boolean;
  summary?: InvoiceSummaries;
  invoices?: Paginated<CollectionsInvoice>;
  tenants: Array<{
    id: string;
    name: string;
  }>;
  properties: Array<{
    id: string;
    name: string;
  }>;
}

const initialState: state = {
  loading: false,
  error: false,
  summary: undefined,
  invoices: undefined,
  tenants: [],
  properties: [],
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInvoiceSummary.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getInvoiceSummary.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.summary = payload;
    });
    builder.addCase(getInvoiceSummary.rejected, (state, { payload, error }) => {
      state.loading = false;

      if (payload != null) {
        state.error = payload.err;
      } else {
        state.error = error !== undefined;
      }
    });

    builder.addCase(getInvoiceList.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getInvoiceList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.invoices = payload;
    });
    builder.addCase(getInvoiceList.rejected, (state, { payload, error }) => {
      state.loading = false;

      if (payload != null) {
        state.error = payload.err;
      } else {
        state.error = error !== undefined;
      }
    });

    builder.addCase(getTenantList.pending, (state: state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(
      getTenantList.fulfilled,
      (state: state, { payload }: any) => {
        state.loading = false;
        state.error = false;
        state.tenants = payload;
      }
    );
    builder.addCase(
      getTenantList.rejected,
      (state: state, { payload }: any) => {
        state.loading = false;
        state.error = payload;
      }
    );

    builder.addCase(getPropertyList.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getPropertyList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.properties = payload;
    });
    builder.addCase(
      getPropertyList.rejected,
      (state: state, { payload, error }) => {
        state.loading = false;

        if (payload != null) {
          state.error = payload.err;
        } else {
          state.error = error !== undefined;
        }
      }
    );
  },
});

export const invoicesReducer = slice.reducer;
