import React from 'react'

import { RegistrationStatusLabel } from './RegistrationStatusLabel'
import { RegistrationListItemActionsMenu } from './RegistrationListItemActionsMenu'

import type { TenantRegistration } from '../../../../types/registration'
import type { TenantRegistrationActions } from '../../../../types/registration/actions'

export interface RegistrationListItemProps {
  registration: TenantRegistration
  isOpen: boolean
  areActionsHidden: boolean
  onClick: () => void
  onActionClick: (action: TenantRegistrationActions) => void
}

export const RegistrationListItem: React.FC<RegistrationListItemProps> = (props) => {
  return (
    <>
      <tr onClick={props.onClick} className="bg-white cursor-pointer">
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm font-medium text-zinc-900">{props.registration.tenant.name}</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-zinc-900">{`${props.registration.tenant.contact.firstName} ${props.registration.tenant.contact.lastName}`}</div>
          <div className="text-xs text-zinc-500">{props.registration.tenant.contact.emailAddress}</div>
          <div className="text-xs text-zinc-500">{props.registration.tenant.contact.phoneNumber}</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <RegistrationStatusLabel status={props.registration.status} />
        </td>
        {!props.areActionsHidden && (
          <td className="px-6 py-4 whitespace-nowrap">
            <RegistrationListItemActionsMenu status={props.registration.status} onActionClick={props.onActionClick} />
          </td>
        )}
      </tr>
      {props.isOpen &&
        props.registration.tenant.accounts.map((account, accountIdx) => (
          <React.Fragment key={accountIdx}>
            <tr className="bg-blue-50">
              <td />
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="ml-4">
                  <div className="text-sm text-zinc-900">Utility Account</div>
                  <div className="text-xs text-zinc-500">{account.name}</div>
                  <div className="text-xs text-zinc-500">{account.number}</div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-zinc-900">Billing Address</div>
                <div className="text-xs text-zinc-500">{account.billingAddress.address}</div>
                <div className="text-xs text-zinc-500">{`${account.billingAddress.city}, ${account.billingAddress.state}, ${account.billingAddress.zipCode}`}</div>
              </td>
              <td />
            </tr>
            {account.agreements.map((agreement, agreementIdx) => (
              <tr key={agreementIdx} className="bg-orange-100">
                <td />
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="ml-8">
                    <div className="text-sm text-zinc-900">Service Agreement</div>
                    <div className="text-xs text-zinc-500">{agreement.number}</div>
                    <div className="text-xs text-zinc-500">{`${agreement.meterNumber} - ${agreement.tariffSchedule}`}</div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-zinc-900">Service Address</div>
                  <div className="text-xs text-zinc-500">{agreement.serviceAddress.address}</div>
                  <div className="text-xs text-zinc-500">{`${agreement.serviceAddress.city}, ${agreement.serviceAddress.state}, ${agreement.serviceAddress.zipCode}`}</div>
                </td>
                <td />
              </tr>
            ))}
          </React.Fragment>
        ))}
    </>
  )
}

export default RegistrationListItem
