export const isTaggedFile = (filename: string): boolean => {
  const regex = /^\[[^\]]+\].*$/
  return regex.test(filename)
}

// Extract the tag from the filename
export const extractTag = (filename: string): string => {
  const regex = /^\[([^\]]+)\].*$/
  const match = filename.match(regex)
  if (match === null) {
    return ''
  }

  return match[1]
}
