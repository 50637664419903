import axios from 'axios'

import type {
  GetInvoicesListArgs,
  GetInvoicesListData,
  GetPaymentsArgs,
  GetPaymentsData,
  GetTenantData,
  GetTenantsArgs,
  GetTenantsData,
  GetTenantSearchArgs,
  GetTenantSearch,
  InvoiceSummaries,
  Note,
  PaymentSummaries,
  FilesResult,
  Tag,
  LastModifiedObject,
  Opportunity,
  GetFilesArgs,
  Utility,
  AccountManager,
  GetProgramsArgs,
  GetProgramsListData,
  DetailedProgramItem,
  AllocationReadinessResult,
  AllocationSubmission,
  UtilityPortalCredentials,
  LinkUtilityPortalResult,
  ReadinessChecklist,
  UpdateScraperIsActiveResult,
  BillingOptionsResponse,
  UpdateUtilityBillingConfigBody,
  SharedTenantAccountUpdateResponse,
  UpdateKingBillingConfigBody,
  CreateKingIncentivePlanBody,
  CreateUtilityIncentivePlanBody,
  IncentivePlanOptionsResponse,
  BillingSdkTenantAccountBalances,
} from './types'

const BASE_URL = '/api/v1/admin'
const accessToken = localStorage.getItem('accessToken')
axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`

export const getTenantsApi = async (args: GetTenantsArgs): Promise<GetTenantsData> => {
  return await axios.get(`${BASE_URL}/tenant`, { params: args }).then((res) => res.data as GetTenantsData)
}

export const getTenantSearchApi = async (query: GetTenantSearchArgs): Promise<GetTenantSearch> => {
  // eslint-disable-next-line  @typescript-eslint/no-unsafe-return
  return await axios.get(`${BASE_URL}/tenant/search`, { params: query }).then((res) => res?.data as GetTenantSearch)
}

export const getTenantListApi = async (): Promise<
  Array<{
    id: string
    name: string
  }>
> => {
  return await axios.get(`${BASE_URL}/tenant/list`).then(
    (res) =>
      res.data as Array<{
        id: string
        name: string
      }>
  )
}

export const getTenantApi = async (tenantId: string): Promise<GetTenantData> => {
  return await axios.get(`${BASE_URL}/tenant/${tenantId}`).then((res) => res.data as GetTenantData)
}

export const updateTenantApi = async (tenantId: string, payload: unknown): Promise<unknown> => {
  return await axios.put(`${BASE_URL}/tenant/${tenantId}`, payload).then((res) => res.data as unknown)
}

export const updateMeterApi = async (meterId: string, payload: unknown): Promise<unknown> => {
  return await axios.put(`${BASE_URL}/tenant/meter/${meterId}`, payload).then((res) => res.data as unknown)
}

export const updateContactApi = async (tenantId: string, contactId: string, payload: unknown): Promise<unknown> => {
  return await axios.put(`${BASE_URL}/tenant/${tenantId}/contact/${contactId}`, payload).then((res) => res.data as unknown)
}

export const addPaymentApi = async (payload: unknown): Promise<unknown> => {
  return await axios.post(`${BASE_URL}/payment`, payload).then((res) => res.data as unknown)
}

export const updatePaymentApi = async (payload: unknown): Promise<unknown> => {
  return await axios.patch(`${BASE_URL}/payment`, payload).then((res) => res.data as unknown)
}

export const impersonateApi = async (
  accountManagerCuid: string
): Promise<{
  impersonationToken: string
  tokenExpirationSeconds: number
}> => {
  return await axios.post(`${BASE_URL}/auth/user/${accountManagerCuid}/impersonate`).then(
    (res) =>
      res.data as {
        impersonationToken: string
        tokenExpirationSeconds: number
      }
  )
}

export const getPropertyListApi = async (): Promise<
  Array<{
    id: string
    name: string
  }>
> => {
  return await axios.get(`${BASE_URL}/property`).then(
    (res) =>
      res.data as Array<{
        id: string
        name: string
      }>
  )
}

export const getUtilityListApi = async (): Promise<Utility[]> => {
  return await axios.get(`${BASE_URL}/utility`).then((res) => res.data as Utility[])
}

export const generateAccountInvitationApi = async (payload: unknown): Promise<unknown> => {
  return await axios.post(`${BASE_URL}/tenant/seed/invites`, payload).then((res) => res.data as unknown)
}

export const getInvoiceSummaryApi = async (): Promise<InvoiceSummaries> => {
  return await axios.get(`${BASE_URL}/collections/invoice/summary`).then((res) => res.data as InvoiceSummaries)
}

export const updateInvoiceApi = async (invoiceId: string, payload: unknown): Promise<unknown> => {
  return await axios.patch(`${BASE_URL}/invoice/${invoiceId}`, payload).then((res) => res.data as unknown)
}

export const getStatementApi = async (invoiceId: string, type: 'king' | 'utility'): Promise<Blob> => {
  return await axios
    .get(`${BASE_URL}/collections/invoice/${invoiceId}/statement`, {
      params: { type: type },
      responseType: 'arraybuffer',
    })
    .then((response) => response.data as Blob)
}

export const getInvoiceListApi = async (query: GetInvoicesListArgs): Promise<GetInvoicesListData> => {
  return await axios.get(`${BASE_URL}/collections/invoice`, { params: query }).then((res) => res.data as GetInvoicesListData)
}

export const getPaymentSummaryApi = async (): Promise<PaymentSummaries> => {
  return await axios.get(`${BASE_URL}/collections/payment/summary`).then((res) => res.data as PaymentSummaries)
}

export const getPaymentListApi = async (query: GetPaymentsArgs): Promise<GetPaymentsData> => {
  return await axios.get(`${BASE_URL}/collections/payment`, { params: query }).then((res) => res.data as GetPaymentsData)
}

export const updateTenantPreferenceApi = async (payload: unknown): Promise<unknown> => {
  return await axios.patch(`${BASE_URL}/tenant/preference`, payload).then((res) => res.data as unknown)
}

export const postTenantNoteApi = async (payload: { id: string; body: string }): Promise<Note> => {
  return await axios.post(`${BASE_URL}/tenant/${payload.id}/notes`, { body: payload.body }).then((response) => response.data as Note)
}

export const postAccountManagerInviteApi = async (payload: { tenantAccountId: string; emailAddress: string }): Promise<AccountManager> => {
  return await axios.post(`${BASE_URL}/tenant-account/account-manager/create`, payload).then((response) => response.data as AccountManager)
}

export const disableAccountManagerApi = async (payload: { tenantAccountId: string; emailAddress: string }): Promise<unknown> => {
  return await axios.put(`${BASE_URL}/tenant-account/account-manager/disable`, payload).then((response) => response.data as unknown)
}

export const getFileListApi = async (query: GetFilesArgs): Promise<FilesResult> => {
  return await axios.get(`${BASE_URL}/file-explorer/files`, { params: query }).then((response) => response.data as FilesResult)
}

export const getFileExtensionsListApi = async (): Promise<string[]> => {
  return await axios.get(`${BASE_URL}/file-explorer/file-extensions`).then((res) => res.data as string[])
}

export const getLastModifiedApi = async (): Promise<LastModifiedObject> => {
  return await axios.get(`${BASE_URL}/file-explorer/files/modified-by`).then((res) => res.data as LastModifiedObject)
}

export const getOpportunitiesApi = async (): Promise<Opportunity> => {
  return await axios.get(`${BASE_URL}/file-explorer/opportunities`).then((res) => res.data as Opportunity)
}

export const getTagListApi = async (): Promise<Tag[]> => {
  return await axios.get(`${BASE_URL}/file-explorer/tags`).then((res) => res.data as Tag[])
}

// Commented out until duplicate directories in GDrive are figured out
// export const getDirectoriesApi = async (opportunityId: string | null): Promise<DirectoryObject> => {
//   return await axios.get(`${BASE_URL}/file-explorer/directories`, { params: opportunityId }).then((res) => res.data as DirectoryObject)
// }

export const getOpportunityFolderIdApi = async (opportunityId: string): Promise<string> => {
  return await axios
    .get(`${BASE_URL}/file-explorer/opportunityFolder`, {
      params: { opportunityId },
    })
    .then((res) => res.data as string)
}

export const deleteTagForFileApi = async (fileIds: string[]): Promise<unknown> => {
  return await axios.delete(`${BASE_URL}/file-explorer/tags`, { data: { fileIds: fileIds } }).then((res) => res.data as unknown)
}

export const addTagToFileApi = async (fileIds: string[], tag: Tag): Promise<unknown> => {
  return await axios.put(`${BASE_URL}/file-explorer/tags`, { fileIds, tag }).then((res) => res.data as unknown)
}

export const renameFileApi = async (fileId: string, newName: string): Promise<unknown> => {
  return await axios.patch(`${BASE_URL}/file-explorer/files/rename`, { fileId, newName }).then((res) => res.data as unknown)
}

export const downloadFileApi = async (fileIds: string[]): Promise<Blob> => {
  return await axios.post(`${BASE_URL}/file-explorer/files/download`, { fileIds }, { responseType: 'blob' }).then((res) => res.data as Blob)
}
export const getProgramsApi = async (query: GetProgramsArgs): Promise<GetProgramsListData> => {
  return await axios.get(`${BASE_URL}/programs`, { params: query }).then((response) => response.data as GetProgramsListData)
}

export const getProgramApi = async (programId: string): Promise<DetailedProgramItem> => {
  return await axios.get(`${BASE_URL}/programs/${programId}`).then((response) => response.data as DetailedProgramItem)
}

export const getAllocationReadinessApi = async (programId: string): Promise<AllocationReadinessResult> => {
  return await axios.get(`${BASE_URL}/programs/${programId}/allocations/readiness`).then((response) => response.data as AllocationReadinessResult)
}

export const commitAllocationSubmissionApi = async (programId: string, submission: AllocationSubmission): Promise<AllocationReadinessResult> => {
  return await axios.post(`${BASE_URL}/programs/${programId}/allocations/commit`, submission).then((response) => response.data as AllocationReadinessResult)
}

export const linkUtilityPortalApi = async (programId: string, portalCredentials: UtilityPortalCredentials): Promise<LinkUtilityPortalResult> => {
  return await axios.post(`${BASE_URL}/programs/${programId}/portal/link`, portalCredentials).then((response) => response.data as LinkUtilityPortalResult)
}

export const updateUtilityPortalCredentialsApi = async (programId: string, portalCredentials: UtilityPortalCredentials): Promise<LinkUtilityPortalResult> => {
  return await axios.put(`${BASE_URL}/programs/${programId}/portal/link`, portalCredentials).then((response) => response.data as LinkUtilityPortalResult)
}

export const getReadinessCheckListApi = async (programId: string): Promise<ReadinessChecklist> => {
  return await axios.get(`${BASE_URL}/programs/${programId}/checklist`).then((response) => response.data as ReadinessChecklist)
}

export const updateScraperIsActive = async (programId: string, isActive: boolean): Promise<UpdateScraperIsActiveResult> => {
  return await axios
    .put(`${BASE_URL}/programs/${programId}/portal/scraper/active`, {
      isActive,
    })
    .then((response) => response.data as UpdateScraperIsActiveResult)
}

export const getBillingOptionsApi = async (): Promise<BillingOptionsResponse> => {
  return await axios.get(`${BASE_URL}/tenant-account/billing-options`).then((res) => res.data as BillingOptionsResponse)
}

export const updateUtilityBillingConfigApi = async (
  body: UpdateUtilityBillingConfigBody,
  tenantAccountId: string
): Promise<SharedTenantAccountUpdateResponse> => {
  return await axios
    .put(`${BASE_URL}/tenant-account/${tenantAccountId}/billing-config/utility`, body)
    .then((res) => res.data as SharedTenantAccountUpdateResponse)
}

export const updateKingBillingConfigApi = async (body: UpdateKingBillingConfigBody, tenantAccountId: string): Promise<SharedTenantAccountUpdateResponse> => {
  return await axios.put(`${BASE_URL}/tenant-account/${tenantAccountId}/billing-config/king`, body).then((res) => res.data as SharedTenantAccountUpdateResponse)
}

export const createUtilityIncentivePlanApi = async (
  body: CreateUtilityIncentivePlanBody,
  tenantAccountId: string
): Promise<SharedTenantAccountUpdateResponse> => {
  return await axios
    .post(`${BASE_URL}/tenant-account/${tenantAccountId}/incentive-plan/utility`, body)
    .then((res) => res.data as SharedTenantAccountUpdateResponse)
}

export const createKingIncentivePlanApi = async (body: CreateKingIncentivePlanBody, tenantAccountId: string): Promise<SharedTenantAccountUpdateResponse> => {
  return await axios
    .post(`${BASE_URL}/tenant-account/${tenantAccountId}/incentive-plan/king`, body)
    .then((res) => res.data as SharedTenantAccountUpdateResponse)
}

export const getIncentivePlanOptionsApi = async (): Promise<IncentivePlanOptionsResponse> => {
  return await axios.get(`${BASE_URL}/tenant-account/incentive-plan-options`).then((res) => res.data as IncentivePlanOptionsResponse)
}

export const createAgreementFromUploadApi = async (invitationCuid: string, formData: FormData): Promise<unknown> => {
  return await axios.post(`${BASE_URL}/tesa/feed/agreement/create-from-upload/${invitationCuid}`, formData).then((res) => res.data as unknown)
}

export const updateAgreementFromUploadApi = async (agreementCuid: string, formData: FormData): Promise<unknown> => {
  return await axios.put(`${BASE_URL}/tesa/feed/agreement/${agreementCuid}/submission/update-from-upload`, formData).then((res) => res.data as unknown)
}

export const getTenantAccountBalancesFromBillingSdkApi = async (tenantAccountId: string): Promise<BillingSdkTenantAccountBalances> => {
  return await axios.get(`${BASE_URL}/tenant-account/${tenantAccountId}/billing-event-sdk-balances`).then((res) => res.data as BillingSdkTenantAccountBalances)
}
