import React from "react";
import classnames from "classnames";

export interface PropTypes {
  size?: 1 | 2 | 3 | 4 | 5;
  className?: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  color?: "indigo" | "emerald" | "red" | "zinc" | "orange";
  onClick?: any;
  tabIndex?: number;
  children?: React.ReactNode;
}

export const Button: React.FC<PropTypes> = (props) => {
  const {
    size = 3,
    className,
    disabled = false,
    type = "button",
    color = "indigo",
    children,
    ...rest
  } = props;

  return (
    <button
      type={type}
      disabled={disabled}
      className={classnames(
        `border-transparent font-medium rounded-md text-${color}-600 bg-${color}-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}-500`,
        {
          "px-2.5 py-1.5 text-xs": size === 1,
          "px-3 py-2 text-sm": size === 2,
          "px-4 py-2 text-sm": size === 3,
          "px-4 py-2 text-base": size === 4,
          "px-6 py-3 text-base": size === 5,
          "opacity-50 cursor-default": disabled,
          "hover:bg-indigo-200": !disabled && color === "indigo",
          "hover:bg-emerald-200": !disabled && color === "emerald",
          "hover:bg-red-200": !disabled && color === "red",
          "hover:bg-zinc-200": !disabled && color === "zinc",
          "hover:bg-orange-200": !disabled && color === "orange",
        },
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
