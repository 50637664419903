import { createSlice } from '@reduxjs/toolkit'
import { Paginated } from '../../../apis/types'
import { TenantRegistration } from '../../../types/registration'

const SLICE_NAME = 'TENANT_ONBOARDING_SLICE'

interface state {
  loading: boolean
  error: boolean
  data?: Paginated<TenantRegistration>
}

const initialState: state = {
  loading: false,
  error: false,
  data: undefined,
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
})

export const tenantOnboardingReducer = slice.reducer
