import type { GroupBase, StylesConfig } from 'react-select'

export const createCustomSelectStyles = <Option>(error: boolean = false): StylesConfig<Option, true, GroupBase<Option>> => ({
  control: (_, state) => ({
    label: 'control',
    width: '100%',
    padding: '0.25rem',
    position: 'relative',
    borderWidth: '1px',
    borderRadius: '0.375rem',
    borderColor:
      state.isDisabled || (!error && !state.isFocused)
        ? 'rgb(209 213 219)' /* gray:300 */
        : error
        ? 'rgb(252 165 165)' /* red:300 */
        : 'rgb(147 197 253)' /* blue:300 */,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: state.isDisabled ? 'rgb(243 244 246)' /* gray:100 */ : error ? undefined : undefined,
    boxShadow: state.isFocused ? 'var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color)' : '0 1px 2px 0 rgb(0 0 0 / 0.05)',
    cursor: 'default',
    transition: 'all',
    transitionDuration: '100ms',
    '--tw-ring-color': state.isFocused ? (error ? 'rgb(254 202 202 / 0.5)' /* red:200 */ : 'rgb(191 219 254 / 0.5)' /* blue:200 */) : undefined,
  }),
  input: (styles) => ({
    ...styles,
    input: {
      color: 'rgb(55 65 81)' /* gray:700 */,
      ':focus': {
        '--tw-ring-color': 'transparent',
      },
    },
  }),
  option: () => ({
    label: 'option',
    paddingTop: '0.50rem',
    paddingBottom: '0.50rem',
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',
    color: 'rgb(55 65 81)' /* gray:700 */,
    userSelect: 'none',
    cursor: 'default',
    '&:hover': {
      color: 'rgb(59 130 246)' /* blue:500 */,
      backgroundColor: 'rgb(219 234 254)' /* blue:100 */,
    },
  }),
  multiValue: (_, state) => ({
    label: 'multiValue',
    marginTop: '0.25rem',
    marginBottom: '0.25rem',
    borderRadius: '0.125rem',
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
    paddingLeft: '0.25rem',
    paddingRight: '0.25rem',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: error ? 'rgb(254 226 226)' /* red:100 */ : state.isDisabled ? 'rgb(229 231 235)' /* gray:200 */ : 'rgb(219 234 254)' /* blue:100 */,
  }),
  multiValueLabel: (_, state) => ({
    color: error ? 'rgb(239 68 68)' /* red:500 */ : state.isDisabled ? 'rgb(100 116 139)' /* slate:500 */ : 'rgb(59 130 246)' /* blue:500 */,
  }),
  multiValueRemove: (_, state) => ({
    label: 'multiValueRemove',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    paddingLeft: '0.25rem',
    paddingRight: '0.25rem',
    display: error ? 'flex' : state.isDisabled ? 'none' : 'flex',
    alignItems: 'center',
    color: error ? 'rgb(239 68 68)' /* red:500 */ : state.isDisabled ? undefined : 'rgb(59 130 246)' /* blue:500 */,
    '&:hover': {
      color: error ? 'rgb(185 28 28)' /* red:700 */ : state.isDisabled ? undefined : 'rgb(29 78 216)' /* blue:700 */,
    },
  }),
})
