import React, { useCallback, useState } from "react";
import Button from "../../../../components/Form/Button";
import { useFormik, FormikProvider, Field } from "formik";
import Input from "../../../../components/Form/Input";
import Select from "../../../../components/Form/Select";
import * as Yup from "yup";
import {
  createUtilityIncentivePlanApi,
  createKingIncentivePlanApi,
} from "../../../../apis/apis";
import { pushToast } from "../../../../components/Toaster/Toaster.slice";
import {
  InstallmentPreview,
  IncentivePlanOptionObject,
  IncentivePlanOptionsResult,
} from "../../../../apis/types";
import { addMonths, endOfMonth, getDate } from "date-fns";
import Table from "../../../../components/Table/Table";
import { formatUTCDetailedDate } from "../../../../util/string";
import VNEMWarning from '../../../programs/components/VNEMWarning'
import { useAppDispatch } from "../../../../shared/redux/hooks";
interface CreateIncentivePlanFormProps {
  closeFunction: () => void;
  tenantAccountId: string;
  incentivePlanOptions: IncentivePlanOptionsResult | undefined;
  latestKingInvoiceDueDate: string | undefined;
  kingOnlyBalance: number | undefined
  estimatedUtilityBalance: number | undefined
  hasCreateUtilityIncentivePlanPermission: boolean
}

const CreateIncentivePlanForm: React.FC<CreateIncentivePlanFormProps> = ({
  closeFunction,
  tenantAccountId,
  incentivePlanOptions,
  latestKingInvoiceDueDate,
  kingOnlyBalance,
  estimatedUtilityBalance,
  hasCreateUtilityIncentivePlanPermission,

}) => {
  const dispatch = useAppDispatch()

  const noValidKingOnlyBalance = kingOnlyBalance == null || kingOnlyBalance <= 0

  const [planTypeOptions, setPlanTypeOptions] = useState(() => {
    const options = incentivePlanOptions?.incentivePlanTypes ?? [{ PAYMENT_PLAN: 'Payment Plan' }]
    // If the kingOnlyBalance is 0 or null, remove the Payment Plan option
    const filteredOptions = noValidKingOnlyBalance ? options.filter((type: IncentivePlanOptionObject) => Object.keys(type)[0] !== 'PAYMENT_PLAN') : options

    // Transform array of objects into a single object for use in the Select component
    return filteredOptions.reduce((accumulator, currentObject) => {
      return { ...accumulator, ...currentObject }
    }, {})
  })

  const [showInstalmentCountField, setShowInstalmentCountField] = useState<boolean>(false)
  const requiredMessage = 'Required'
  const installmentCountErrorMessage = 'Installment count must be at least 1 and less than or equal to 12'

  const formik = useFormik({
    initialValues: {
      sponsor: 'King',
      planType: 'ACCOMMODATION_CREDIT',
      startingBalance: undefined,
      cadence: 'ONE_TIME',
      installmentCount: 1,
      memo: undefined,
    },
    validationSchema: Yup.object({
      sponsor: Yup.string().required(requiredMessage),
      planType: Yup.string().required(requiredMessage),
      startingBalance: Yup.number()
        .min(0, 'Starting balance must be at least 0')
        // If the sponsor is King and the kingOnlyBalance is not null, set the max value to the kingOnlyBalance
        .when(['sponsor', 'planType'], ([sponsor, planType], schema) => {
          if (sponsor === 'King' && kingOnlyBalance != null && planType !== 'ACCOMMODATION_CREDIT') {
            return schema.max(
              kingOnlyBalance,
              `Starting balance cannot exceed this tenant account's current king only balance of ${kingOnlyBalance.toFixed(2)}`
            )
          } else if (sponsor === 'Utility' && estimatedUtilityBalance != null) {
            return schema.max(
              estimatedUtilityBalance,
              `Starting balance cannot exceed this tenant account's current estimated utility balance of ${estimatedUtilityBalance.toFixed(2)}`
            )
          }
          return schema
        })
        .required(requiredMessage),

      cadence: Yup.string().required(requiredMessage),
      installmentCount: Yup.number().min(1, installmentCountErrorMessage).max(12, installmentCountErrorMessage).required(requiredMessage),
      memo: Yup.string().required(requiredMessage),
    }),
    onSubmit: (values) => {
      if (!formik.isValidating && formik.isValid) {
        formik.setSubmitting(true)
        if (values.sponsor === 'King') {
          createKingIncentivePlanApi(
            {
              ...values,
              installmentCount: values.cadence === 'ONE_TIME' ? 1 : values.installmentCount ?? 1,
              startingBalance: Number(values.startingBalance).toFixed(2) ?? '0',
            },
            tenantAccountId
          )
            .then(async () => {
              await dispatch(
                pushToast({
                  message: 'New king incentive plan created',
                  type: 'success',
                })
              )
            })
            .catch(async (e) => {
              await dispatch(
                pushToast({
                  message: 'Failed to create new king incentive plan',
                  type: 'error',
                  description: `${e.response?.data?.error !== undefined ? `${e.response?.data?.error as string}:` : 'Error:'} ${e.message as string}`,
                })
              )
            })
        } else if (values.sponsor === 'Utility') {
          createUtilityIncentivePlanApi(
            {
              ...values,
              installmentCount: values.cadence === 'ONE_TIME' ? 1 : values.installmentCount ?? 1,
              startingBalance: Number(values.startingBalance).toFixed(2) ?? '0',
            },
            tenantAccountId
          )
            .then(async () => {
              await dispatch(
                pushToast({
                  message: 'New utility incentive plan created',
                  type: 'success',
                })
              )
            })
            .catch(async (e) => {
              await dispatch(
                pushToast({
                  message: 'Failed to create new utility incentive plan',
                  type: 'error',
                  description: `${e.response?.data?.error !== undefined ? `${e.response?.data?.error as string}:` : 'Error:'} ${e.message as string}`,
                })
              )
            })
        }
        closeFunction()
      }
    },
  })

  /**
   * Helper function to add a month to a date
   * @param initialDate
   * @returns Date one month after the provided initialDate
   */
  const addMonth = (initialDate: Date): Date => {
    const newDate = addMonths(initialDate, 1)

    // Check if the day has rolled over to the next month
    if (getDate(newDate) !== getDate(initialDate)) {
      // Set to the last day of the previous month
      return endOfMonth(addMonths(initialDate, -1))
    }

    return newDate
  }

  const renderProposedInstallmentScheduleSummary = useCallback(
    (
      startingBalance: number | undefined,
      installmentCount: number | undefined,
      incentivePlanStartDate: Date,
      type: string,
      startingBalanceValid: boolean,
      installmentCountValid: boolean
    ): JSX.Element => {
      if (!startingBalanceValid || !installmentCountValid || startingBalance == null || installmentCount == null) {
        return <div className="text-slate-500 mb-3">Please enter a valid Starting Balance and Installment Count to preview the installment schedule</div>
      }

      let dueDate = incentivePlanStartDate
      let totalBalance = startingBalance
      const installments: InstallmentPreview[] = []

      // Calculate the standard installment amount (to be used in all installments except the last)
      const standardInstallmentAmount = parseFloat((totalBalance / installmentCount).toFixed(2))

      for (let installmentNumber = 1; installmentNumber <= installmentCount; installmentNumber++) {
        let currentInstallmentAmount

        // Use the total balance for the last installment
        if (installmentNumber === installmentCount) {
          currentInstallmentAmount = totalBalance
        }
        // Use the standard installment amount for other installments
        else {
          currentInstallmentAmount = standardInstallmentAmount
        }

        installments.push({
          id: installmentNumber.toString(),
          dueDate: dueDate.toISOString(),
          number: installmentNumber,
          amount: currentInstallmentAmount,
        })

        totalBalance -= currentInstallmentAmount
        totalBalance = parseFloat(totalBalance.toFixed(2)) // Correct for any floating-point arithmetic issues.

        // New dueDate is one month after the previous dueDate
        dueDate = new Date(addMonth(new Date(dueDate)))
      }

      return (
        <div className="mb-3 bg-slate-100 p-3 rounded-lg">
          <div className="pt-3">
            {/* Display a warning if the first installment date is in the past */}
            {new Date(installments[0].dueDate) < new Date() && <VNEMWarning type="warning" text="First installment date is in the past" />}
          </div>
          <div className="bg-white">
            <Table<InstallmentPreview>
              data={{ results: installments }}
              colConfig={[
                {
                  label: 'Installment',
                  render: (rec) => rec.number,
                },
                {
                  label: type === 'ACCOMMODATION_CREDIT' ? 'Amount Credited' : 'Amount Due',
                  render: (rec) => (rec.amount != null && typeof rec.amount === 'number' ? `$${rec.amount.toFixed(2)}` : 'N/A'),
                },
                {
                  label: type === 'ACCOMMODATION_CREDIT' ? 'Credit Date' : 'Due Date',
                  render: (rec) => formatUTCDetailedDate(new Date(rec.dueDate)),
                },
              ]}
              hidePaginator
            />
          </div>
        </div>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values.startingBalance, formik.values.installmentCount]
  )

  return (
    <div className="bg-white px-5 py-4 w-full max-w-screen-lg h-full mx-auto shadow-xl rounded-2xl flex flex-col">
      <div className="justify-start items-start">
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col justify-start items-start">
              <h2 className="mb-5 text-2xl font-medium">Create Incentive Plan</h2>
              <div className="grid grid-cols-2 gap-4 w-full">
                <div>
                  <div className="w-3/4">
                    <Field
                      label="Sponsor"
                      id="sponsor"
                      name="sponsor"
                      component={Select}
                      options={
                        hasCreateUtilityIncentivePlanPermission && estimatedUtilityBalance != null && estimatedUtilityBalance > 0
                          ? { King: 'King', Utility: 'Utility' }
                          : { King: 'King' }
                      }
                      required
                      error={formik.errors.sponsor}
                      className="w-full mb-4"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value === 'King') {
                          setPlanTypeOptions(() => {
                            const options = incentivePlanOptions?.incentivePlanTypes ?? [
                              { PAYMENT_PLAN: 'Payment Plan' },
                              {
                                ACCOMMODATION_CREDIT: 'Accommodation Credit',
                              },
                            ]
                            // If the kingOnlyBalance is 0 or null, remove the Payment Plan option
                            const filteredOptions = noValidKingOnlyBalance
                              ? options.filter((type: IncentivePlanOptionObject) => Object.keys(type)[0] !== 'PAYMENT_PLAN')
                              : options

                            // Transform array of objects into a single object for use in the Select component
                            return filteredOptions.reduce((accumulator, currentObject) => {
                              return { ...accumulator, ...currentObject }
                            }, {})
                          })
                        } else {
                          setPlanTypeOptions(() => {
                            const filteredOptions = incentivePlanOptions?.incentivePlanTypes.filter(
                              (type: IncentivePlanOptionObject) => Object.keys(type)[0] !== 'ACCOMMODATION_CREDIT'
                            ) ?? [{ PAYMENT_PLAN: 'Payment Plan' }]

                            // Transform array of objects into a single object for use in the Select component
                            return filteredOptions.reduce((accumulator, currentObject) => {
                              return { ...accumulator, ...currentObject }
                            }, {})
                          })
                        }
                        formik.setFieldValue('sponsor', e.target.value).catch(() => {})
                      }}
                    />
                    <Field
                      label="Plan Type"
                      id="planType"
                      name="planType"
                      component={Select}
                      options={planTypeOptions}
                      required
                      error={formik.errors.planType}
                      className="w-full mb-4"
                    />
                    <Field
                      label="Starting Balance"
                      id="startingBalance"
                      name="startingBalance"
                      type="number"
                      as={Input}
                      required
                      error={formik.errors.startingBalance}
                      className="w-full mb-4"
                    />
                    <Field
                      label="Cadence"
                      id="cadence"
                      name="cadence"
                      component={Select}
                      options={
                        // Transform array of objects into a single object for use in the Select component
                        incentivePlanOptions?.incentivePlanCadences.reduce((accumulator, currentObject) => {
                          return { ...accumulator, ...currentObject }
                        }, {}) ?? { MONTHLY: 'Monthly', ONE_TIME: 'One Time' }
                      }
                      required
                      error={formik.errors.cadence}
                      className="w-full mb-4"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value === 'ONE_TIME') {
                          setShowInstalmentCountField(false)
                          formik.setFieldValue('installmentCount', 1).catch(() => {})
                        } else {
                          setShowInstalmentCountField(true)
                        }
                        formik.setFieldValue('cadence', e.target.value).catch(() => {})
                      }}
                    />
                    {showInstalmentCountField && (
                      <Field
                        label="Installment Count"
                        id="installmentCount"
                        name="installmentCount"
                        type="number"
                        as={Input}
                        required
                        error={formik.errors.installmentCount}
                        className="w-full mb-4"
                      />
                    )}
                  </div>
                  <div className="w-full">
                    <Field label="Description" id="memo" name="memo" type="text" as={Input} required error={formik.errors.memo} className="w-full mb-4" />
                  </div>
                </div>
                <div>
                  {noValidKingOnlyBalance && (
                    <div>
                      <VNEMWarning
                        type="warning"
                        text="Cannot create a King payment plan for this account since their King only balance is non existent or less than or equal to 0"
                      />
                    </div>
                  )}
                  {hasCreateUtilityIncentivePlanPermission && (estimatedUtilityBalance == null || estimatedUtilityBalance <= 0) && (
                    <div>
                      <VNEMWarning
                        type="warning"
                        text="Cannot create a Utility payment plan for this account since their estimated utility balance is non existent or less than or equal to 0"
                      />
                    </div>
                  )}
                  {!hasCreateUtilityIncentivePlanPermission && (
                    <div className="mt-4">
                      <VNEMWarning type="warning" text="You do not have permission to create a utility incentive plan" />
                    </div>
                  )}
                  <div className="mb-2">Proposed Installment Schedule</div>
                  {renderProposedInstallmentScheduleSummary(
                    formik.values.startingBalance,
                    formik.values.installmentCount,
                    addMonth(new Date(latestKingInvoiceDueDate ?? new Date())),
                    formik.values.planType,
                    formik.errors.startingBalance === undefined,
                    formik.errors.installmentCount === undefined
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <Button
                onClick={() => {
                  closeFunction()
                }}
                type="button"
                color="zinc"
                className="w-28"
              >
                Close
              </Button>
              <div className="ml-2">
                <Button type="submit" disabled={formik.isSubmitting} tabIndex={-1} color="orange" className="w-28">
                  {formik.isSubmitting && (
                    <svg
                      className="animate-spin inline -mt-1 -ml-1 mr-3 h-5 w-5 text-orange-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  Create
                </Button>
              </div>
            </div>
          </form>
        </FormikProvider>
      </div>
    </div>
  )
};

export default CreateIncentivePlanForm;
