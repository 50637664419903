import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getFileExtensionsListApi,
  getFileListApi,
  getLastModifiedApi,
  getOpportunitiesApi,
  getTagListApi,
} from "../../apis/apis";
import {
  DirectoryObject,
  FilesResult,
  GetFilesArgs,
  Opportunity,
  Tag,
} from "../../apis/types";
import { AppDispatch, RootState } from "../../shared/redux/store";

const SLICE_NAME = "FILE_EXPLORER_SLICE";

export const getFileList = createAsyncThunk<
  any,
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  GetFilesArgs,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: { err: boolean };
  }
>(`${SLICE_NAME}/getFileList`, async (query, { rejectWithValue }) => {
  try {
    const response = await getFileListApi(query);
    return response;
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    });
  }
});

export const getFileExtensionList = createAsyncThunk<
  string[],
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: { err: boolean };
  }
>(`${SLICE_NAME}/getFileExtensionList`, async (_, { rejectWithValue }) => {
  try {
    const fileExtensions = (await getFileExtensionsListApi()).sort();
    return fileExtensions;
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    });
  }
});

export const getLastModifiedList = createAsyncThunk<
  Array<{
    id: string;
    name: string;
  }>,
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: { err: boolean };
  }
>(`${SLICE_NAME}/getLastModifiedList`, async (_, { rejectWithValue }) => {
  try {
    const lastModifiedObject = await getLastModifiedApi();

    const lastModifiedSortedList = Object.entries(lastModifiedObject)
      .map(([id, name]) => ({ id, name }))
      .sort((id, name) => id.name.localeCompare(name.name));

    return lastModifiedSortedList;
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    });
  }
});

export const getOpportunitiesList = createAsyncThunk<
  Opportunity[],
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: { err: boolean };
  }
>(`${SLICE_NAME}/getOpportunitiesList`, async (_, { rejectWithValue }) => {
  try {
    const opportunitiesObject = await getOpportunitiesApi();

    const opportunitiesObjectSorted = Object.entries(opportunitiesObject)
      .map(([id, name]) => ({ id, name }))
      .sort((id, name) => id.name.localeCompare(name.name));

    return opportunitiesObjectSorted;
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    });
  }
});

export const getTagList = createAsyncThunk<
  Tag[],
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: { err: boolean };
  }
>(`${SLICE_NAME}/getTagList`, async (_, { rejectWithValue }) => {
  try {
    const opportunitiesList = (await getTagListApi()).sort((index, keyword) =>
      index.keyword.localeCompare(keyword.keyword)
    );

    return opportunitiesList;
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    });
  }
});
// Commented out until duplicate directories in GDrive are figured out
// export const getDirectoriesList = createAsyncThunk<
//   DirectoryObject[],
//   // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
//   any,
//   {
//     state: RootState
//     dispatch: AppDispatch
//     rejectValue: { err: boolean }
//   }
// >(`${SLICE_NAME}/getDirectoriesList`, async (query, { rejectWithValue }) => {
//   try {
//     const directoriesObject = await getDirectoriesApi(query)

//     const directoriesObjectSorted = Object.entries(directoriesObject)
//       .map(([id, name]) => ({ id, name }))
//       .sort((id, name) => id.name.localeCompare(name.name))

//     return directoriesObjectSorted
//   } catch (err) {
//     return rejectWithValue({
//       err: err !== undefined,
//     })
//   }
// })

interface state {
  loading: boolean;
  error: boolean;
  files?: FilesResult;
  fileExtensions?: string[];
  lastModifiedList?: Array<{
    id: string;
    name: string;
  }>;
  opportunitiesList?: Opportunity[];
  tagsList?: Tag[];
  directoriesList?: DirectoryObject[];
}

const initialState: state = {
  loading: false,
  error: false,
  files: undefined,
  fileExtensions: [],
  lastModifiedList: [],
  opportunitiesList: [],
  tagsList: [],
  directoriesList: [],
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFileList.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getFileList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.files = payload;
    });
    builder.addCase(getFileList.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error !== undefined;
    });
    builder.addCase(getFileExtensionList.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getFileExtensionList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.fileExtensions = payload;
    });
    builder.addCase(
      getFileExtensionList.rejected,
      (state: state, { payload, error }) => {
        state.loading = false;

        if (payload != null) {
          state.error = payload.err;
        } else {
          state.error = error !== undefined;
        }
      }
    );
    builder.addCase(getLastModifiedList.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getLastModifiedList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.lastModifiedList = payload;
    });
    builder.addCase(
      getLastModifiedList.rejected,
      (state: state, { payload, error }) => {
        state.loading = false;

        if (payload != null) {
          state.error = payload.err;
        } else {
          state.error = error !== undefined;
        }
      }
    );
    builder.addCase(getOpportunitiesList.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getOpportunitiesList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.opportunitiesList = payload;
    });
    builder.addCase(
      getOpportunitiesList.rejected,
      (state: state, { payload, error }) => {
        state.loading = false;

        if (payload != null) {
          state.error = payload.err;
        } else {
          state.error = error !== undefined;
        }
      }
    );
    builder.addCase(getTagList.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getTagList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.tagsList = payload;
    });
    builder.addCase(getTagList.rejected, (state: state, { payload, error }) => {
      state.loading = false;

      if (payload != null) {
        state.error = payload.err;
      } else {
        state.error = error !== undefined;
      }
    });
    // Commented out until duplicate directories in GDrive are figured out

    // builder.addCase(getDirectoriesList.pending, (state) => {
    //   state.loading = true
    //   state.error = false
    // })
    // builder.addCase(getDirectoriesList.fulfilled, (state, { payload }) => {
    //   state.loading = false
    //   state.error = false
    //   state.directoriesList = payload
    // })
    // builder.addCase(getDirectoriesList.rejected, (state: state, { payload, error }) => {
    //   state.loading = false

    //   if (payload != null) {
    //     state.error = payload.err
    //   } else {
    //     state.error = error !== undefined
    //   }
    // })
  },
});

export const fileExplorerReducer = slice.reducer;
