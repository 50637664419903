import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Nav from "./components/Nav/Nav";
import Toaster from "./components/Toaster/Toaster";

const CoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  width: 100vw;
  > .mainCtr {
    flex: 0.9;
    background-color: white;
    overflow: scroll;
    height: 100vh;
  }
`;

interface PropInterface {
  children: React.ReactNode;
}

const CoreLayout: React.FC<PropInterface> = (props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <CoreContainer>
      <Nav />
      <div className="mainCtr">{props.children}</div>
      <Toaster />
    </CoreContainer>
  );
};

export default CoreLayout;
