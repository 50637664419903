import React, { ChangeEvent } from "react";
import classnames from "classnames";

export interface ControlledQuickSearchProps {
  title: string;
  value: string | undefined;
  placeholder?: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ControlledQuickSearch: React.FC<ControlledQuickSearchProps> = (props) => {
  const { title, value = "", placeholder, handleChange } = props;
  return (
    <div className="w-full">
      <label htmlFor="search" className="block text-sm font-medium text-zinc-700">
        {title}
      </label>
      <div className="relative flex items-center">
        <input
          type="text"
          name="search"
          id="search"
          value={value}
          onChange={(e) => handleChange(e)}
          placeholder={placeholder}
          className={classnames(
            'mt-1 px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6',
            {
              'bg-orange-50 border-orange-300 focus:ring-orange-500 focus:border-orange-500': value,
              'focus:ring-indigo-500 focus:border-indigo-500': value !== undefined,
            }
          )}
        />
      </div>
    </div>
  )
};

export default ControlledQuickSearch;
