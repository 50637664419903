import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getTESASupplementDocumentApi } from "../../../apis/registration";

export const TESASupplement: React.FC = () => {
  const [objectURL, setObjectURL] = useState<string | undefined>();
  const { agreementId } = useParams();

  useEffect(() => {
    const init = async (): Promise<void> => {
      const objectURL = await getTESASupplementDocumentApi(
        agreementId as string
      );
      setObjectURL(objectURL);
    };

    void init();
  }, [agreementId, setObjectURL]);

  return <iframe className="h-screen" height="100%" width="100%" src={objectURL} />
};

export default TESASupplement;
