import React from "react";

export interface LayoutProps {
  children: React.ReactNode;
  title: React.ReactElement | string;
}

export const Layout: React.FC<LayoutProps> = (props) => {
  return (
    <main className="flex-1 relative focus:outline-none text-sm">
      <div className="py-6">
        <div className="mx-auto px-4 sm:px-6 md:px-8">
          {typeof props.title === "string" ? (
            <div className="mb-5 text-2xl font-medium">{props.title}</div>
          ) : (
            props.title
          )}
        </div>
        <div className="mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-4">{props.children}</div>
        </div>
      </div>
    </main>
  );
};

export default Layout;
