export const nth = (d: number): string => {
  if (d > 3 && d < 21) return `${d}th`
  switch (d % 10) {
    case 1:
      return `${d}st`
    case 2:
      return `${d}nd`
    case 3:
      return `${d}rd`
    default:
      return `${d}th`
  }
}

export const fullMonth = (m: number): string => {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  return months[m]
}

export const formatDate = (ISOString: Date = new Date()): string =>
  new Date(ISOString).toLocaleDateString('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
  })


export const formatDateTime = (ISOString: Date = new Date()): string =>
  new Date(ISOString).toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })

// Formats a UTC date string into a readable date string of the format: "MM/DD/YY" eg "01/01/21"
export const formatUTCDate = (ISOString: Date = new Date()): string => {
  const date = new Date(ISOString)
  return `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${String(date.getUTCFullYear()).substr(2)}`
}

export const formatUTCDateTime = (ISOString: Date = new Date()): string => {
  const date = new Date(ISOString)

  // padStart will ensure that single digit months, dates, hours, minutes and seconds are prepended with a zero
  const formattedDate = `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(date.getUTCDate()).padStart(2, '0')}T${String(
    date.getUTCHours()
  ).padStart(2, '0')}:${String(date.getUTCMinutes()).padStart(2, '0')}:${String(date.getUTCSeconds()).padStart(2, '0')}.${String(
    date.getUTCMilliseconds()
  ).padStart(3, '0')}Z`

  return formattedDate
}

// Formats a UTC date string into a readable date string of the format: "Month Day, Year"
// eg. "Jan 1, 2021"
export const formatUTCDetailedDate = (ISOString = new Date()): string => {
  const shortMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const date = new Date(ISOString);

  // Extract UTC date components
  const year = date.getUTCFullYear();
  const month = shortMonthNames[date.getUTCMonth()];
  const day = date.getUTCDate();

  return `${month} ${day}, ${year}`;
}

export const formatTimeInMilliseconds = (ISOString: string): number => {
  const date = new Date(ISOString)
  return date.getTime()
}

export const formatCurrency = (value: number | null): string => {
  if (value === null || isNaN(Number(value))) {
    return ''
  }
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
}

export const formatPhoneNumber = (value: string = ''): string => {
  const numericValue = value?.replace('+1', '')?.replace(/\D/g, '')
  const match = numericValue.match(/^(\d{3})(\d{3})(\d{4})/)
  return match !== null ? `(${match[1]}) ${match[2]}-${match[3]}` : value
}

export const formatEIN = (value: string = ''): string => {
  const numericValue = value?.replace(/\D/g, '')
  const match = numericValue.match(/^(\d{2})(\d{7})/)
  return match !== null ? `${match[1]}-${match[2]}` : value
}

export const formatPostalCode = (value: string = ''): string => {
  const numericValue = value.replace(/\D/g, '')
  const match2 = numericValue.match(/^(\d{5})(\d{4})/)
  const match1 = numericValue.match(/^(\d{5})/)
  return match2 != null ? `${match2[1]}-${match2[2]}` : match1 != null ? match1[1] : value
}

export const makeEnumReadable = (value: string | null): string => {
  if (value === null || typeof value !== 'string') {
    return ''
  }
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase().replaceAll('_', ' ')
}

export const formatFileSize = (sizeInBytes: number): string => {
  const sizeInKB = Math.floor(sizeInBytes / 1024)
  const sizeInMB = Math.floor(sizeInKB / 1024)
  const sizeInGB = Math.floor(sizeInMB / 1024)
  const sizeInTB = Math.floor(sizeInGB / 1024)

  if (sizeInTB >= 1) {
    return `${sizeInTB.toLocaleString('en-US')} TB`
  } else if (sizeInGB >= 1) {
    return `${sizeInGB.toLocaleString('en-US')} GB`
  } else if (sizeInMB >= 1) {
    return `${sizeInMB.toLocaleString('en-US')} MB`
  } else if (sizeInKB >= 1) {
    return `${sizeInKB.toLocaleString('en-US')} KB`
  } else {
    return `${sizeInBytes.toLocaleString('en-US')} B`
  }
}

export const formatTitleCase = (str: string): string => {
  return str
    .toLowerCase()
    .split(/\s|_|-/) // Split on spaces, underscores, or dashes
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}