import React, { cloneElement } from "react";
import classnames from "classnames";
import { Invites } from "../../../../types/registration";

interface OnboardingStepsProps {
  className?: string;
  children: Array<
    React.ReactElement<any, string | React.JSXElementConstructor<any>>
  >;
}

const OnboardingSteps: React.FC<OnboardingStepsProps> = (props) => {
  const index = props.children
    .slice()
    .reverse()
    .findIndex((c: any) => c.props.isActive);
  const count = props.children.length - 1;
  const finalIndex = index >= 0 ? count - index : index;

  const children = props.children.map((OnboardingStep, i) => {
    return cloneElement(OnboardingStep, {
      key: i,
      number: i + 1,
      isComplete: i < finalIndex,
      isActive: i === finalIndex,
      isIncomplete: i > finalIndex,
    });
  });

  return (
    <div
      className={classnames(
        "flex border-indigo-200 border-r-1",
        props.className
      )}
    >
      {children}
    </div>
  );
};

interface OnboardingStepProps {
  isComplete?: boolean;
  isActive?: boolean;
  isIncomplete?: boolean;
}

const OnboardingStep: React.FC<OnboardingStepProps> = (props) => {
  const { isComplete, isActive, isIncomplete } = props;

  return (
    <div
      className={classnames(
        "w-8 h-2.5 border border-indigo-400 border-r-0 last:border-r first:rounded-l last:rounded-r",
        {
          "bg-indigo-200": isComplete,
          "bg-indigo-400": isActive,
          "bg-white": isIncomplete,
        }
      )}
    />
  );
};

interface OnboardingStepDescProps {
  isActive: boolean;
  children?: React.ReactNode;
}

const OnboardingStepDesc: React.FC<OnboardingStepDescProps> = (props) => {
  const { children, isActive } = props;

  if (!isActive) {
    return null;
  }

  return <div className={classnames("text-sm font-base")}>{children}</div>;
};

export interface OnboardingMiniTimelineProps {
  invites?: Invites;
}

export const OnboardingMiniTimeline: React.FC<OnboardingMiniTimelineProps> = (
  props
) => {
  const {
    isOnregInviteCreated = false,
    isOnregInvitePending = false,
    isOnregAgreedTesa = false,
    isOnregAgreedTesaSupplement = false,
    isOnregAgreedPricingAndPayments = false,
    isOnregInviteConsumed = false,
    isAccountInviteCreated = false,
    isAccountInviteConsumed = false,
  } = props.invites ?? {};

  return (
    <div>
      <OnboardingSteps>
        <OnboardingStep isActive={isOnregInviteCreated} />
        <OnboardingStep isActive={isOnregInvitePending && isOnregAgreedTesa} />
        <OnboardingStep
          isActive={isOnregInvitePending && isOnregAgreedTesaSupplement}
        />
        <OnboardingStep
          isActive={isOnregInvitePending && isOnregAgreedPricingAndPayments}
        />
        <OnboardingStep isActive={isOnregInviteConsumed} />
        <OnboardingStep isActive={isAccountInviteCreated} />
        <OnboardingStep isActive={isAccountInviteConsumed} />
      </OnboardingSteps>
      <OnboardingSteps className="mt-0.5 font-medium">
        <OnboardingStepDesc isActive={isOnregInviteCreated}>
          Registration invitation generated
        </OnboardingStepDesc>
        <OnboardingStepDesc
          isActive={isOnregInvitePending && isOnregAgreedTesa}
        >
          Registration in progress
        </OnboardingStepDesc>
        <OnboardingStepDesc
          isActive={isOnregInvitePending && isOnregAgreedTesaSupplement}
        >
          Registration in progress
        </OnboardingStepDesc>
        <OnboardingStepDesc
          isActive={isOnregInvitePending && isOnregAgreedPricingAndPayments}
        >
          Registration in progress
        </OnboardingStepDesc>
        <OnboardingStepDesc isActive={isOnregInviteConsumed}>
          Registration complete
        </OnboardingStepDesc>
        <OnboardingStepDesc isActive={isAccountInviteCreated}>
          Account invitation generated
        </OnboardingStepDesc>
        <OnboardingStepDesc isActive={isAccountInviteConsumed}>
          Account created
        </OnboardingStepDesc>
      </OnboardingSteps>
    </div>
  );
};

export default OnboardingMiniTimeline;
