import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Input from "../../../../components/Form/Input";
import Button from "../../../../components/Form/Button";
import Modal from "../../../../components/Modal";
import { getTenant } from "../Tenants.slice";
import { pushToast } from "../../../../components/Toaster/Toaster.slice";
import { postAccountManagerInviteApi } from "../../../../apis/apis";
import { useAppDispatch, useAppSelector } from "../../../../shared/redux/hooks";

interface AddAccountManagerFormProps {
  tenantAccountCuid: string;
}

const AddAccountManagerForm: React.FC<AddAccountManagerFormProps> = ({
  tenantAccountCuid,
}) => {
  const tenantID = useAppSelector((state) => state.tenants.tenant?.id);
  const { permissions } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();

  const [confirmModal, setConfirmModal] = useState<
    React.ReactElement | boolean
  >(false);

  const hasAccountManagerCreatePermission = (): boolean =>
    permissions != null
      ? permissions.includes("account:manager:create")
      : false;

  return (
    <Formik
      initialValues={{ accountManagerEmail: "" }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        accountManagerEmail: Yup.string()
          .email("Please enter a valid email")
          .required("Required field"),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          await new Promise((resolve, reject) => {
            setConfirmModal(<Modal resolve={resolve} reject={reject} />);
          });
          setConfirmModal(false);
          try {
            const response = await postAccountManagerInviteApi({
              tenantAccountId: tenantAccountCuid,
              emailAddress: values.accountManagerEmail,
            });

            try {
              const accountSignupLink = response.accountSignupLink;
              if (accountSignupLink == null) {
                throw new Error(
                  "Account account invitation link does not exist"
                );
              }
              await navigator.clipboard.writeText(accountSignupLink);
              dispatch(
                pushToast({
                  message: "Account invitation link copied",
                  type: "success",
                })
              );
            } catch (e: any) {
              console.error(
                `Failed to copy account invitation link. Error: ${
                  e.message as string
                }`
              );
            }
            resetForm({});
            dispatch(pushToast({ message: `New account manager created` }));
            if (tenantID !== undefined) {
              dispatch(getTenant(tenantID));
            }
          } catch (e: any) {
            dispatch(
              pushToast({
                type: "error",
                message: "Error creating account manager",
                description: `${
                  e.response?.data?.message !== undefined
                    ? `${e.response?.data?.message as string}:`
                    : "Error:"
                } ${e.message as string}`,
              })
            );
          }
          setSubmitting(false);
        } catch (e) {
          setConfirmModal(false);
        }
      }}
    >
      {({ errors, touched, isValid, isSubmitting }) => (
        <>
          <Form>
            <Field
              as={Input}
              name="accountManagerEmail"
              label="Account Manager Email"
              placeholder="Add a valid email address..."
              error={
                errors.accountManagerEmail !== undefined &&
                (touched.accountManagerEmail ?? false)
                  ? errors.accountManagerEmail
                  : undefined
              }
              disabled={isSubmitting || !hasAccountManagerCreatePermission()}
            />
            <div className="mt-6">
              <Button
                type="submit"
                className="w-full"
                disabled={!isValid || !hasAccountManagerCreatePermission()}
              >
                Create new account manager and copy account invitation link
              </Button>
            </div>
          </Form>
          {confirmModal}
        </>
      )}
    </Formik>
  );
};

export default AddAccountManagerForm;
