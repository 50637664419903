import React from 'react'

const iconKing: React.FC<React.ComponentProps<'svg'>> = (props) => {
  return (
    <svg {...props} width="70px" height="64px" viewBox="0 0 70 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>icon-king</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Nav" transform="translate(-33.000000, -55.000000)" fill="currentColor">
          <g id="Header" transform="translate(33.000000, 55.000000)">
            <path
              d="M11.7388474,0 L18.1030379,7.97794118 L22.9691401,7.97794118 L29.3330403,0 L40.6666694,0 L47.0308599,7.97794118 L51.8969621,7.97794118 L58.2611526,0 L70,0 L70,63.8235294 L0,63.8235294 L0,0 L11.7388474,0 Z M65.0877448,23.3202473 L4.91225524,23.3202473 L4.91225524,58.9140495 L65.0877448,58.9140495 L65.0877448,23.3202473 Z M30.7016678,28.8433035 L30.7016678,33.1392072 L23.3334301,33.1392072 L23.3334301,41.9503356 L42.2393574,33.1392072 L35,33.1392072 L35,28.8433035 L59.5612762,28.8433035 L59.5612762,33.1392072 L50.9576453,33.1392072 L39.6904882,38.3898529 L52.0130705,49.0950896 L59.5612762,49.0950896 L59.5612762,53.3907032 L35,53.3907032 L35,49.0950896 L43.5896979,49.0950896 L34.2084311,40.9445348 L23.3334301,46.0124131 L23.3334301,49.0950896 L30.7016678,49.0950896 L30.7016678,53.3907032 L10.4387238,53.3907032 L10.4387238,49.0950896 L17.8069615,49.0950896 L17.8069615,33.1392072 L10.4387238,33.1392072 L10.4387238,28.8433035 L30.7016678,28.8433035 Z M9.37284888,4.90947995 L4.91225524,4.90947995 L4.91225524,18.4104773 L65.0877448,18.4104773 L65.0877448,4.90947995 L60.6271511,4.90947995 L54.2629606,12.8874211 L44.6648615,12.8874211 L38.3006709,4.90947995 L31.6990388,4.90947995 L25.3351385,12.8874211 L15.7370394,12.8874211 L9.37284888,4.90947995 Z"
              id="icon-king"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default iconKing
