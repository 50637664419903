import React, { useEffect, useState, useRef } from 'react'

declare global {
  interface Window {
    impersonation_ephemeralToken: string | undefined
    impersonation_tokenExpirationSeconds: number | undefined
    impersonation_email: string | undefined
    impersonation_tenantAccountCuid: string | undefined
  }
}

let billingPortalOrigin = 'https://localhost:3001'
window.location.hostname === 'dev.admin.kingenergy.com' && (billingPortalOrigin = 'https://dev.service.kingenergy.com')
window.location.hostname === 'admin.kingenergy.com' && (billingPortalOrigin = 'https://service.kingenergy.com')

export const Impersonate: React.FC = () => {
  const [timeRemaining, _setTimeRemaining] = useState(window.impersonation_tokenExpirationSeconds)

  const timeRemainingRef = useRef(timeRemaining)
  const setTimeRemaining = (timeRemaining: any): void => {
    timeRemainingRef.current = timeRemaining
    _setTimeRemaining(timeRemaining)
  }

  const handleIframeLoad = (): void => {
    const iframe = document.getElementById('iframe') as HTMLIFrameElement
    iframe.contentWindow?.postMessage(
      {
        email: window.impersonation_email,
        atk: window.impersonation_ephemeralToken,
        tenantAccountCuid: window.impersonation_tenantAccountCuid,
      },
      billingPortalOrigin
    )
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(Number(timeRemainingRef.current) - 1)
      if (Number(timeRemainingRef.current) < 0) {
        clearInterval(interval)
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  if (window.impersonation_ephemeralToken == null || Number(timeRemainingRef.current) <= 0) {
    return (
      <div className="p-4">
        Impersonation session invalidated.
        <button
          type="button"
          onClick={() => {
            window.close()
          }}
          className="ml-3 inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Exit
        </button>
      </div>
    )
  }

  return (
    <div className="flex flex-col h-screen">
      <div className="w-full bg-red-500 text-white p-2 text-center font-normal">
        Viewing account <strong>{window.impersonation_email}</strong> in read-only mode.
        <div className="w-16 inline-block text-center">
          ({Math.floor(Number(timeRemaining) / 60)}:{Number(timeRemaining) % 60 < 10 ? 0 : ''}
          {Number(timeRemaining) % 60})
        </div>
        <button
          type="button"
          onClick={() => {
            window.close()
          }}
          className="ml-1 inline-flex items-center px-2.5 py-0.5 border shadow-sm text-sm font-medium rounded text-red-500 bg-white hover:bg-red-50 focus:outline-none"
        >
          Exit
        </button>
      </div>
      <iframe src={billingPortalOrigin} id="iframe" frameBorder="0" onLoad={handleIframeLoad} className="w-full flex-1"></iframe>
    </div>
  )
}

export default Impersonate
