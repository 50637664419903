import React, { ChangeEvent, useState, useEffect } from "react";
import { useDebounce } from "usehooks-ts";
import classnames from "classnames";

export interface QuickSearchProps {
  title: string;
  initialValue?: string;
  placeholder?: string;
  handleChange: (value: string) => void;
}

const QuickSearch: React.FC<QuickSearchProps> = (props) => {
  const { title, initialValue, placeholder } = props;
  const [value, setValue] = useState<string | undefined>(initialValue);
  const debouncedValue = useDebounce<string | undefined>(value, 500);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
  };

  const handleValueChange = props.handleChange;

  useEffect(() => {
    handleValueChange(debouncedValue ?? "");
  }, [debouncedValue, handleValueChange]);

  return (
    <div className="">
      <label htmlFor="search" className="block text-sm font-medium text-zinc-700">
        {title}
      </label>
      <input
        type="text"
        name="search"
        id="search"
        value={value}
        onChange={(e) => handleChange(e)}
        placeholder={placeholder}
        className={classnames(
          'mt-1 bg-white block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
        )}
      />
    </div>
  )
};

export default QuickSearch;
