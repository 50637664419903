import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import classnames from "classnames";

export interface InputProps extends React.ComponentProps<"input"> {
  label?: string;
  value: string;
  error?: string;
  className?: string;
  displayField?: boolean;
  required?: boolean;
  disabled?: boolean;
  description?: string;
}

export const Input: React.FC<InputProps> = (props) => {
  const {
    label,
    value,
    error,
    className,
    displayField,
    required,
    disabled,
    description,
    ...rest
  } = props;

  return (
    <div className={className}>
      {label !== undefined && (
        <label htmlFor={label} className={classnames('block text-sm font-medium text-zinc-700 truncate text-left', description != null ? 'h-9' : 'h-5')}>
          <div className="flex flex-col">
            <div>
              {label}
              {required === true && <span className="ml-1 text-sm text-red-500">*</span>}
            </div>
            {description != null && <span className="text-xs text-gray-400">{description}</span>}
          </div>
        </label>
      )}
      <div className="mt-1 relative z-0 rounded-md">
        {displayField ?? false ? (
          <span className="h-10 block w-full text-sm truncate">{value}</span>
        ) : (
          <input
            type="text"
            className={classnames(
              'mt-1 px-2 py-1.5 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600 sm:text-sm sm:leading-6',
              disabled === true ? 'bg-gray-100 cursor-not-allowed' : 'bg-white',
              {
                'focus:outline-none focus:border-none focus:ring-2 ring-red-500 focus:ring-red-500': error,
              },
            )}
            value={value}
            {...rest}
            autoComplete="off"
            disabled={disabled}
          />
        )}
        {error !== undefined && (
          <div className="absolute z-0 inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      {error !== undefined && (
        <p className="mt-1 text-xs text-red-600" id="email-error">
          {error}
        </p>
      )}
    </div>
  )
};

export default Input;
