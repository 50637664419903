import React from "react";
import styled from "styled-components";
import IconKing from "./icons/icon-king";
import Menu from "./Menu";

const Component = styled.div`
  flex: 0.1;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  border-right: 2px solid #ededed;
  max-width: min-content;
  color: #777;
  .logo {
    width: 56px;
    min-height: 56px;
    margin: 20px 24px;
    display: inline-block;
    color: var(--king-blue);
  }
`;

const Nav: React.FC = () => {
  return (
    <Component>
      <IconKing className="logo" />
      <Menu />
    </Component>
  );
};

export default Nav;
