import React from 'react'
import classnames from 'classnames'

export interface PanelProps extends React.ComponentProps<'div'> {
  header: React.ReactElement | string
  headerClassName?: string
}

export const Panel: React.FC<PanelProps> = (props) => {
  const { className, headerClassName, ...rest } = props
  return (
    <div className={classnames('w-full bg-white shadow rounded-lg border', className)} {...rest}>
      <div className={classnames('bg-indigo-50 py-3 px-4 font-semibold rounded-t-lg', headerClassName)}>{props.header}</div>
      <div className="py-3 px-4 rounded-b-lg">{props.children}</div>
    </div>
  )
}

export default Panel
