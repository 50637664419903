import React from 'react'
import { format } from 'date-fns'
import Button from '../../../../components/Form/Button'

export interface Note {
  id: string
  body: string
  author: { id: string; username: string | null }
  createdAt: string
}

export interface PanelNotesProps {
  noteInput: string
  notes: Note[]
  postNoteRequestPhase: { kind: 'idle' } | { kind: 'in-progress' } | { kind: 'failed' }
  setNoteInput: (value: string) => void
  onPostNoteRequest: () => Promise<void>
}

export const PanelNotes: React.FC<PanelNotesProps> = (props) => {
  const isPostingNote = props.postNoteRequestPhase.kind === 'in-progress'
  const isPostInputEmpty = props.noteInput.length === 0

  return (
    <div className="flex flex-col">
      <div className="flex align-middle">
        <div className="flex-1">
          <textarea
            value={props.noteInput}
            disabled={isPostingNote}
            placeholder="Add a note..."
            className="mt-1 px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            onChange={(event) => props.setNoteInput(event.target.value)}
          />
        </div>
        <div className="ml-2">
          <Button disabled={isPostingNote || isPostInputEmpty} className="h-10" onClick={async () => await props.onPostNoteRequest()}>
            Post
          </Button>
        </div>
      </div>
      <div className="mt-2 h-24 overflow-y-scroll space-y-2">
        {props.notes?.length === 0 && (
          <div>
            <span className="text-xs text-zinc-600">No notes yet for this tenant.</span>
          </div>
        )}
        {props.notes?.map((note) => {
          return (
            <div key={note.id} className="flex flex-col">
              <span className="text-xs text-zinc-600">{note.body}</span>
              <span className="text-xs text-zinc-400">
                {format(new Date(note.createdAt), "M/d/yy 'at' h:mm aaa")}
                {note.author.username !== null ? ` by ${note.author.username}` : null}
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PanelNotes
