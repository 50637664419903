import React from "react";
import styled from "styled-components";
import classnames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
// import IconSystem from './icons/icon-system'
// import IconBilling from './icons/icon-billing'
// import IconUtilities from './icons/icon-utilities'
import IconTenants from "./icons/icon-tenants";
// import IconCapMarkets from './icons/icon-capmarkets'
// import IconWorkflow from './icons/icon-workflow'
// import IconMessaging from './icons/icon-messaging'
// import IconSales from './icons/icon-sales'
// import IconReports from './icons/icon-reports'
import IconUsers from "./icons/icon-users";
import IconSearch from "./icons/icon-search";
import IconLogOut from "./icons/icon-log-out";
import { FolderIcon } from "@heroicons/react/24/outline";
import IconPrograms from "./icons/icon-programs";
import IconCash from "./icons/icon-cash";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppSelector } from "../../shared/redux/hooks";

const Component = styled.div`
  --nav-grey: #d8d8d8;
  --nav-light-blue: #f2fbff;
  --nav-light-grey-hover: #fbfbfb;
  --nav-light-blue-hover: #fcfeff;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 10px 0;
  .navItem {
    text-decoration: none;
    font-weight: 600;
    padding: 10px 20px;
    border-left: 4px solid;
    border-color: transparent;
    white-space: nowrap;
    transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out,
      background-color 0.1s ease-in-out;
    &.isOpen {
      background-color: white;
      color: var(--king-blue);
      border-color: var(--nav-grey);
    }
    &:hover {
      background-color: var(--nav-light-grey-hover);
      border-color: var(--nav-grey);
    }
    svg {
      margin: 0px 10px 2px 0;
      display: inline-block;
    }
  }
  .navLinkGroup {
    display: flex;
    overflow: hidden;
    max-height: 0;
    height: min-content;
    transition: max-height 0.2s ease-in-out;
    flex-direction: column;
    .navItem {
      border-color: var(--nav-grey);
      font-size: 0.8em;
      font-weight: 500;
      transition: background-color 0.1s ease-in-out;
      padding: 10px 20px 10px 60px;
      line-height: 1.2em;
      white-space: normal;
      &:hover {
        background-color: var(--nav-light-blue-hover);
      }
    }
  }
  .active {
    .navItem {
      background-color: white;
      color: var(--king-blue);
      border-color: var(--king-blue);
      background-color: var(--nav-light-blue);
      &.hasChildren {
        border-color: var(--nav-grey);
        background-color: white;
      }
    }
    &.navLinkGroup {
      max-height: 265px;
      .navItem {
        color: #717171;
        border-color: var(--nav-grey);
        background-color: white;
        transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;
        &:hover {
          color: #525252;
          background-color: var(--nav-light-grey-hover);
        }
      }
      .active > .navItem {
        background-color: var(--nav-light-blue);
        border-color: var(--king-blue);
        color: var(--king-blue);
      }
    }
  }
  .keyboardShortcut {
    display: inline-block;
    border-radius: 3px;
    padding: 0 3px;
    font-size: 12px;
    margin: 0 0 0 3px;
    border: 1px solid currentColor;
    top: -1px;
    position: relative;
  }
`

const Nav: React.FC = () => {
  const location = useLocation();
  const { permissions } = useAppSelector((state) => state.app);
  const { logout, user, isAuthenticated, isLoading } = useAuth0();

  const hasFileExplorerPermission = (): boolean => {
    if (permissions == null) return false;
    return permissions.includes("storagedrive:file:read");
  };

  const navConfig: Array<{
    label: string | React.ReactNode;
    to: string;
    baseRoute?: string;
    icon: React.ReactNode;
    children?: Array<{
      label: string;
      to: string;
    }>;
    exact?: boolean;
  }> = [
    {
      label: (
        <>
          Quick Search <div className="keyboardShortcut">⌘K</div>
        </>
      ),
      to: "/",
      exact: true,
      icon: <IconSearch />,
    },
    {
      label: "Collections",
      to: "/collections/invoices",
      baseRoute: "/collections",
      icon: <IconCash />,
      children: [
        {
          label: "Invoices",
          to: "/collections/invoices",
        },
        {
          label: "Payments",
          to: "/collections/payments",
        },
        // {
        //   label: "Adjustments",
        //   to: "/billing/statements/adjustments"
        // }
      ],
    },
    ...(hasFileExplorerPermission()
      ? [
          {
            label: "File Explorer",
            to: "/file-explorer",
            icon: <FolderIcon className="h-6 w-6" />,
          },
        ]
      : []),
    {
      label: "Programs",
      to: "/program/programs",
      icon: <IconPrograms />,
      // children: [{
      //   label: "Roles",
      //   to: "/users/roles",
      // }, {
      //   label: "Permissions",
      //   to: "/users/permissions",
      // }]
    },
    // {
    //   label: "Utilities",
    //   to: "/utilities",
    //   icon: <IconUtilities />,
    //   children: [{
    //     label: "Providers",
    //     to: "/utilities/providers"
    //   }, {
    //     label: "Tariffs",
    //     to: "/utilities/tariffs"
    //   }, {
    //     label: "Programs",
    //     to: "/utilities/programs"
    //   }, {
    //     label: "Meters",
    //     to: "/utilities/meters"
    //   }],
    // },
    {
      label: "Tenants",
      to: "/tenant/tenants",
      baseRoute: "/tenant",
      icon: <IconTenants />,
      children: [
        // {
        //   label: "Landlords",
        //   to: "/tenant/landlords"
        // },
        {
          label: "Onboarding",
          to: "/tenant/onboarding",
        },
        // {
        //   label: "Theming",
        //   to: "/tenant/theming"
        // }
      ],
    },
    // {
    //   label: "Capital Markets",
    //   to: "/capmarkets",
    //   icon: <IconCapMarkets />,
    //   children: [{
    //     label: "Banking",
    //     to: "/capmarkets/banking"
    //   }, {
    //     label: "Borrowers",
    //     to: "/capmarkets/borrowers"
    //   }, {
    //     label: "Investors",
    //     to: "/capmarkets/investors"
    //   }, {
    //     label: "Risk",
    //     to: "/capmarkets/risk"
    //   }],
    // },
    // {
    //   label: "Workflow",
    //   to: "/workflow",
    //   icon: <IconWorkflow />,
    // },
    // {
    //   label: "Messaging",
    //   to: "/messaging",
    //   icon: <IconMessaging />,
    // },
    // {
    //   label: "Sales",
    //   to: "/sales",
    //   icon: <IconSales />,
    //   children: [{
    //     label: "Leads",
    //     to: "/sales/leads",
    //   }]
    // },
    // {
    //   label: "Reporting",
    //   to: "/reporting",
    //   icon: <IconReports />,
    // },
    {
      label: "Users",
      to: "/users",
      icon: <IconUsers />,
      // children: [{
      //   label: "Roles",
      //   to: "/users/roles",
      // }, {
      //   label: "Permissions",
      //   to: "/users/permissions",
      // }]
    },
  ];

  // console.group();
  const menuOptions = navConfig.map((item, itemIndex) => {
    // console.log(item.baseRoute || item.to, useRouteMatch(item.baseRoute), !!useRouteMatch(item.baseRoute));
    return (
      <React.Fragment key={`${item.to}-${itemIndex}`}>
        <NavLink
          to={item.to}
          // className={location.pathname.includes(item.to) ? "active" : ""}
        >
          <div
            className={classnames("navItem", {
              // isOpen: location.pathname.includes(item.baseRoute ?? ""),
            })}
          >
            {item.icon}
            {item.label}
          </div>
        </NavLink>
        {item.children !== undefined && (
          <div
            className={classnames("navLinkGroup", {
              active: location.pathname.includes(item.baseRoute ?? ""),
            })}
            style={{
              maxHeight: location.pathname.includes(item.baseRoute ?? "")
                ? 50 * item.children.length
                : 0,
            }}
          >
            {item.children.map((child, childIndex) => {
              return (
                <NavLink
                  end
                  to={child.to}
                  // className={
                  //   location.pathname.match(
                  //     new RegExp(
                  //       `${child.to.replace(/\//g, "/")}(/(.*)(/.*)?)?$`
                  //     )
                  //   ) !== null
                  //     ? "active"
                  //     : ""
                  // }
                  key={`${child.to}-${childIndex}`}
                >
                  <div className="navItem">{child.label}</div>
                </NavLink>
              );
            })}
          </div>
        )}
      </React.Fragment>
    );
  });
  // console.groupEnd();

  return (
    <>
      <Component>
        <div>{menuOptions}</div>
        <div>
          <a
            href="#"
            onClick={() => {
              logout({ logoutParams: { returnTo: window.location.origin } });
            }}
          >
            <div className="navItem">
              <IconLogOut />
              Log Out
            </div>
          </a>
        </div>
      </Component>
      {isAuthenticated && !isLoading ? (
        <div className="p-2 pb-4 text-xs pointer-default text-zinc-400 border-t">
          {user?.name != null && user?.name !== "" ? (
            <div
              className="truncate font-medium w-[170px] text-zinc-500"
              title={user.name}
            >
              {user.name}
            </div>
          ) : null}
          {user?.email != null && user?.email !== "" ? (
            <div
              className="truncate font-medium w-[170px] text-zinc-500"
              title={user.email}
            >
              {user.email}
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default Nav;
