import React, { useState } from "react";
import ReadinessCheckListItem from "./ReadinessCheckListItem";
import Button from "../../../components/Form/Button";
import VNEMWarning from "./VNEMWarning";
import { DetailedProgramItem, ReadinessChecklist } from "../../../apis/types";
import { object, string } from "yup";
import { useFormik } from "formik";
import {
  linkUtilityPortalApi,
  updateUtilityPortalCredentialsApi,
  updateScraperIsActive,
} from "../../../apis/apis";
import { pushToast } from "../../../components/Toaster/Toaster.slice";
import UpdateCredentialsForm from "./UpdateCredentialsForm";
import Modal from "../../../components/Modal";
import { useAppDispatch } from "../../../shared/redux/hooks";

interface ReadinessCheckListProps {
  program: DetailedProgramItem | undefined;
  readinessChecklist: ReadinessChecklist;
  setReadinessChecklist: React.Dispatch<
    React.SetStateAction<ReadinessChecklist | null>
  >;
  handleCaptureVNEMSubmission: () => void;
  formatDate: (dateStr: string | null | undefined) => string;
  handleRefreshReadinessChecklist: () => void;
}

const ReadinessCheckList: React.FC<ReadinessCheckListProps> = ({
  program,
  readinessChecklist,
  handleCaptureVNEMSubmission,
  formatDate,
  handleRefreshReadinessChecklist,
}) => {
  const dispatch = useAppDispatch();

  const [showUpdateCredentialsForm, setShowUpdateCredentialsForm] =
    useState(false);
  const [toggleChecked, setToggleChecked] = useState(
    readinessChecklist.portalLinking.scraperIsActive ?? false
  );
  const [toggleDisabled, setToggleDisabled] = useState(false);
  const [confirmModal, setConfirmModal] = useState<
    React.ReactElement | boolean
  >(false);

  const handleToggle = async (): Promise<void> => {
    if (program?.id == null) return;
    try {
      await new Promise((resolve, reject) => {
        setConfirmModal(<Modal resolve={resolve} reject={reject} />);
      });
      setConfirmModal(false);
      setToggleDisabled(true);
      void updateScraperIsActive(program?.id, !toggleChecked)
        .then((res) => {
          setToggleChecked(res.scraperIsActive ?? !toggleChecked);
        })
        .catch(
          async (e) =>
            await dispatch(
              pushToast({
                type: "error",
                message: "Error updating scraper status",
                description: `${
                  e.response?.data?.error !== undefined
                    ? `${e.response?.data?.error as string}:`
                    : "Error:"
                } ${e.message as string}`,
              })
            )
        );

      setTimeout(() => {
        setToggleDisabled(false);
      }, 1000);
    } catch (e) {
      setConfirmModal(false);
    }
  };

  const requireStr = "This field is required";
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    enableReinitialize: true,
    validationSchema: object().shape({
      username: string()
        .required(requireStr)
        .min(8, "Username must be at least 8 characters"),
      password: string()
        .required(requireStr)
        .min(8, "Password must be at least 8 characters"),
    }),
    onSubmit: async (values) => {
      const linkPortal = async (): Promise<void> => {
        let res;
        if (readinessChecklist.portalLinking.isLinked) {
          res = await updateUtilityPortalCredentialsApi(program?.id ?? "", {
            username: values.username,
            password: values.password,
          });
        } else {
          res = await linkUtilityPortalApi(program?.id ?? "", {
            username: values.username,
            password: values.password,
          });
        }
        if (res.success !== true) {
          throw new Error("Error linking utility portal");
        }
      };

      try {
        await new Promise((resolve, reject) => {
          setConfirmModal(<Modal resolve={resolve} reject={reject} />);
        });
        setConfirmModal(false);
        await linkPortal()
          .then(async () => {
            await dispatch(
              pushToast({
                type: "success",
                message: "Utility portal linked successfully",
              })
            );
          })
          .catch(async (e) => {
            await dispatch(
              pushToast({
                type: "error",
                message: "Error fetching file list",
                description: `${
                  e.response?.data?.error !== undefined
                    ? `${e.response?.data?.error as string}:`
                    : "Error:"
                } ${e.message as string}`,
              })
            );
          });
        setShowUpdateCredentialsForm(false);
        handleRefreshReadinessChecklist();
      } catch (e) {
        setConfirmModal(false);
      }
    },
  });

  return (
    <>
      {confirmModal}
      <div className="mb-5 text-xl font-medium">Readiness Checklist</div>
      <div className="flex flex-col justify-between">
        <div className="border-b border-slate-300 pb-4">
          {/* If a program does not have a salesforce id, even if the readiness checklist says VNEM allocations have been submitted, don't
          let the user capture new VNEM allocations or view them  */}
          {readinessChecklist.allocationSubmission.isSubmitted &&
          program?.salesforceId != null ? (
            <ReadinessCheckListItem
              isComplete={true}
              isActive={false}
              isIncomplete={false}
              headingText="VNEM Allocation Submission Captured"
              infoComponent={
                <>
                  {readinessChecklist.allocationSubmission.submissionDate !=
                    null && (
                    <p>
                      Submission date:{" "}
                      {formatDate(
                        readinessChecklist.allocationSubmission.submissionDate
                      )}
                    </p>
                  )}
                  {readinessChecklist.allocationSubmission.startDate !=
                    null && (
                    <p>
                      Start date:{" "}
                      {formatDate(
                        readinessChecklist.allocationSubmission.startDate
                      )}
                    </p>
                  )}
                  {readinessChecklist.allocationSubmission.endDate != null && (
                    <p>
                      End date:{" "}
                      {formatDate(
                        readinessChecklist.allocationSubmission.endDate
                      )}
                    </p>
                  )}
                </>
              }
              actionComponent={
                <>
                  <Button
                    onClick={() => {
                      handleCaptureVNEMSubmission();
                    }}
                  >
                    Capture VNEM Allocations...
                  </Button>
                  <Button className="mt-2">
                    <a
                      href={`https://metabase.kingenergy.com/question/76-onebill-billing-readiness-report?property_name=${
                        program?.name?.split(" ")[0] ?? ""
                      }`}
                      target="_blank"
                      rel="noreferrer"
                      className="font-medium"
                    >
                      View Current Allocations...
                    </a>
                  </Button>
                </>
              }
            />
          ) : (
            <ReadinessCheckListItem
              isComplete={false}
              isActive={false}
              isIncomplete={true}
              headingText="VNEM Allocation Submission Captured"
              infoComponent={<p>No submissions captured</p>}
              actionComponent={
                <>
                  {program?.salesforceId != null ? (
                    <Button
                      className="mt-2"
                      onClick={() => {
                        handleCaptureVNEMSubmission();
                      }}
                    >
                      Capture VNEM Allocations...
                    </Button>
                  ) : (
                    <div className="ml-12">
                      <VNEMWarning
                        type="error"
                        text="This program must be assigned a Salesforce Program ID in order to capture VNEM allocations"
                      />
                    </div>
                  )}
                </>
              }
            />
          )}
        </div>
        <div className="pt-4">
          {readinessChecklist.portalLinking.isLinked ? (
            <ReadinessCheckListItem
              isComplete={true}
              isActive={false}
              isIncomplete={false}
              headingText={`${
                program?.utility != null ? program?.utility : "Utility"
              } Portal Linked`}
              infoComponent={
                <>
                  <p>
                    Username: {readinessChecklist.portalLinking.utilityUsername}
                  </p>
                  {readinessChecklist.portalLinking.scraperLastRun != null && (
                    <p>
                      Scraper job last run:{" "}
                      {formatDate(
                        readinessChecklist.portalLinking.scraperLastRun
                      )}
                    </p>
                  )}
                </>
              }
              actionComponent={
                <>
                  <div className="flex flex-row justify-between">
                    {/* TODO: Uncomment when scraperJobSuccessful data is available from a new endpoint  */}
                    {/* <div className="flex flex-col mr-4">
                      <p>Job Status:</p>
                      {readinessChecklist.portalLinking.scraperJobLastRun == null ? (
                        <p>Not run</p>
                      ) : readinessChecklist.portalLinking.scraperJobSuccessful === true ? (
                        <p className="text-emerald-500">Success</p>
                      ) : (
                        <p className="text-red-500">Failed</p>
                      )}
                    </div> */}
                    <div className="flex flex-col justify-between">
                      {/* TODO: Uncomment if/when "Run Scraper Job feature is implemented" */}
                      {/* <Button
                        onClick={() => {
                          console.log('Run scraper job')
                        }}
                        disabled
                      >
                        Run Scraper Job
                      </Button> */}
                      <UpdateCredentialsForm
                        formik={formik}
                        showUpdateCredentialsForm={showUpdateCredentialsForm}
                        setShowUpdateCredentialsForm={
                          setShowUpdateCredentialsForm
                        }
                        toggleChecked={toggleChecked}
                        onToggle={handleToggle as () => void}
                        toggleDisabled={toggleDisabled}
                        showToggle={true}
                      />
                    </div>
                  </div>
                </>
              }
            />
          ) : (
            <ReadinessCheckListItem
              isComplete={false}
              isActive={false}
              isIncomplete={true}
              headingText={`${
                program?.utility != null ? program?.utility : "Utility"
              } Portal Linked`}
              infoComponent={
                <p>{`${
                  program?.utility != null ? program?.utility : "Utility"
                } portal not linked.`}</p>
              }
              actionComponent={
                <UpdateCredentialsForm
                  formik={formik}
                  showUpdateCredentialsForm={showUpdateCredentialsForm}
                  setShowUpdateCredentialsForm={setShowUpdateCredentialsForm}
                  toggleChecked={toggleChecked}
                  onToggle={handleToggle as () => void}
                  toggleDisabled={toggleDisabled}
                  showToggle={false}
                />
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ReadinessCheckList;
