import axios from 'axios'

import type { BaseQueryFn } from '@reduxjs/toolkit/query/react'
import type { AxiosError } from 'axios'

import type { APIErrors, BadServiceInputError, EntityNotFoundError, EntityUniqueConstraintBrokenError, InternalServerError } from './errors'

interface AxiosAPIError {
  status: number
  data: { code: string }
}

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string
      method: string
      data?: unknown
    },
    unknown,
    APIErrors
  > =>
  async ({ url, method, data }) => {
    try {
      const result = await axios({ url: baseUrl + url, method, data })

      return { data: result.data }
    } catch (error) {
      const { status, data } = ((error as AxiosError).response as AxiosAPIError) ?? {
        status: 500,
        data: {
          code: 'internal-server-error',
          message: 'Server has not been able to fulfill the current request',
          meta: {},
        },
      }

      if (status === 400 && data.code === 'bad-service-input') {
        return { error: data as BadServiceInputError }
      } else if (status === 404 && data.code === 'entity-not-found') {
        return { error: data as EntityNotFoundError }
      } else if (status === 422 && data.code === 'entity-unique-constraint-broken') {
        return { error: data as EntityUniqueConstraintBrokenError }
      } else {
        return { error: data as InternalServerError }
      }
    }
  }
