import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPrograms, getPropertyList } from "./Programs.slice";
import Layout from "./Layout";
import Table from "../../components/Table/Table";
import { ProgramItem } from "../../apis/types";
import { formatUTCDate } from "../../util/string";
import Panel from "../../components/Panel";
import QuickSearch from "../../components/QuickSearch/QuickSearch";
import ComboboxAutocomplete from "../../components/Form/ComboboxAutocomplete";
import { useAppDispatch, useAppSelector } from "../../shared/redux/hooks";

const Programs: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { programs, properties } = useAppSelector((state) => state.programs);

  const [propertyId, setPropertyId] = useState<string | undefined>(undefined);
  const [programQuickSearchTerm, setProgramQuickSearchTerm] = useState<
    string | undefined
  >();

  useEffect(() => {
    void dispatch(getPropertyList());
  }, [dispatch]);

  useEffect(() => {
    void dispatch(
      getPrograms({
        page: 0,
        resultsPerPage: 15,
        programName: programQuickSearchTerm,
        propertyId: propertyId,
      })
    );
  }, [dispatch, programQuickSearchTerm, propertyId]);

  const handleQuickSearchChange = (value: string): void => {
    if (value === "") {
      setProgramQuickSearchTerm(undefined);
      return;
    }
    setProgramQuickSearchTerm(value);
  };

  const handleRecordClick = (rec: ProgramItem): void => {
    navigate(`/program/programs/${rec.id}`);
  };

  const handlePaginatorClick = (page: number): void => {
    void dispatch(getPrograms({ page, resultsPerPage: 15 }));
  };

  return (
    <Layout title="Programs">
      <Panel header="Filters" className="mb-5">
        <div className="grid grid-auto-flow grid-cols-3 gap-3 mb-2">
          <div>
            <label
              htmlFor="propertyNames"
              className="block text-sm font-medium text-zinc-700"
            >
              Property name
            </label>
            {properties !== undefined && (
              <ComboboxAutocomplete
                labelPlural="Properties"
                value={propertyId ?? ""}
                handleChange={setPropertyId}
                data={properties}
                dropDownZIndex="z-10"
              />
            )}
          </div>
          <QuickSearch
            title="Program name"
            initialValue={programQuickSearchTerm}
            handleChange={(value) => handleQuickSearchChange(value)}
          />
        </div>
      </Panel>
      {!(programs == null) && (
        <Table<ProgramItem>
          colConfig={[
            {
              label: "Property Name",
              render: (rec) => rec.propertyName,
            },
            {
              label: "Program Name",
              render: (rec) => rec.name,
            },
            {
              label: "Utility",
              render: (rec) => rec.utility,
            },
            {
              label: "Start Date",
              render: (rec) =>
                rec.startDate != null
                  ? formatUTCDate(new Date(rec.startDate))
                  : "",
            },
            {
              label: "End Date",
              render: (rec) =>
                rec.endDate != null ? formatUTCDate(new Date(rec.endDate)) : "",
            },
            {
              label: "PTO Date",
              render: (rec) => formatUTCDate(new Date(rec.ptoDate)),
            },
            {
              label: "VNEM Billing Start Date",
              render: (rec) => formatUTCDate(new Date(rec.billingOnDate)),
            },
            {
              label: "Allocation Percentage",
              render: (rec) =>
                rec.totalVNEMAllocationPercentage != null &&
                `${rec.totalVNEMAllocationPercentage}%`,
            },
          ]}
          data={programs}
          handleClick={handleRecordClick}
          handlePaginatorClick={handlePaginatorClick}
        />
      )}
    </Layout>
  );
};

export default Programs;
