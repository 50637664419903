import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../shared/redux/store'

const SLICE_NAME = 'TOASTER_SLICE'

export interface Toast {
  id: number
  type?: 'success' | 'error'
  message: string
  description?: string
}

export const pushToast = createAsyncThunk<
  Toast,
  Omit<Toast, 'id'>,
  {
    state: RootState
    dispatch: AppDispatch
    rejectValue: { err: boolean }
  }
>(`${SLICE_NAME}/pushToast`, (payload) => {
  const id = Date.now()
  return { id, ...payload }
})

export const removeToast = createAsyncThunk(`${SLICE_NAME}/removeToast`, () => {})

interface state {
  loading: boolean
  error: boolean
  data: Toast[]
}

const initialState: state = {
  loading: false,
  error: false,
  data: [],
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(pushToast.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = false
      state.data = [...state.data, payload]
    })

    builder.addCase(removeToast.fulfilled, (state) => {
      state.loading = false
      state.error = false
      state.data.splice(0, 1)
    })
  },
})

export const toasterReducer = slice.reducer
