import React from 'react'
import TenantSearch from '../../components/TenantSearch/TenantSearch'
import Layout from './Layout'
import iconTenant from '../../components/TenantSearch/icons/icon-tenant.svg'
import iconProperty from '../../components/TenantSearch/icons/icon-property.svg'
import iconProgram from '../../components/TenantSearch/icons/icon-program.svg'

const System: React.FC = () => {
  return (
    <Layout title="Quick search">
      <div className="w-full mx-auto flex">
        <div className="w-2/3 py-4 pr-4">
          <TenantSearch autoFocus />
        </div>
        <div className="w-1/3 p-4">
          <label className="block text-sm text-zinc-700">
            <div className="text-base font-semibold">Search by:</div>
            <ul className="list-disc pl-5 mt-2 space-y-1">
              <li>Tenant account name, number, billing address</li>
              <li>Utility account name, number</li>
              <li>Service agreement number, service address</li>
              <li>Meter number</li>
              <li>Contact or account manager name, email address, phone number</li>
              <li>Property name</li>
              <li>Program name</li>
            </ul>
          </label>
          <label className="block text-sm text-zinc-700 mt-4">
            <div className="text-base font-semibold">Returns: </div>
            <div className="mt-2 space-y-1">
              <div className="flex flex-row items-center">
                <img src={iconTenant} alt="Tenant" />
                <div className="ml-2">Tenants</div>
              </div>
              <div className="flex flex-row items-center">
                <img src={iconProperty} alt="Property" />
                <div className="ml-2">Properties</div>
              </div>
              <div className="flex flex-row items-center">
                <img src={iconProgram} alt="Program" />
                <div className="ml-2">Programs</div>
              </div>
            </div>
          </label>
        </div>
      </div>
    </Layout>
  )
}

export default System
