import React from 'react'

const GoogleSheetIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="60px" viewBox="0 0 31 36">
      <defs>
        <linearGradient id="linearGradient-1" x1="50.005%" x2="50.005%" y1="8.586%" y2="100.014%">
          <stop offset="0%" stopColor="#263238" stopOpacity="0.2"></stop>
          <stop offset="100%" stopColor="#263238" stopOpacity="0.02"></stop>
        </linearGradient>
        <radialGradient id="radialGradient-2" cx="3.168%" cy="2.763%" r="161.248%" fx="3.168%" fy="2.763%" gradientTransform="matrix(1 0 0 1.02669 0 0)">
          <stop offset="0%" stopColor="#FFF"></stop>
          <stop offset="100%" stopColor="#FFF" stopOpacity="0"></stop>
        </radialGradient>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-38 -6)">
          <g transform="translate(38 6)">
            <path
              fill="#0F9D58"
              fillRule="nonzero"
              d="M2.98 0h17.29L31 9v24.5c0 1.38-1.335 2.5-2.98 2.5H2.98C1.336 36 0 34.88 0 33.5v-31C0 1.12 1.335 0 2.98 0z"
            ></path>
            <path
              fill="#263238"
              fillOpacity="0.1"
              fillRule="nonzero"
              d="M0 33c0 1.38 1.335 2.5 2.98 2.5h25.04c1.645 0 2.98-1.12 2.98-2.5v.5c0 1.38-1.335 2.5-2.98 2.5H2.98C1.336 36 0 34.88 0 33.5V33z"
            ></path>
            <path fill="#FFF" fillOpacity="0.2" fillRule="nonzero" d="M2.98 0h17.29v.5H2.98C1.336.5 0 1.62 0 3v-.5C0 1.12 1.335 0 2.98 0z"></path>
            <path fill="url(#linearGradient-1)" fillRule="nonzero" d="M20.8653846 8L31 16.5 31 9"></path>
            <path fill="#87CEAC" fillRule="nonzero" d="M20.27 0L31 9h-7.75c-1.646 0-2.98-1.12-2.98-2.5V0z"></path>
            <path fill="#FFF" fillRule="nonzero" d="M25 22L14 22 14 30 12 30 12 22 7 22 7 20 12 20 12 16 14 16 14 20 25 20z"></path>
            <path
              fill="url(#radialGradient-2)"
              fillOpacity="0.1"
              fillRule="nonzero"
              d="M2.98 0h17.29L31 9v24.5c0 1.38-1.335 2.5-2.98 2.5H2.98C1.336 36 0 34.88 0 33.5v-31C0 1.12 1.335 0 2.98 0z"
            ></path>
            <path
              fill="#FFF"
              d="M7.686 10.875c-1.69 0-3.061-1.343-3.061-3s1.37-3 3.061-3c.825 0 1.518.298 2.048.782l-.878.86a1.677 1.677 0 00-1.17-.449 1.82 1.82 0 00-1.81 1.808c0 .978.811 1.806 1.81 1.806.907 0 1.524-.51 1.65-1.208h-1.65V7.325h2.883c.036.197.056.404.056.618 0 1.714-1.171 2.932-2.939 2.932z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default GoogleSheetIcon
