export const fileExtensionIconStyles: Array<{
  fileType: string
  color?: string
  labelColor?: string
  type?: string
  glyphColor?: string
  extension?: string
}> = [
  {
    fileType: 'doc',
    color: 'var(--file-icon-blue)',
    labelColor: 'var(--file-icon-blue)',
    type: 'document',
    glyphColor: 'var(--file-icon-glyph)',
    extension: 'doc',
  },
  {
    fileType: 'docx',
    color: 'var(--file-icon-blue)',
    labelColor: 'var(--file-icon-blue)',
    type: 'document',
    glyphColor: 'var(--file-icon-glyph)',
    extension: 'doc',
  },
  {
    fileType: 'xlsx',
    color: 'var(--file-icon-green)',
    labelColor: 'var(--file-icon-green)',
    type: 'spreadsheet',
    glyphColor: 'var(--file-icon-glyph)',
    extension: 'xls',
  },
  {
    fileType: 'xls',
    color: 'var(--file-icon-green)',
    labelColor: 'var(--file-icon-green)',
    type: 'spreadsheet',
    glyphColor: 'var(--file-icon-glyph)',
    extension: 'xls',
  },
  {
    fileType: 'xlsm',
    color: 'var(--file-icon-green)',
    labelColor: 'var(--file-icon-green)',
    type: 'spreadsheet',
    glyphColor: 'var(--file-icon-glyph)',
    extension: 'xlsm',
  },

  {
    fileType: 'csv',
    color: 'var(--file-icon-green)',
    labelColor: 'var(--file-icon-green)',
    type: 'spreadsheet',
    glyphColor: 'var(--file-icon-glyph)',
    extension: 'csv',
  },
  {
    fileType: 'pdf',
    color: 'var(--king-red)',
    labelColor: 'var(--king-red)',
    glyphColor: 'var(--file-icon-glyph)',
    type: 'acrobat',
    extension: 'pdf',
  },
  {
    fileType: 'json',
    color: 'var(--file-icon-yellow)',
    labelColor: 'var(--file-icon-yellow)',
    type: 'code',
    glyphColor: 'var(--file-icon-glyph)',
    extension: 'json',
  },
  {
    fileType: 'png',
    color: 'var(--file-icon-light-blue)',
    labelColor: 'var(--file-icon-light-blue)',
    type: 'image',
    glyphColor: 'var(--file-icon-glyph)',
    extension: 'png',
  },
  {
    fileType: 'pptx',
    color: 'var(--file-icon-orange)',
    labelColor: 'var(--file-icon-orange)',
    type: 'presentation',
    glyphColor: 'var(--file-icon-glyph)',
    extension: 'ppt',
  },
  {
    fileType: 'ppt',
    color: 'var(--file-icon-orange)',
    labelColor: 'var(--file-icon-orange)',
    type: 'presentation',
    glyphColor: 'var(--file-icon-glyph)',
    extension: 'ppt',
  },
  {
    fileType: 'tif',
    color: 'var(--file-icon-light-blue)',
    labelColor: 'var(--file-icon-light-blue)',
    type: 'image',
    glyphColor: 'var(--file-icon-glyph)',
    extension: 'tif',
  },
  {
    fileType: 'jpg',
    color: 'var(--file-icon-light-blue)',
    labelColor: 'var(--file-icon-light-blue)',
    type: 'image',
    glyphColor: 'var(--file-icon-glyph)',
    extension: 'jpg',
  },
  {
    fileType: 'jpeg',
    color: 'var(--file-icon-light-blue)',
    labelColor: 'var(--file-icon-light-blue)',
    type: 'image',
    glyphColor: 'var(--file-icon-glyph)',
    extension: 'jpeg',
  },
  {
    fileType: 'heic',
    color: 'var(--file-icon-light-blue)',
    labelColor: 'var(--file-icon-light-blue)',
    type: 'image',
    glyphColor: 'var(--file-icon-glyph)',
    extension: 'heic',
  },
  {
    fileType: 'mov',
    color: 'var(--file-icon-light-blue)',
    labelColor: 'var(--file-icon-light-blue)',
    type: 'video',
    glyphColor: 'var(--file-icon-glyph)',
    extension: 'mov',
  },
  {
    fileType: 'mp4',
    color: 'var(--file-icon-light-blue)',
    labelColor: 'var(--file-icon-light-blue)',
    type: 'video',
    glyphColor: 'var(--file-icon-glyph)',
    extension: 'mp4',
  },
  {
    fileType: 'zip',
    type: 'compressed',
    extension: 'zip',
  },
  {
    fileType: 'application/vnd.google-apps.document',
    color: 'var(--file-icon-blue)',
    labelColor: 'var(--file-icon-blue)',
    type: 'document',
    glyphColor: 'var(--file-icon-glyph)',
  },
  {
    fileType: 'application/vnd.google-apps.spreadsheet',
    color: 'var(--file-icon-green)',
    labelColor: 'var(--file-icon-green)',
    type: 'spreadsheet',
    glyphColor: 'var(--file-icon-glyph)',
  },
  {
    fileType: 'application/vnd.google-apps.presentation',
    color: 'var(--file-icon-orange)',
    labelColor: 'var(--file-icon-yellow)',
    type: 'presentation',
    glyphColor: 'var(--file-icon-glyph)',
  },
]
