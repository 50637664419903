import React, { useState, useEffect } from "react";
import Layout from "./Layout";
import { getProgram } from "./Programs.slice";
import { useParams } from "react-router-dom";
import Panel from "../../components/Panel";
import { formatUTCDetailedDate } from "../../util/string";
import DisplayField from "../../components/Form/DisplayField";
import classnames from "classnames";
import CaptureVNEMSubmission from "./CaptureVNEMSubmission";
import { ReadinessChecklist } from "../../apis/types";
import ReadinessCheckList from "./components/ReadinessChecklist";
import { getReadinessCheckListApi } from "../../apis/apis";
import { useAppDispatch, useAppSelector } from "../../shared/redux/hooks";

// Function to format date
const formatDate = (dateStr: string | null | undefined): string => {
  return dateStr != null ? formatUTCDetailedDate(new Date(dateStr)) : "";
};

const Program: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [readinessChecklist, setReadinessChecklist] =
    useState<ReadinessChecklist | null>(null);
  const [showCaptureVNEMSubmission, setShowCaptureVNEMSubmission] =
    useState(false);
  const dispatch = useAppDispatch();

  const { programId } = useParams();
  const { program } = useAppSelector((state) => state.programs);

  const salesforceProgramLink: string | undefined =
    program?.salesforceId != null
      ? `https://kingenergy.lightning.force.com/lightning/r/Program__c/${program?.salesforceId}/view`
      : undefined;

  const handleCaptureVNEMSubmission = (): void => {
    setShowCaptureVNEMSubmission(true);
    handleRefreshReadinessChecklist();
  };

  const handleRefreshReadinessChecklist = (): void => {
    void getReadinessCheckListApi(programId as string).then((response) => {
      setReadinessChecklist(response);
    });
  };

  useEffect(() => {
    void dispatch(getProgram(programId as string)).then(() => {
      setIsLoading(false);
    });
  }, [dispatch, programId]);

  // Every time an action is performed that will change the state of the readiness checklist (i.e. VNEM submission is captured), refresh the readiness checklist
  // (the checklist is refreshed differently for portal linking actions, see ReadinessChecklist.tsx)
  useEffect(() => {
    if (showCaptureVNEMSubmission) return;
    handleRefreshReadinessChecklist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCaptureVNEMSubmission]);

  return (
    <Layout title={program?.name ?? "Program"}>
      {isLoading && <div>Loading...</div>}
      {!isLoading && (
        <div>
          <Panel header="Program Summary" className="mb-5">
            <div
              className={classnames(
                "grid grid-auto-flow gap-4 m-2",
                "grid-cols-7"
              )}
            >
              <DisplayField label="Name">{program?.name}</DisplayField>
              <DisplayField label="Utility">{program?.utility}</DisplayField>
              <DisplayField label="Start Date">
                {formatDate(program?.startDate)}
              </DisplayField>
              {program?.endDate != null && (
                <DisplayField label="End Date">
                  {formatDate(program?.endDate)}
                </DisplayField>
              )}
              <DisplayField label="PTO Date">
                {formatDate(program?.ptoDate)}
              </DisplayField>
              <DisplayField label="VNEM Billing Start Date">
                {formatDate(program?.billingOnDate)}
              </DisplayField>
              <DisplayField label="Salesforce Program ID">
                {salesforceProgramLink != null ? (
                  <div className="w-full">
                    <a
                      href={salesforceProgramLink}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-600 hover:text-blue-800 visited:text-purple-600 underline hover:underline"
                    >
                      {program?.salesforceId}
                    </a>
                  </div>
                ) : (
                  "None found"
                )}
              </DisplayField>
            </div>
          </Panel>

          {showCaptureVNEMSubmission && programId ? (
            <CaptureVNEMSubmission
              programId={programId}
              onCaptureVNEMSubmission={() =>
                setShowCaptureVNEMSubmission(false)
              }
              setReadinessCheckList={setReadinessChecklist}
              onFailureToFetchVNEMSubmission={() =>
                setShowCaptureVNEMSubmission(false)
              }
            />
          ) : (
            readinessChecklist != null && (
              <ReadinessCheckList
                program={program}
                readinessChecklist={readinessChecklist}
                setReadinessChecklist={setReadinessChecklist}
                handleCaptureVNEMSubmission={handleCaptureVNEMSubmission}
                formatDate={formatDate}
                handleRefreshReadinessChecklist={
                  handleRefreshReadinessChecklist
                }
              />
            )
          )}
        </div>
      )}
    </Layout>
  );
};

export default Program;
