import React from "react";
import { updateContactApi } from "../../../../apis/apis";
import { getTenant } from "../Tenants.slice";
import { useParams } from "react-router-dom";
import InlineEditInput from "../../../../components/Form/InlineEditInput";
import * as Yup from "yup";
import { formatPhoneNumber } from "../../../../util/string";
import { useAppDispatch } from "../../../../shared/redux/hooks";

export interface PanelContactProps {
  contact?: {
    cuid?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    emailAddress?: string;
  };
}

export const PanelContact: React.FC<PanelContactProps> = (props) => {
  const dispatch = useAppDispatch();
  const { tenantId } = useParams();
  const { contact = {} } = props;

  return (
    <div className="grid grid-cols-2 gap-3">
      <InlineEditInput
        name="firstName"
        label="First name"
        required
        initialValue={contact.firstName}
        handleSubmit={async (editedValue) =>
          await updateContactApi(tenantId as string, contact.cuid ?? "", {
            firstName: editedValue,
          }).then(() => {
            dispatch(getTenant(tenantId as string));
          })
        }
      />
      <InlineEditInput
        name="lastName"
        label="Last name"
        required
        initialValue={contact.lastName}
        handleSubmit={async (editedValue) =>
          await updateContactApi(tenantId as string, contact.cuid ?? "", {
            lastName: editedValue,
          }).then(() => {
            dispatch(getTenant(tenantId as string));
          })
        }
      />
      <InlineEditInput
        name="phone"
        label="Phone"
        required
        initialValue={contact.phone}
        validationSchema={Yup.string().test(
          "phone",
          "10 digits required",
          (value): boolean => {
            return value?.replace(/\D/g, "").match(/^\d{10}$/) !== null;
          }
        )}
        formatter={formatPhoneNumber}
        handleSubmit={async (editedValue) =>
          await updateContactApi(tenantId as string, contact.cuid ?? "", {
            phone: editedValue,
          }).then(() => {
            dispatch(getTenant(tenantId as string));
          })
        }
      />
      <InlineEditInput
        name="emailAddress"
        label="Email"
        required
        validationSchema={Yup.string().email("Invalid email")}
        initialValue={contact.emailAddress}
        handleSubmit={async (editedValue) =>
          await updateContactApi(tenantId as string, contact.cuid ?? "", {
            emailAddress: editedValue,
          }).then(() => {
            dispatch(getTenant(tenantId as string));
          })
        }
      />
    </div>
  );
};

export default PanelContact;
