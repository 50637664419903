import React, { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/solid";

interface WorkflowSelectProps {
  value: string | null;
  onSelectWorkflow: (workflow: string) => void;
  dropDownZIndex?: string;
}
const workflows: Array<{ id: number; name: string; description: string }> = [
  { id: 1, name: "Default", description: "All files, no filters" },
  {
    id: 2,
    name: "Tagger",
    description:
      "Unnecessary file types such as images filtered, untagged and last modified today filter applied",
  },
];

export const WorkflowSelect: React.FC<WorkflowSelectProps> = ({
  dropDownZIndex = "z-10",
  onSelectWorkflow,
  value,
}) => {
  const [selected, setSelected] = useState(workflows[0]);

  // Update the workflow each time the value changes due to an external change
  useEffect(() => {
    const workflow = workflows.find((workflow) => workflow.name === value);
    if (workflow == null) return;
    setSelected(workflow);
  }, [value]);

  const handleSelect = (workflow: {
    id: number;
    name: string;
    description: string;
  }): void => {
    setSelected(workflow);
    onSelectWorkflow(workflow.name);
  };

  function classNames(...classes: string[]): string {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <Listbox value={selected} onChange={handleSelect}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
            Preset Workflow
          </Listbox.Label>
          <div className={classNames("relative mt-2")}>
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{selected.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.47 4.72a.75.75 0 011.06 0l3.75 3.75a.75.75 0 01-1.06 1.06L12 6.31 8.78 9.53a.75.75 0 01-1.06-1.06l3.75-3.75zm-3.75 9.75a.75.75 0 011.06 0L12 17.69l3.22-3.22a.75.75 0 111.06 1.06l-3.75 3.75a.75.75 0 01-1.06 0l-3.75-3.75a.75.75 0 010-1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={classNames(
                  "list-none m-0 pl-0 absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
                  dropDownZIndex
                )}
              >
                {workflows.map((workflow) => (
                  <Listbox.Option
                    key={workflow.id}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-indigo-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-8 pr-4"
                      )
                    }
                    value={workflow}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {workflow.name}
                        </span>
                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 left-0 flex items-center pl-1.5"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                        <p
                          className={`text-xs ${
                            selected ? "font-medium" : "font-normal"
                          } overflow-auto w-60 whitespace-normal break-words`}
                        >
                          {workflow.description}
                        </p>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default WorkflowSelect;
