import React from 'react'
import { ServiceAccount } from '../../../../apis/types'
import DisplayField from '../../../../components/Form/DisplayField'

interface TabGeneralProps {
  title: string
  data: ServiceAccount
}

const TabGeneral: React.FC<TabGeneralProps> = (props) => {
  const { data, ...rest } = props
  return (
    <div className="grid grid-cols-3 gap-6" {...rest}>
      <DisplayField label="King account name">{data.kingAccountName}</DisplayField>
      <DisplayField label="King account number">{data.kingAccountNumber}</DisplayField>
      <div />
      <DisplayField label={`${data.utilityName} account name`}>{data.utilityAccountName}</DisplayField>
      <DisplayField label={`${data.utilityName} account number`}>{data.utilityAccountNumber}</DisplayField>
      <DisplayField label={`${data.utilityName} billing address`}>
        {data.billingAddress !== null && (
          <span>
            {data.billingAddress.street1} {data.billingAddress.street2}
            <br />
            {data.billingAddress.city}, {data.billingAddress.state} {data.billingAddress.zip}
          </span>
        )}
      </DisplayField>
    </div>
  )
}

export default TabGeneral
