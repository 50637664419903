import React from "react";
import { useParams } from "react-router-dom";
import Layout from "./Layout";

const EditTenant: React.FC = () => {
  const { tenantId } = useParams();

  return (
    <Layout title={`Modify Tenant ${tenantId}`}>
      Modifying Tenant {tenantId}
    </Layout>
  );
};

export default EditTenant;
