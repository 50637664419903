import React, { cloneElement } from 'react'
import classnames from 'classnames'

export interface OnboardingStepsProps {
  className?: string
  children: Array<React.ReactElement<any, string | React.JSXElementConstructor<any>>>
}

export const OnboardingSteps: React.FC<OnboardingStepsProps> = (props) => {
  const index = props.children
    .slice()
    .reverse()
    .findIndex((c: any) => c.props.isActive)
  const count = props.children.length - 1
  const finalIndex = index >= 0 ? count - index : index

  const children = props.children.map((OnboardingStep: any, i: number) => {
    return cloneElement(OnboardingStep, {
      key: i,
      number: i + 1,
      isComplete: i < finalIndex,
      isActive: i === finalIndex,
      isIncomplete: i > finalIndex,
    })
  })

  return <div className={classnames(props.className)}>{children}</div>
}

export default OnboardingSteps
