import React from "react";
import { usersAPI } from "../shared/api";
import { useNavigate } from "react-router-dom";
import { UsersView } from "./components/UsersView";

import type { User } from "../shared/types/models";

export const UsersPage: React.FC = () => {
  const navigate = useNavigate();

  function navigateToUserDetails(user: User): void {
    navigate(`/users/${user.id}`);
  }

  const { data: users, isLoading: isLoadingUsers } =
    usersAPI.endpoints.findUsers.useQuery();

  return (
    <UsersView
      users={users}
      isLoadingUsers={isLoadingUsers}
      navigateToUserDetails={navigateToUserDetails}
    />
  );
};

export default UsersPage;
