import React from "react";
import { UsersList } from "./UsersList";
import { CreateUserForm } from "./CreateUserForm";
import { Modal } from "../../../components/Modal";

import type { User } from "../../shared/types/models";

export interface UsersViewProps {
  users?: User[];
  isLoadingUsers: boolean;
  navigateToUserDetails: (user: User) => void;
}

export const UsersView: React.FC<UsersViewProps> = (props) => {
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] =
    React.useState<boolean>(false);

  function handleOpenCreateUserModalButtonClick(): void {
    setIsCreateUserModalOpen(true);
  }

  function handleCreateUserModalCloseAction(): void {
    setIsCreateUserModalOpen(false);
  }

  function handleCreateUserFormCancelButtonClick(): void {
    setIsCreateUserModalOpen(false);
  }

  function handleCreateUserFormSubmitSuccess(): void {
    setIsCreateUserModalOpen(false);
  }

  function handleUserRowClick(user: User): void {
    props.navigateToUserDetails(user);
  }

  return (
    <>
      <div className="max-w-7xl mx-auto px-4">
        <main className="max-w-5xl mx-auto py-6">
          <div className="flex justify-between items-center">
            <span className="font-semibold text-xl text-gray-700">Users</span>
            <button
              type="button"
              onClick={handleOpenCreateUserModalButtonClick}
              className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 disabled:text-blue-500 disabled:bg-blue-50 disabled:cursor-not-allowed border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
            >
              Add
            </button>
          </div>
          <div className="mt-3">
            <UsersList
              users={props.isLoadingUsers ? [] : props.users ?? []}
              handleRowClick={handleUserRowClick}
            />
          </div>
        </main>
      </div>
      <Modal
        isOpen={isCreateUserModalOpen}
        handleCloseAction={handleCreateUserModalCloseAction}
      >
        {() => (
          <CreateUserForm
            handleCancelButtonClick={handleCreateUserFormCancelButtonClick}
            handleFormSubmitSuccess={handleCreateUserFormSubmitSuccess}
          />
        )}
      </Modal>
    </>
  );
};
