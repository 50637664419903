import React from "react";
import { useFormik, FormikProvider, Field } from "formik";
import ComboboxAutocomplete from "../../../../components/Form/ComboboxAutocomplete";
import BillingOptionsSwitch from "./BillingOptionsSwitch";
import Button from "../../../../components/Form/Button";
import Input from "../../../../components/Form/Input";
import Panel from "../../../../components/Panel";
import * as Yup from "yup";
import { BillingOptionObject } from "../../../../apis/types";
import {
  updateKingBillingConfigApi,
  updateUtilityBillingConfigApi,
} from "../../../../apis/apis";
import { pushToast } from "../../../../components/Toaster/Toaster.slice";
import { useAppDispatch, useAppSelector } from "../../../../shared/redux/hooks";

interface TabBillingOptionsProps {
  title: string;
  utilityFormInitialValues: {
    billType?: string;
    ccaProgram?: string;
    demandCreditProgram?: string;
  };
  kingFormInitialValues: {
    billType?: string;
    includeChart?: boolean;
    includeSummary?: boolean;
    energyDiscount?: number;
    demandDiscount?: number;
    billingMode?: string;
  };
  utilityBillingOptions: BillingOptionObject[];
  kingBillingOptions: BillingOptionObject[];
  enrollmentOptions: BillingOptionObject[];
  billingModeOptions: BillingOptionObject[];
  tenantAccountId: string;
}

const TabBillingOptions: React.FC<TabBillingOptionsProps> = ({
  kingFormInitialValues,
  utilityFormInitialValues,
  utilityBillingOptions,
  kingBillingOptions,
  billingModeOptions,
  enrollmentOptions,
  tenantAccountId,
}) => {
  const dispatch = useAppDispatch();
  const { permissions } = useAppSelector((state) => state.app);

  const {
    billType: utilityBillType,
    ccaProgram,
    demandCreditProgram,
  } = utilityFormInitialValues;
  const {
    billType: kingBillType,
    includeChart,
    includeSummary,
    energyDiscount,
    demandDiscount,
    billingMode,
  } = kingFormInitialValues;

  const hasModifyPermission =
    permissions?.includes("account:manager:billing-options:modify") ?? false;
  const hasModifyDiscountPermission =
    permissions?.includes("account:manager:billing-options:discounts:modify") ??
    false;

  // If new billing options are added, add descriptions to the maps below
    const billingModeOptionsDescriptionMap = new Map([
    ['CONSOLIDATED', 'OneBill™'],
    ['STANDALONE', 'OneBill™ Solar Only'],
    ['CONSOLIDATED_ESTIMATED', 'Advance Bill'],
    ['STANDALONE_ESTIMATED', 'Solar Only Estimate'],
  ])

  const utilityBillTypeDescriptionMap = new Map([
    ["STANDARD", "Standard bill (most utilities)"],
    ["DETAIL", "Detailed charges for a given meter (PG&E only)"],
    ["STANDARD_AND_DETAIL", "Standard bill & detailed bill (PG&E only)"],
    [
      "STANDARD_OMNIBUS",
      "Standard bill for multiple locations - only one location is billed",
    ],
    [
      "STANDARD_OMNIBUS_AND_DETAIL",
      "Standard and detailed bill for multiple locations - only one location is billed",
    ],
    ["NONE", "No access to bills"],
  ]);

  const utilityForm = useFormik({
    initialValues: {
      billType: utilityBillType ?? "STANDARD",
      ccaProgram: ccaProgram ?? "NOT_ENROLLED",
      demandCreditProgram: demandCreditProgram ?? "NOT_ENROLLED",
    },
    validationSchema: Yup.object({
      billType: Yup.string().required(),
      ccaProgram: Yup.string().required(),
      demandCreditProgram: Yup.string().required(),
    }),
    onSubmit: (values) => {
      updateUtilityBillingConfigApi(values, tenantAccountId)
        .then(async () => {
          await dispatch(
            pushToast({
              message: "Utility billing configuration updated",
              type: "success",
            })
          );
        })
        .catch(async (e) => {
          await dispatch(
            pushToast({
              message: "Failed to update utility billing configuration",
              type: "error",
              description: `${
                e.response?.data?.error !== undefined
                  ? `${e.response?.data?.error as string}:`
                  : "Error:"
              } ${e.message as string}`,
            })
          );
        });
    },
  });

  const kingForm = useFormik({
    initialValues: {
      billType: kingBillType ?? "STANDARD",
      includeChart: includeChart ?? false,
      includeSummary: includeSummary ?? false,
      energyDiscount: energyDiscount ?? 10,
      demandDiscount: demandDiscount ?? 0,
      billingMode: billingMode ?? "CONSOLIDATED",
    },
    validationSchema: Yup.object({
      energyDiscount: Yup.number().min(0).max(100).required(),
      demandDiscount: Yup.number().min(0).max(100).required(),
      billingMode: Yup.string().required(),
      billType: Yup.string().required(),
      includeChart: Yup.boolean().required(),
      includeSummary: Yup.boolean().required(),
    }),
    onSubmit: (values) => {
      const body = {
        ...values,
        energyDiscount: values.energyDiscount.toFixed(2),
        demandDiscount: values.demandDiscount.toFixed(2),
      };

      updateKingBillingConfigApi(body, tenantAccountId)
        .then(async () => {
          await dispatch(
            pushToast({
              message: "King billing configuration updated",
              type: "success",
            })
          );
        })
        .catch(async (e) => {
          await dispatch(
            pushToast({
              message: "Failed to update king billing configuration",
              type: "error",
              description: `${
                e.response?.data?.error !== undefined
                  ? `${e.response?.data?.error as string}:`
                  : "Error:"
              } ${e.message as string}`,
            })
          );
        });
    },
  });

  const renderBillingOptions = (
    billingOptions: BillingOptionObject[],
    billingOptionsMap?: Map<string, string>
  ): Array<{ id: string; name: string; description?: string }> => {
    return billingOptions.map((option) => {
      const key = Object.keys(option)[0];
      const description =
        billingOptionsMap?.has(key) === true
          ? billingOptionsMap.get(key)
          : undefined;
      const baseObject = {
        id: key,
        name: option[key],
      };

      return {
        ...baseObject,
        ...(description !== undefined && { description }),
      };
    });
  };

  const renderSelectField = (
    name:
      | keyof typeof utilityFormInitialValues
      | keyof typeof kingFormInitialValues,
    label: string,
    options: BillingOptionObject[],
    description: string,
    formName: string,
    disabled: boolean,
    billingOptionsMap?: Map<string, string>,
    isEnrollmentSelect?: boolean
  ): JSX.Element => {
    let formValue;
    if (formName === "utilityForm") {
      formValue =
        utilityForm.values[name as keyof typeof utilityFormInitialValues];
    } else {
      formValue = kingForm.values[name as keyof typeof kingFormInitialValues];
    }

    return (
      <div className="flex flex-col">
        <label
          htmlFor={name}
          className="block text-sm font-medium text-zinc-700"
        >
          <div className="flex flex-col">
            <div>{label}</div>
            {isEnrollmentSelect !== true && (
              <span className="text-xs text-gray-400">{description}</span>
            )}
          </div>
        </label>
        <ComboboxAutocomplete
          data={renderBillingOptions(options, billingOptionsMap)}
          value={(formValue as string) ?? ""}
          handleChange={(value: string | undefined) => {
            const formikForm =
              formName === "utilityForm" ? utilityForm : kingForm;
            formikForm.setFieldValue(name, value).catch(async (err) => {
              await dispatch(
                pushToast({
                  message: "Failed to update billing configuration",
                  type: "error",
                  description: `${
                    err.response?.data?.error !== undefined
                      ? `${err.response?.data?.error as string}:`
                      : "Error:"
                  } ${err.message as string}`,
                })
              );
            });
          }}
          label={label}
          labelPlural={`${label}s`}
          wildCardDisabled={true}
          disabled={disabled}
          isNotFilter={true}
          placeholder={
            isEnrollmentSelect === true
              ? `Search for an enrollment type`
              : `Search for a ${label.toLowerCase()}...`
          }
        />
      </div>
    );
  };

  return (
    <div className="md:grid md:grid-cols-2 md:gap-6">
      <div>
        <Panel
          header={"Utility Billing Configuration"}
          className="mb-3 md:mb-0 flex flex-col"
        >
          <FormikProvider value={utilityForm}>
            <form
              onSubmit={utilityForm.handleSubmit}
              className="flex flex-col justify-between"
            >
              <div className="flex flex-col">
                <div className="z-20">
                  {renderSelectField(
                    "billType",
                    "Bill Type",
                    utilityBillingOptions,
                    "Utility bill type(s) available to the tenant",
                    "utilityForm",
                    !hasModifyPermission,
                    utilityBillTypeDescriptionMap
                  )}
                </div>
                <div className="grid grid-auto-flow grid-cols-2 gap-3 mt-3">
                  {renderSelectField(
                    "ccaProgram",
                    "CCA Program",
                    enrollmentOptions,
                    "CCA Program",
                    "utilityForm",
                    !hasModifyPermission,
                    undefined,
                    true
                  )}
                  {renderSelectField(
                    "demandCreditProgram",
                    "Demand Credit Program",
                    enrollmentOptions,
                    "Demand Credit Program",
                    "utilityForm",
                    !hasModifyPermission,
                    undefined,
                    true
                  )}
                </div>
              </div>
              <div className="mt-3">
                <Button
                  type="submit"
                  className="w-full"
                  disabled={!hasModifyPermission}
                >
                  Save
                </Button>
              </div>
            </form>
          </FormikProvider>
        </Panel>
      </div>

      <Panel header={"King Billing Configuration"} className="flex flex-col ">
        <FormikProvider value={kingForm}>
          <form
            onSubmit={kingForm.handleSubmit}
            className="flex flex-col flex-1 justify-between"
          >
            <div>
              <div className="grid grid-auto-flow grid-cols-2 gap-3">
                {renderSelectField(
                  "billType",
                  "Bill Type",
                  kingBillingOptions,
                  "Type of KE bill generated",
                  "kingForm",
                  !hasModifyPermission
                )}
                {renderSelectField(
                  "billingMode",
                  "Billing Mode",
                  billingModeOptions,
                  "How we calculate bills for the tenant",
                  "kingForm",
                  !hasModifyPermission,
                  billingModeOptionsDescriptionMap
                )}
              </div>
              <div className="grid grid-auto-flow grid-cols-2 gap-3 mt-3">
                <BillingOptionsSwitch
                  name="includeChart"
                  label="Display Energy Pie Chart"
                  disabled={!hasModifyPermission}
                />
                <BillingOptionsSwitch
                  name="includeSummary"
                  label="Display Detailed Summary"
                  disabled={!hasModifyPermission}
                />
              </div>
              <div className="flex flex-row justify-between gap-x-4 mt-3">
                <Field
                  as={Input}
                  type="number"
                  name="energyDiscount"
                  label="Energy Discount"
                  className="flex-1"
                  error={kingForm.errors.energyDiscount}
                  disabled={!hasModifyDiscountPermission}
                  description="K.E. contracted discount"
                />
                <Field
                  as={Input}
                  type="number"
                  name="demandDiscount"
                  label="Demand Discount"
                  className="flex-1"
                  error={kingForm.errors.demandDiscount}
                  disabled={!hasModifyDiscountPermission}
                  description="% of demand savings passed onto tenant"
                />
              </div>
            </div>
            <Button
              type="submit"
              className="mt-3 w-full"
              disabled={!hasModifyPermission}
            >
              Save
            </Button>
          </form>
        </FormikProvider>
      </Panel>
    </div>
  );
};

export default TabBillingOptions;
