import React, { Fragment, useRef, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import iconTenant from "./icons/icon-tenant.svg";
import iconProperty from "./icons/icon-property.svg";
import iconProgram from './icons/icon-program.svg'
import { getTenantSearchApi } from '../../apis/apis'
import { useNavigate } from 'react-router-dom'
import { GetTenantSearchData } from '../../apis/types'
import { formatPhoneNumber } from '../../util/string'
interface PropTypes {
  className?: string
  handleChange?: any
  autoFocus?: boolean
  setShowGlobalSearch?: React.Dispatch<React.SetStateAction<boolean>>
}

export const TenantSearch: React.FC<PropTypes> = (props) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [data, setData] = useState<GetTenantSearchData[] | []>([])
  const [dataLoaded, setDataLoaded] = useState(false)
  const [dataError, setDataError] = useState(false)

  const navigate = useNavigate()
  const inputEl = useRef<HTMLInputElement>(null)

  const getParts = (phrase: string, match: string): any => {
    if (match == null || match === '' || match.length < 2) {
      return phrase
    }
    const matchLowerCase = match.toLowerCase()
    const formattedOutput: any[] = []
    let cursor = 0
    // eslint-disable-next-line @typescript-eslint/prefer-includes
    while (phrase.toLowerCase().indexOf(matchLowerCase, cursor) !== -1) {
      const matchedIndex = phrase.toLowerCase().indexOf(matchLowerCase, cursor)
      formattedOutput.push(phrase.substring(cursor, matchedIndex))
      formattedOutput.push(<span className="bg-yellow-100">{phrase.substring(matchedIndex, matchedIndex + matchLowerCase.length)}</span>)
      cursor = matchedIndex + matchLowerCase.length
    }
    formattedOutput.push(phrase.substring(cursor))
    return <span>{formattedOutput}</span>
  }

  return (
    <div className="">
      <Combobox
        value={null}
        onChange={(selection: any) => {
          setSearchTerm('')
          switch (selection?.type) {
            case 'PROPERTY':
              if (props.setShowGlobalSearch) props.setShowGlobalSearch(false)
              navigate(`/tenant/tenants?propertyId=${selection.propertyCuid as string}`)
              break
            case 'TENANT':
              if (props.setShowGlobalSearch) props.setShowGlobalSearch(false)
              navigate(`/tenant/tenants/${selection.tenantCuid as string}`)
              break
            case 'PROGRAM':
              if (props.setShowGlobalSearch) props.setShowGlobalSearch(false)
              navigate(`/program/programs/${selection.programCuid as string}`)
              break
            default:
              break
          }
        }}
      >
        <div className="relative mt-1">
          <div>
            <Combobox.Input
              // className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-zinc-300 border focus:outline-none sm:text-sm rounded-md"
              className={'mt-1 block w-full pl-3 pr-10 py-2 text-base border-0 ring-1 bg-white sm:text-sm rounded-md'}
              displayValue={() => ''}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onChange={async (event) => {
                const searchTerm = event.target.value.trim()
                setSearchTerm(searchTerm)
                let result
                try {
                  result = await getTenantSearchApi({ searchTerm })
                  setDataError(false)
                } catch (e) {
                  setDataError(true)
                }
                setData(result?.data ?? [])
                setDataLoaded(true)
              }}
              ref={inputEl}
              autoFocus={props.autoFocus}
              placeholder="Search..."
            />
          </div>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" afterLeave={() => setSearchTerm('')}>
            <Combobox.Options className="absolute mt-1 max-h-120 w-full overflow-auto rounded-md bg-white py-1 pl-0 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {dataError && <div className="px-3 py-1 bg-red-100">Error connecting to search service</div>}
              {!dataError && !dataLoaded && <div className="px-3 py-1">Loading...</div>}
              {!dataError && dataLoaded && data?.length === 0 && searchTerm !== '' && searchTerm.length >= 2 && (
                <div className="px-3 py-1">No results found</div>
              )}
              {!dataError && dataLoaded && data?.length === 0 && searchTerm !== '' && searchTerm.length < 2 && (
                <div className="px-3 py-1">Please enter at least two alphanumeric characters</div>
              )}
              {data?.map((item: any, index: number) => (
                <Combobox.Option
                  className={({ active }) =>
                    `relative cursor-default select-none py-1 px-2.5 border-b border-zinc-200 last:border-0 ${active ? 'bg-indigo-50' : 'text-gray-900'}`
                  }
                  value={item}
                  key={index}
                >
                  {
                    // Handle option based off of whether an item is a tenant, property, or program
                    (() => {
                      switch (item.type) {
                        case 'TENANT':
                          return (
                            <div>
                              <div className="grid grid-cols-[38px_minmax(1px,_1fr)_minmax(1px,_1fr)] gap-2.5">
                                <div>
                                  <img src={iconTenant} alt="Tenant" />
                                </div>
                                <div className="text-base font-medium self-center">{getParts(item.tenantAccountName, searchTerm)}</div>
                                <div className="relative text-right self-center text-xs">
                                  <div className="text-slate-600">{item.propertyName}</div>
                                  <div className="relative text-slate-500">{item.propertyLocation}</div>
                                </div>
                              </div>
                              {/* Conditionally render matched items except 'tenantAccountName' */}
                              {item.matchedOn?.map((matchedOn: string, index: React.Key | null | undefined) => {
                                if (matchedOn === 'tenantAccountName') {
                                  return null
                                }
                                return (
                                  <div className="pl-[49px] pb-1 relative" key={index}>
                                    <div className="text-slate-600 inline-block font-normal">
                                      {matchedOn === 'contactPhone' || matchedOn === 'accountManagerPhone'
                                        ? getParts(formatPhoneNumber(item[matchedOn]), searchTerm)
                                        : getParts(item[matchedOn], searchTerm)}
                                      <div className="ml-4 text-xs text-slate-400 inline-block">
                                        {matchedOn === 'utilityAccountNumber' && `Utility Account Number (${item.utilityShortName as string})`}
                                        {matchedOn === 'utilityAccountName' && `Utility Account Name (${item.utilityShortName as string})`}
                                        {matchedOn === 'tenantAccountNumber' && 'Tenant Account Number'}
                                        {matchedOn === 'contactFullName' && 'Contact Name'}
                                        {matchedOn === 'contactEmail' && 'Contact Email Address'}
                                        {matchedOn === 'contactPhone' && 'Contact Phone Number'}
                                        {matchedOn === 'serviceAccountId' && 'Service Account ID'}
                                        {matchedOn === 'meterNumber' && 'Meter Number'}
                                        {matchedOn === 'serviceStreetAddress' && 'Service Street Address'}
                                        {matchedOn === 'accountManagerFullName' && 'Account Manager Name'}
                                        {matchedOn === 'accountManagerIdentifier' && 'Account Manager Identifier'}
                                        {matchedOn === 'accountManagerPhone' && 'Account Manager Phone Number'}
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          )

                        case 'PROPERTY':
                          return (
                            <div className="grid grid-cols-[38px_minmax(1px,_1fr)_minmax(1px,_1fr)] gap-2.5">
                              <img src={iconProperty} alt="Property" />
                              <div className="text-base font-medium self-center">{getParts(item.propertyName, searchTerm)}</div>
                              <div className="text-slate-600 text-right self-center text-xs">{item.propertyLocation}</div>
                            </div>
                          )

                        case 'PROGRAM':
                          return (
                            <div className="grid grid-cols-[38px_minmax(1px,_1fr)_minmax(1px,_1fr)] gap-2.5">
                              <img src={iconProgram} alt="Program" />
                              <div className="text-base font-medium self-center">{getParts(item.programName, searchTerm)}</div>
                            </div>
                          )

                        default:
                          return
                      }
                    })()
                  }
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}

export default TenantSearch;
