import React from 'react'

interface FilterUndoButtonProps {
  filter: string
  handleUndo: (filter: string) => void
}

export const FilterUndoButton: React.FC<FilterUndoButtonProps> = ({ filter, handleUndo }) => {
  return (
    <button
      className="flex flex-row items-center justify-center w-8 h-8 mt-6 ml-1 transition duration-150 ease-in-out rounded-full hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
      onClick={() => handleUndo(filter)}
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
      </svg>
    </button>
  )
}
