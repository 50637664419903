import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPaymentListApi,
  getTenantListApi,
  getPropertyListApi,
} from "../../../apis/apis";
import {
  GetPaymentsArgs,
  Paginated,
  Payment,
  PaymentSummaries,
} from "../../../apis/types";
import { AppDispatch, RootState } from "../../../shared/redux/store";

const SLICE_NAME = "PAYMENTS_SLICE";

export const getPaymentSummary = createAsyncThunk<
  PaymentSummaries,
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: { err: boolean };
  }
  // eslint-disable-next-line @typescript-eslint/require-await
>(`${SLICE_NAME}/getPaymentSummary`, async (_, { rejectWithValue }) => {
  try {
    // TODO to reenable "Trailing 30-day totals" feature remove this hardcoded
    // api response, add back in the commented out code, and import getPaymentSummaryApi

    // const response = await getPaymentSummaryApi()
    // return response
    const summary: PaymentSummaries | undefined = {
      CANCELLED: { number: 0, amount: 0 },
      CHARGEBACK: { number: 0, amount: 0 },
      FAILED: { number: 0, amount: 0 },
      PENDING: { number: 0, amount: 0 },
      PROCESSING: { number: 0, amount: 0 },
      SUCCEEDED: { number: 0, amount: 0 },
    };
    return summary;
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    });
  }
});

export const getPaymentList = createAsyncThunk<
  Paginated<Payment>,
  GetPaymentsArgs,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: { err: boolean };
  }
>(`${SLICE_NAME}/getPaymentList`, async (query, { rejectWithValue }) => {
  try {
    const response = await getPaymentListApi(query);
    return response;
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    });
  }
});

export const getTenantList = createAsyncThunk<
  Array<{
    id: string;
    name: string;
  }>,
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: { err: boolean };
  }
>(`${SLICE_NAME}/getTenantList`, async (_, { rejectWithValue }) => {
  try {
    const response = await getTenantListApi();
    return response;
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    });
  }
});

export const getPropertyList = createAsyncThunk<
  Array<{
    id: string;
    name: string;
  }>,
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: { err: boolean };
  }
>(`${SLICE_NAME}/getPropertyList`, async (_, { rejectWithValue }) => {
  try {
    const response = await getPropertyListApi();
    return response;
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    });
  }
});

interface state {
  loading: boolean;
  error: boolean;
  summary?: PaymentSummaries;
  payments?: Paginated<Payment>;
  tenants: Array<{
    id: string;
    name: string;
  }>;
  properties: Array<{
    id: string;
    name: string;
  }>;
}

const initialState: state = {
  loading: false,
  error: false,
  summary: undefined,
  payments: undefined,
  tenants: [],
  properties: [],
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPaymentSummary.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getPaymentSummary.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.summary = payload;
    });
    builder.addCase(getPaymentSummary.rejected, (state, { payload, error }) => {
      state.loading = false;

      if (payload != null) {
        state.error = payload.err;
      } else {
        state.error = error !== undefined;
      }
    });

    builder.addCase(getPaymentList.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getPaymentList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.payments = payload;
    });
    builder.addCase(getPaymentList.rejected, (state, { payload, error }) => {
      state.loading = false;

      if (payload != null) {
        state.error = payload.err;
      } else {
        state.error = error !== undefined;
      }
    });

    builder.addCase(getTenantList.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getTenantList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.tenants = payload;
    });
    builder.addCase(getTenantList.rejected, (state, { payload, error }) => {
      state.loading = false;

      if (payload != null) {
        state.error = payload.err;
      } else {
        state.error = error !== undefined;
      }
    });

    builder.addCase(getPropertyList.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getPropertyList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.properties = payload;
    });
    builder.addCase(getPropertyList.rejected, (state, { payload, error }) => {
      state.loading = false;

      if (payload != null) {
        state.error = payload.err;
      } else {
        state.error = error !== undefined;
      }
    });
  },
});

export const paymentsReducer = slice.reducer;
