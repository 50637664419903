import React from 'react'

import { viewPropsByUserStatus } from '../view-props/user-status'

import type { UserStatus } from '../types/models'

export interface UserStatusBadgeProps {
  status: UserStatus
}

export const UserStatusBadge: React.FC<UserStatusBadgeProps> = (props) => {
  const { colorClassName } = viewPropsByUserStatus[props.status]

  return <div className={`block w-2.5 h-2.5 rounded-full ${colorClassName}`} />
}
