import React from 'react'
import { FormikProvider, Field } from 'formik'
import Button from '../../../components/Form/Button'
import Input from '../../../components/Form/Input'
import ControlledToggle from '../../../components/Form/ControlledToggle'
interface UpdateCredentialsFormProps {
  formik: any
  showUpdateCredentialsForm: boolean
  setShowUpdateCredentialsForm: (showUpdateCredentialsForm: boolean) => void
  toggleChecked?: boolean
  onToggle?: () => void
  toggleDisabled?: boolean
  showToggle: boolean
}

const UpdateCredentialsForm: React.FC<UpdateCredentialsFormProps> = ({
  formik,
  showUpdateCredentialsForm,
  setShowUpdateCredentialsForm,
  toggleChecked,
  onToggle,
  toggleDisabled,
  showToggle,
}) => {
  return (
    <>
      {!showUpdateCredentialsForm && (
        <div className="flex flex-col justify-between items-center">
          <Button
            onClick={() => {
              setShowUpdateCredentialsForm(true)
            }}
          >
            Update Credentials...
          </Button>
          {showToggle && (
            <ControlledToggle
              label="Scraper Active"
              onChange={() => {
                if (onToggle != null) {
                  onToggle()
                }
              }}
              checked={toggleChecked ?? false}
              className="mt-4"
              disabled={toggleDisabled}
            />
          )}
        </div>
      )}
      {showUpdateCredentialsForm && (
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col justify-between items-center">
              <div className="flex flex-row justify-between items-end">
                <div className="grid grid-cols-2 gap-4">
                  <Field label="Username" id="username" name="username" type="text" as={Input} required error={formik.errors.username} />
                  <Field label="Password" id="password" name="password" type="password" as={Input} required error={formik.errors.password} />
                </div>
              </div>
              <div className="mt-4 w-full flex flex-row justify-center items-center">
                <Button type="submit" className="w-full">
                  Update
                </Button>
                <Button
                  className="w-full ml-4"
                  onClick={() => {
                    setShowUpdateCredentialsForm(false)
                  }}
                  color="zinc"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </FormikProvider>
      )}
    </>
  )
}

export default UpdateCredentialsForm
