import type { UserStatus } from '../types/models'

export interface UserStatusBadgeViewProps {
  label: string
  colorClassName: string
}

export const viewPropsByUserStatus: Record<UserStatus, UserStatusBadgeViewProps> = {
  active: { label: 'Active', colorClassName: 'bg-green-500/75' },
  blocked: { label: 'Blocked', colorClassName: 'bg-red-500/75' },
}
